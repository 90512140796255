import React, { FunctionComponent, memo } from 'react';

interface Props {
  className?: string;
  onClick?: () => void;
}

export const Clock: FunctionComponent<Props> = memo((props) => (
  <svg
    width="22"
    height="22"
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.0002 1.83331C16.0628 1.83331 20.1668 5.93737 20.1668 11C20.1668 16.0626 16.0628 20.1666 11.0002 20.1666C5.93755 20.1666 1.8335 16.0626 1.8335 11C1.8335 5.93737 5.93755 1.83331 11.0002 1.83331ZM11.0002 3.66665C6.95007 3.66665 3.66683 6.94989 3.66683 11C3.66683 15.0501 6.95007 18.3333 11.0002 18.3333C15.0503 18.3333 18.3335 15.0501 18.3335 11C18.3335 6.94989 15.0503 3.66665 11.0002 3.66665ZM11.0002 5.49998C11.4703 5.49998 11.8577 5.85385 11.9107 6.30974L11.9168 6.41665V10.6203L13.4817 12.1851C13.8397 12.5431 13.8397 13.1235 13.4817 13.4815C13.1512 13.8119 12.6313 13.8374 12.2717 13.5578L12.1853 13.4815L10.352 11.6482C10.2087 11.5049 10.1178 11.3191 10.0914 11.1205L10.0835 11V6.41665C10.0835 5.91039 10.4939 5.49998 11.0002 5.49998Z"
      fill="#B9C1CC"
    />
  </svg>
));
