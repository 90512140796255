import { createStyles, makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) =>
  createStyles({
    wrapper: {
      width: 'auto',
      maxHeight: '850px',
      paddingBottom: '42px',
      [theme.breakpoints.only('xs')]: {
        width: '100%',
        maxHeight: 'unset',
        paddingBottom: '26px',
      },
    },
    heading: {
      margin: '32px 0 16px',
      textAlign: 'center',
    },
    textContainer: {
      padding: '0 30px',
    },
    text: {
      textAlign: 'center',
    },
    buttonContainer: {
      display: 'flex',
      justifyContent: 'center',
      marginTop: '32px',
    },
    body: {
      maxWidth: '635px',
      padding: '13px 30px 27px',
      [theme.breakpoints.only('xs')]: {
        padding: 0,
      },
    },
    mB8: {
      marginBottom: '8px',
    },
    categories: {
      margin: '20px 0 24px',
    },
    subCategories: {
      flexDirection: 'row',
      flex: 1,
    },
  })
);
