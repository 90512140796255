import React, { FunctionComponent, useState, useEffect, useCallback } from 'react';
import { useFormContext } from 'react-hook-form';
import cx from 'classnames';
// material-ui components
import { Grid, Typography } from '@material-ui/core';
// components
import { Button } from 'components';
// controllers
import { DatePickerController, OutlinedInputController, SelectController } from 'controllers';
// icons
import { Plus } from 'assets/icons';
// helpers
import { childDefaultValues } from '../../helpers';
// constants
import { genderOptions, gradeLevelOptions } from 'utils/constants';
// types
import { IChildForm } from './types';
// styles
import useStyles from './styles';

export const ChildForm: FunctionComponent<IChildForm> = ({
  parent,
  handleCancel,
  currentChildId,
  handleSetChildId,
  buttonsIsDisabled,
}) => {
  const classes = useStyles();
  const [formIsOpened, setFormIsOpened] = useState(false);

  const form = useFormContext();

  useEffect(() => {
    const currentChildren =
      parent?.children.find(({ id }) => id === currentChildId) || childDefaultValues;
    if (!parent?.children.length || currentChildId || currentChildId === null) {
      setFormIsOpened(true);
    } else {
      setFormIsOpened(false);
    }
    if (formIsOpened) form.setValue('children', currentChildren);
    // eslint-disable-next-line
  }, [currentChildId, parent]);

  useEffect(() => {
    handleSetChildId(undefined);
    // eslint-disable-next-line
  }, [parent]);

  const handleSelectChild = useCallback(
    (id: number) => {
      handleSetChildId(id);
      if (!formIsOpened) setFormIsOpened(true);
      form.clearErrors('children');
    },
    // eslint-disable-next-line
    [formIsOpened, handleSetChildId]
  );

  const handleAddChild = useCallback(() => {
    handleSetChildId(null);
    form.setValue('children', childDefaultValues);
    form.trigger('children');
    // eslint-disable-next-line
  }, [formIsOpened, handleSetChildId]);

  return (
    <Grid container>
      <Grid container>
        <Typography variant="caption" className={classes.heading}>
          Child info
        </Typography>
      </Grid>
      {!!parent?.children.length && (
        <>
          <Grid container>
            <Typography variant="subtitle2" className={classes.title}>
              Please select a child to fill out the form below
            </Typography>
          </Grid>
          <Grid
            container
            justify="space-between"
            alignItems="center"
            className={classes.childContainer}
          >
            <Grid container item xs={12} sm={9} md={9} lg={9} className={classes.childrenContainer}>
              {parent?.children.map(({ first_name, last_name, id }) => (
                <Typography
                  key={id}
                  variant="body1"
                  onClick={() => handleSelectChild(id)}
                  className={cx(classes.child, {
                    [classes.childSelected]: currentChildId === id,
                  })}
                >
                  {first_name} {last_name}
                </Typography>
              ))}
            </Grid>
            <Grid
              container
              justify="flex-end"
              item
              xs={12}
              sm={3}
              md={3}
              lg={3}
              className={classes.addChildButton}
            >
              <Button
                size="small"
                width="134px"
                variant="outlined"
                label="Add a Child"
                startIcon={<Plus />}
                onClick={handleAddChild}
              />
            </Grid>
          </Grid>
        </>
      )}
      {formIsOpened && (
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6} md={6} lg={6}>
            <OutlinedInputController
              required
              fullWidth
              placeholder="First Name"
              name="children.first_name"
              eventsIsDisabled={!!currentChildId}
              errorField={form.errors.children?.first_name}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={6}>
            <OutlinedInputController
              required
              fullWidth
              placeholder="Last Name"
              name="children.last_name"
              eventsIsDisabled={!!currentChildId}
              errorField={form.errors.children?.last_name}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={6}>
            <DatePickerController
              width="100%"
              name="children.birthday"
              placeholder="Date of birth"
              disabled={!!currentChildId}
              errorField={form.errors.children?.birthday}
              readOnly={false}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={6}>
            <OutlinedInputController
              fullWidth
              placeholder="Allergies"
              name="children.allergies"
              eventsIsDisabled={!!currentChildId}
              errorField={form.errors.children?.allergies}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={6}>
            <SelectController
              fullWidth
              placeholder="Gender"
              name="children.gender"
              options={genderOptions}
              eventsIsDisabled={!!currentChildId}
              errorField={form.errors.children?.gender}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={6}>
            <SelectController
              fullWidth
              placeholder="Grade level"
              name="children.grade_level"
              options={gradeLevelOptions}
              eventsIsDisabled={!!currentChildId}
              errorField={form.errors.children?.grade_level}
            />
          </Grid>
          <Grid item xs={12}>
            <OutlinedInputController
              fullWidth
              placeholder="School"
              name="children.school"
              eventsIsDisabled={!!currentChildId}
              errorField={form.errors.children?.school}
            />
          </Grid>
        </Grid>
      )}
      <Grid container justify="center" className={classes.buttonsContainer}>
        <Button disabled={buttonsIsDisabled} variant="text" label="Cancel" onClick={handleCancel} />
        <Button disabled={buttonsIsDisabled} type="submit" label="Add" width="168px" />
      </Grid>
    </Grid>
  );
};
