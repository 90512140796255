import React, { FunctionComponent, useState } from 'react';
import { useStore } from 'hooks/useStore';
import useStyles from './styles';
import cx from 'classnames';
// components
import { Button } from 'components';
// icons
import { ReactComponent as Link } from 'assets/svg/link.svg';
import { OutlinedButtonType } from 'components/Button/types';
import { Tooltip } from '@material-ui/core';

const CopyBusinessLinkButton: FunctionComponent = () => {
  const classes = useStyles();
  const { user } = useStore();

  const [isClicked, setIsClicked] = useState<boolean>(false);

  const copyToClipboardHandler = () => {
    setIsClicked(true);
    navigator.clipboard.writeText(`${process.env.PUBLIC_URL}/${user?.business_profile?.slug}`);
    setTimeout(() => setIsClicked(false), 2000);
  };

  return (
    <>
      <Tooltip
        arrow
        open={isClicked}
        disableFocusListener
        disableHoverListener
        placement="bottom"
        title="Copy to clipboard"
        classes={{ tooltip: classes.tooltip, arrow: classes.tooltipArrow }}
      >
        <div>
          <Button
            type="button"
            variant="outlined"
            outlinedButtonType={OutlinedButtonType.white}
            withStrokeIcon
            width="auto"
            size="small"
            label="Your short URL"
            startIcon={<Link />}
            className={cx({ [classes.isActive]: isClicked })}
            onClick={copyToClipboardHandler}
          />
        </div>
      </Tooltip>
    </>
  );
};

export default CopyBusinessLinkButton;
