import React, { FunctionComponent, memo, useCallback, useMemo } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers';
import moment from 'moment';
import cx from 'classnames';
// material-ui components
import { Grid, Typography } from '@material-ui/core';
// components
import { Button } from 'components';
// controllers
import { DatePickerController } from 'controllers';
// types
import { OutlinedButtonType } from 'components/Button/types';
import { IPromoteItem } from './types';
// validations
import { Schema } from './validations';
// styles
import useStyles from './styles';

export const PromoteItem: FunctionComponent<IPromoteItem> = memo(
  ({ isCentered = false, isLeftSection, data, onSubmit }) => {
    const classes = useStyles({ isCentered, isLeftSection });
    const form = useForm({ resolver: yupResolver(Schema) });

    const onSubmitForm = useCallback(
      (formData) => {
        onSubmit({ ...formData, promotion: { id: data.id } });
      },
      [onSubmit, data.id]
    );

    const amount = useMemo(() => {
      const startDate = form.watch('start_date');
      const endDate = form.watch('end_date');
      if (startDate && endDate) {
        const duration = moment.duration(moment(endDate).diff(moment(startDate))).asDays() || 0;
        if (duration > 0) return `${Math.round(duration)} ${duration === 1 ? 'day' : 'days'}`;
      }
      return '0 days';
    }, [form]);

    const availableEndDay = useMemo(() => form.watch('start_date'), [form]);

    return (
      <FormProvider {...form}>
        <Grid
          container
          item
          className={classes.container}
          component="form"
          onSubmit={form.handleSubmit(onSubmitForm)}
        >
          <Typography variant="h4" className={classes.heading}>
            {data.title}
          </Typography>
          <Typography variant="body1" className={classes.description}>
            {data.description}
          </Typography>
          <Grid container className={classes.textContainer}>
            <Grid container justify="space-between" alignItems="center" wrap="nowrap">
              <Grid item xs={4}>
                <span
                  className={cx(classes.boldText, classes.date, {
                    [classes.greyText]: !isCentered,
                  })}
                >
                  Start date
                </span>
              </Grid>
              <Grid item xs={8}>
                <DatePickerController
                  fullWidth
                  isStartDate
                  disabledPast
                  name="start_date"
                  placeholder="Choose date"
                  id={`${data.id}_start_date`}
                  errorField={form.errors.start_date}
                  horizontal={isLeftSection || isCentered ? 'left' : 'right'}
                />
              </Grid>
            </Grid>
            <Grid container justify="space-between" alignItems="center" wrap="nowrap">
              <Grid item xs={4}>
                <span
                  className={cx(classes.boldText, classes.date, {
                    [classes.greyText]: !isCentered,
                  })}
                >
                  End date
                </span>
              </Grid>
              <Grid item xs={8}>
                <DatePickerController
                  isEndDate
                  fullWidth
                  disabledPast
                  name="end_date"
                  placeholder="Choose date"
                  id={`${data.id}_end_date`}
                  availableDay={availableEndDay}
                  errorField={form.errors.end_date}
                  horizontal={isLeftSection || isCentered ? 'left' : 'right'}
                />
              </Grid>
            </Grid>
            <Grid
              container
              alignItems="center"
              justify="space-between"
              className={classes.amountContainer}
            >
              <span className={cx(classes.boldText, { [classes.greyText]: !isCentered })}>
                Amount
              </span>
              <span className={classes.boldText}>{amount}</span>
            </Grid>
          </Grid>
          <Grid container className={classes.buttonContainer}>
            <Button
              fullWidth
              type="submit"
              label="Start now"
              variant="outlined"
              outlinedButtonType={OutlinedButtonType.orange}
            />
          </Grid>
        </Grid>
      </FormProvider>
    );
  }
);
