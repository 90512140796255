import React, { memo } from 'react';
import { DotProps } from 'react-multi-carousel';
import cx from 'classnames';
// styles
import useStyles from './styles';

export const CarouselDot = memo(({ active, onClick }: DotProps) => {
  const classes = useStyles();

  return (
    <button
      type="button"
      aria-label="dot"
      className={cx(classes.dot, { [classes.dotActive]: active })}
      onClick={onClick}
    />
  );
});
