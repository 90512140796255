import React, { FunctionComponent } from 'react';
// colors
import colors from 'styles/colors';

interface ILogo {
  headerIsTransparent: boolean;
}

export const Logo: FunctionComponent<ILogo> = ({ headerIsTransparent }) => {
  const currentColor = headerIsTransparent ? colors.white : colors.cyan;

  return (
    <svg width="96" height="40" viewBox="0 0 96 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M19.4524 31.154H13.123L5.4433 21.5306V31.154H0V1.47852H5.4433V19.2838L12.701 9.95722H19.1992L10.7178 20.4284L19.4524 31.154Z"
        fill={currentColor}
      />
      <path d="M22.1694 31.154V9.95722H27.6127V31.154H22.1694Z" fill={currentColor} />
      <path
        d="M46.4774 9.95722H52.4692L44.4519 31.154H38.2491L30.2319 9.95722H36.2237L41.3294 24.7949L46.4774 9.95722Z"
        fill={currentColor}
      />
      <path d="M55.0939 31.154V9.95722H60.5372V31.154H55.0939Z" fill={currentColor} />
      <path
        d="M76.7799 15.214H69.7059V24.0319C69.7059 24.7667 69.8888 25.3037 70.2545 25.6428C70.6202 25.982 71.1547 26.1798 71.8579 26.2363C72.5612 26.2646 73.4333 26.2504 74.4741 26.1939V31.154C70.7327 31.5779 68.0884 31.2246 66.5412 30.0941C65.0222 28.9636 64.2626 26.9429 64.2626 24.0319V9.04003L66.0635 5.36795H69.7059V9.95722H74.4741L76.7799 15.214Z"
        fill={currentColor}
      />
      <path
        d="M90.1769 9.95722H96L88.3203 31.154C87.2232 34.2063 85.7604 36.4108 83.9319 37.7673C82.1316 39.1522 79.8811 39.774 77.1805 39.6327V34.5454C78.6433 34.5737 79.7967 34.2628 80.6406 33.6128C81.5127 32.9627 82.2019 31.917 82.7082 30.4757L74.058 9.95722H80.0077L85.4932 24.2014L90.1769 9.95722Z"
        fill={currentColor}
      />
      <path
        d="M25.54 6.74057C24.6399 6.74057 22.2065 6.74057 22.2065 6.74057C22.2065 6.74057 22.2065 4.29587 22.2065 3.39148C22.2065 2.48709 22.53 1.69574 23.1771 1.01744C23.8522 0.339148 24.6399 0 25.54 0C26.4684 0 28.9157 0 28.9157 0C28.9157 0 28.9157 2.48709 28.9157 3.39148C28.9157 4.29587 28.5782 5.08722 27.903 5.76552C27.256 6.41555 26.4684 6.74057 25.54 6.74057Z"
        fill="#FF8A00"
      />
      <path
        d="M58.4645 6.74057C57.5644 6.74057 55.131 6.74057 55.131 6.74057C55.131 6.74057 55.131 4.29587 55.131 3.39148C55.131 2.48709 55.4545 1.69574 56.1016 1.01744C56.7767 0.339148 57.5644 0 58.4645 0C59.3928 0 61.8402 0 61.8402 0C61.8402 0 61.8402 2.48709 61.8402 3.39148C61.8402 4.29587 61.5027 5.08722 60.8275 5.76552C60.1805 6.41555 59.3928 6.74057 58.4645 6.74057Z"
        fill="#FF8A00"
      />
    </svg>
  );
};
