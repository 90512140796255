import { createStyles, makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) =>
  createStyles({
    detailsHeading: {
      marginBottom: '16px',
      fontSize: '14px',
      lineHeight: '16px',
      fontWeight: 'bold',
      letterSpacing: '1.5px',
      color: theme.colors.black,
      textTransform: 'uppercase',
    },
    day: {
      fontWeight: 600,
      textTransform: 'capitalize',
      whiteSpace: 'nowrap',
    },
    hoursContainer: {
      marginBottom: '10px',
    },
    closed: {
      color: theme.colors.orange,
    },
    closedDay: {
      color: theme.colors.grey3,
    },
    time: {
      whiteSpace: 'nowrap',
    },
  })
);
