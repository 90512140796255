import React, { FunctionComponent, memo } from 'react';
// material-ui components
import { Modal, Backdrop, Slide } from '@material-ui/core';
// components
import { ModalContent } from './components';
// types
import { IModalLayout } from './types';
// styles
import useStyles from './styles';

const ModalLayout: FunctionComponent<IModalLayout> = ({
  children,
  isModalOpen,
  handleModalClick,
  slideDirection,
}) => {
  const classes = useStyles();

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={classes.modal}
      open={isModalOpen}
      onClose={handleModalClick}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Slide unmountOnExit in={isModalOpen} direction={slideDirection || 'up'}>
        <ModalContent close={handleModalClick}>{children}</ModalContent>
      </Slide>
    </Modal>
  );
};

export default memo(ModalLayout);
