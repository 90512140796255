import React, { FunctionComponent, memo } from 'react';

interface Props {
  isActive?: boolean;
}

export const CreditCard: FunctionComponent<Props> = memo(({ isActive = false }) => {
  const color = isActive ? '#268AFF' : '#B9C1CC';
  return (
    <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19 4.2323C20.6569 4.2323 22 5.57545 22 7.2323V17.2323C22 18.8892 20.6569 20.2323 19 20.2323H5C3.34315 20.2323 2 18.8892 2 17.2323V7.2323C2 5.57545 3.34315 4.2323 5 4.2323H19ZM4 12.2323V17.2323C4 17.7846 4.44772 18.2323 5 18.2323H19C19.5523 18.2323 20 17.7846 20 17.2323V12.2323H4ZM4 8.2323H20V7.2323C20 6.68002 19.5523 6.2323 19 6.2323H5C4.44772 6.2323 4 6.68002 4 7.2323V8.2323Z"
        fill={color}
      />
    </svg>
  );
});
