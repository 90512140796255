import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';
// material-ui components
import { Grid } from '@material-ui/core';
// styles
import { useStylesBanner } from './styles';
// images
import heart from 'assets/images/HomePage/UkrainianHeart.png';
import flag from 'assets/images/HomePage/UkrainianFlag.png';
// icons
import { ReactComponent as Stand } from 'assets/svg/Stand.svg';
import { ReactComponent as Ukraine } from 'assets/svg/Ukraine.svg';
import { Close } from 'assets/icons';
// modals
import { SupportUkraine } from 'modals';

const BannerForUkraine = ({ isTransparent }: { isTransparent: boolean }) => {
  const classes = useStylesBanner();

  const [isBannerHide, setBannerHide] = useState(() => {
    let isHidden = false;
    try {
      isHidden = JSON.parse(String(sessionStorage.getItem('hiddenBanner')));
    } catch (error) {
      console.error(`"Get state error: " ${error}`);
    }
    return !!isHidden;
  });

  useEffect(() => {
    if (sessionStorage.getItem('hiddenBanner') === 'true') {
      setBannerHide(true);
    }
  }, []);

  const closeBanner = () => {
    setBannerHide(true);
    try {
      sessionStorage.setItem('hiddenBanner', JSON.stringify(true));
    } catch (error) {
      console.error(`"Get state error: " ${error}`);
    }
  };

  const [modalIsOpen, setIsOpen] = useState(false);
  function openModal() {
    setIsOpen(true);
    setBannerHide(true);
  }
  function closeModal() {
    setIsOpen(false);
    setBannerHide(false);
  }

  return (
    <>
      {!isBannerHide && !isTransparent && (
        <div className={classes.root}>
          <img src={heart} alt="banner" className={classes.heart} />
          <div className={classes.standWithUkraineContaner}>
            <div className={classes.standWithContaner}>
              <Stand className={classes.standWith} />
              <img src={flag} alt="banner" className={classes.flag} />
            </div>
            <Ukraine className={classes.ukraine} />
          </div>
          <p className={classes.text}>
            We stand with and in support of Ukraine and of peace everywhere.{' '}
            <button className={classes.supportLink} type="button" onClick={openModal}>
              Learn about what you can do
            </button>{' '}
            today to help families and the people in Ukraine.
          </p>

          <div className={classes.closeBtn} onClick={closeBanner}>
            <Grid item className={classes.close}>
              <Close />
            </Grid>
          </div>
        </div>
      )}
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={{
          overlay: {
            display: 'flex',
            position: 'fixed',
            alignItems: 'center',
            justifyContent: 'center',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            border: 'none',
            backgroundColor: 'transparent',
            overflowY: 'auto',
            zIndex: 1301,
          },
          content: {
            position: 'absolute',
            top: 'auto',
            left: 'auto',
            right: 'auto',
            bottom: 'auto',
            inset: '10%',
            display: 'flex',
            flexDirection: 'column',
            backgroundColor: 'transparent',
            overflow: 'visible',
            outline: 'none',
            border: 'none',
            padding: ' 25px 0',
          },
        }}
        contentLabel="Ukraine Modal"
      >
        <SupportUkraine>
          <div className={classes.closeModalContainer} onClick={() => closeModal()}>
            <Grid item className={classes.closeModalBtn}>
              <Close />
            </Grid>
          </div>
        </SupportUkraine>
      </Modal>
    </>
  );
};
export default BannerForUkraine;
