import { createStyles, makeStyles } from '@material-ui/core/styles';

export default makeStyles(() =>
  createStyles({
    wrapper: {
      maxWidth: '900px',
      padding: '0 30px 20px 20px',
    },
    covidInfoBlueHeading: {
      marginLeft: '12px',
    },
    covidInfoText: {
      marginTop: '12px',
    },
    text: {
      wordBreak: 'break-word',
    },
  })
);
