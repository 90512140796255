import React, { FunctionComponent, memo } from 'react';

interface Props {
  onClick?: () => void;
}

export const ChevronUp: FunctionComponent<Props> = memo((props) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M17.005 15.4C16.785 15.4 16.5849 15.32 16.4249 15.16L12.005 10.56L7.58496 15.16C7.28496 15.48 6.76494 15.48 6.44494 15.18C6.12494 14.88 6.12495 14.36 6.42495 14.04L11.4249 8.83998C11.5849 8.67998 11.785 8.59998 12.005 8.59998C12.225 8.59998 12.425 8.67998 12.585 8.83998L17.585 14.04C17.885 14.36 17.885 14.86 17.565 15.18C17.405 15.32 17.205 15.4 17.005 15.4Z"
      fill="#B9C1CC"
    />
  </svg>
));
