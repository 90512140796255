import React, { FunctionComponent, memo } from 'react';

interface Props {
  className?: string;
  onClick?: () => void;
}

export const Search: FunctionComponent<Props> = memo((props) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.88886 10.5555C4.88886 7.43033 7.43033 4.88886 10.5555 4.88886C13.6806 4.88886 16.222 7.43033 16.222 10.5555C16.222 13.6806 13.6806 16.222 10.5555 16.222C7.43033 16.222 4.88886 13.6806 4.88886 10.5555ZM19.723 18.3876L16.5167 15.1803C17.5112 13.9016 18.1109 12.2989 18.1109 10.5555C18.1109 6.38956 14.7213 3 10.5555 3C6.38956 3 3 6.38956 3 10.5555C3 14.7213 6.38956 18.1109 10.5555 18.1109C12.2989 18.1109 13.9016 17.5112 15.1803 16.5167L18.3876 19.723C18.5718 19.9072 18.8136 19.9998 19.0553 19.9998C19.2971 19.9998 19.5389 19.9072 19.723 19.723C20.0923 19.3538 20.0923 18.7569 19.723 18.3876Z"
      fill="#B9C1CC"
    />
  </svg>
));
