import { createStyles, makeStyles } from '@material-ui/core/styles';

export default makeStyles(() =>
  createStyles({
    container: {
      margin: '32px 0 48px',
    },
    heading: {
      marginBottom: '24px',
    },
  })
);
