import { createStyles, makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) =>
  createStyles({
    wrapper: {
      width: '760px',
      padding: '0 50px 50px 30px',
      [theme.breakpoints.only('xs')]: {
        padding: '0 0 30px 0',
      },
    },
    heading: {
      padding: '15px 0',
    },
    textContainer: {
      marginBottom: '48px',
    },
    form: {
      padding: '45px 35px 0 15px',
    },
    buttonsContainer: {
      marginTop: '30px',
    },
    button: {
      margin: '0 15px',
    },
    inputContainer: {
      padding: '12px 15px',
    },
    child: {
      marginRight: '24px',
      fontWeight: 600,
      cursor: 'pointer',
      '&:hover': {
        color: theme.colors.cyan,
      },
    },
    childSelected: {
      color: theme.colors.cyan,
    },
    childHeading: {
      marginBottom: '24px',
    },
    contentContainer: {
      [theme.breakpoints.only('xs')]: {
        flexDirection: 'column',
        justifyContent: 'unset',
        alignItems: 'unset',
      },
    },
    searchButtonContainer: {
      [theme.breakpoints.only('xs')]: {
        justifyContent: 'unset',
        marginTop: '40px',
      },
    },
  })
);
