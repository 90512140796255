import React, { FunctionComponent, useState, MouseEvent, useCallback } from 'react';
import { Link } from 'react-router-dom';
// material-ui components
import { Menu, MenuItem, Grid, Typography } from '@material-ui/core';
// components
import { UserAvatar } from 'components/UserAvatar';
import { Button } from 'components/Button';
// icons
import { Close } from 'assets/icons';
import { ReactComponent as MenuIcon } from 'assets/icons/origin/Menu.svg';
import { ReactComponent as Dots } from 'assets/icons/origin/Dots.svg';
import CartIcon from 'assets/icons/Cart';
// hooks
import { useStore } from 'hooks/useStore';
// types
import { IAvatar } from './types';
import { AvatarGender } from 'types';
import { OutlinedButtonType } from '../Button/types';
// styles
import useStyles from './styles';
import { Roles } from 'utils/constants';

export const Avatar: FunctionComponent<IAvatar> = ({
  photo = '',
  links,
  isBusiness,
  hiddenAvatar,
  isMini = false,
  isUnauthorized,
  isBusinessLandingPage,
}) => {
  const classes = useStyles({ isMini });

  const { logOut, cart, user } = useStore();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleClick = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogOut = useCallback(() => {
    logOut();
    setAnchorEl(null);
  }, [logOut]);

  return (
    <>
      <Grid item className={classes.container}>
        {!hiddenAvatar ? (
          <>
            <div className={classes.imageContainer} onClick={handleClick}>
              <UserAvatar
                alt="profile avatar"
                src={photo}
                size="48px"
                gender={isBusiness ? AvatarGender.business : undefined}
              />
            </div>
            {(user.role === Roles.parent || isUnauthorized) && (
              <Link to="/cart" className={classes.cart}>
                <CartIcon variant="small" count={cart.length} />
              </Link>
            )}
            <div className={classes.dotsContainer} onClick={handleClick}>
              <Dots />
            </div>
          </>
        ) : (
          <div className={classes.menuContainer} onClick={handleClick}>
            {anchorEl ? <Close /> : <MenuIcon />}
          </div>
        )}
      </Grid>
      {!!anchorEl && (
        <Menu
          anchorEl={anchorEl}
          open={!!anchorEl}
          keepMounted
          onClose={handleClose}
          elevation={0}
          getContentAnchorEl={null}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          classes={{ list: classes.list, paper: classes.paper }}
        >
          {links.map(({ path, label }) => (
            <MenuItem
              key={path}
              component={Link}
              to={path}
              classes={{ root: classes.item }}
              onClick={handleClose}
            >
              {label}
            </MenuItem>
          ))}
          {!isUnauthorized && (
            <MenuItem onClick={handleLogOut} classes={{ root: classes.item }}>
              Log out
            </MenuItem>
          )}
          {isUnauthorized && (
            <Grid container direction="column" className={classes.info}>
              <Grid container className={classes.buttonsContainer}>
                {isBusinessLandingPage ? (
                  <Button
                    width="auto"
                    size="small"
                    component={Link}
                    variant="outlined"
                    to="/business-registration"
                    label="Claim Your Free Business Account"
                    outlinedButtonType={OutlinedButtonType.orange}
                  />
                ) : (
                  <>
                    <Button
                      width="150px"
                      component={Link}
                      variant="outlined"
                      to="/business"
                      label="FOR BUSINESSES"
                      outlinedButtonType={OutlinedButtonType.orange}
                      onClick={handleClose}
                      className={classes.button}
                    />
                    <Button
                      component={Link}
                      to="/login"
                      width="150px"
                      label="LOGIN"
                      onClick={handleClose}
                      className={classes.button}
                    />
                  </>
                )}
              </Grid>
              <Typography variant="h5" paragraph>
                Contacts
              </Typography>
              <Typography variant="body1">+1 (941) 914 8644</Typography>
              <Typography variant="body1">Sarasota, Florida, USA</Typography>
            </Grid>
          )}
        </Menu>
      )}
    </>
  );
};
