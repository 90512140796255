import React, { FunctionComponent, memo } from 'react';
import { useHistory } from 'react-router-dom';
// components
import { Avatar } from 'components';
// icons
import { Search as SearchIcon } from 'assets/icons';
// constants
import { Roles } from 'utils/constants';
import { businessLandingLinks, links } from 'layouts/Header/constants';
// types
import { IHeaderButtons } from './types';
// styles
import useStyles from './styles';

const HeaderButtonsSmall: FunctionComponent<IHeaderButtons> = ({
  role,
  avatar,
  headerIsTransparent,
  isBusinessLandingPage,
}) => {
  const classes = useStyles({ headerIsTransparent });
  const history = useHistory();

  const handleSearch = () => {
    history.push({ pathname: '/search' });
  };

  if (isBusinessLandingPage) {
    return (
      <div className={classes.buttonsContainer}>
        <Avatar
          links={businessLandingLinks.mobileMenu}
          hiddenAvatar={true}
          isMini={true}
          isUnauthorized={true}
          isBusinessLandingPage
        />
      </div>
    );
  }

  if (role === Roles.unauthorized) {
    return (
      <div className={classes.buttonsContainer}>
        <SearchIcon className={classes.search} onClick={handleSearch} />
        <Avatar
          links={links[role].mobileMenu}
          hiddenAvatar={true}
          isMini={true}
          isUnauthorized={true}
        />
      </div>
    );
  }

  return (
    <div className={classes.rightContentContainer}>
      <SearchIcon className={classes.search} onClick={handleSearch} />
      <Avatar
        photo={avatar || ''}
        links={links[role].mobileMenu}
        isBusiness={role === Roles.business}
      />
    </div>
  );
};

export default memo(HeaderButtonsSmall);
