import React, { FunctionComponent } from 'react';
import { useFormContext } from 'react-hook-form';
// material-ui components
import { Grid, Typography } from '@material-ui/core';
// components
import { Phone } from 'components/Masks';
// controllers
import { OutlinedInputController } from 'controllers';
// types
import { IParentForm } from './types';
// styles
import useStyles from './styles';

export const ParentForm: FunctionComponent<IParentForm> = ({ isReadOnly }) => {
  const classes = useStyles();
  const form = useFormContext();

  return (
    <Grid container className={classes.container}>
      <Grid container>
        <Typography variant="caption" className={classes.heading}>
          Parent info
        </Typography>
      </Grid>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6} md={6} lg={6}>
          <OutlinedInputController
            required
            fullWidth
            name="parent.first_name"
            placeholder="First Name"
            eventsIsDisabled={isReadOnly}
            errorField={form.errors.parent?.first_name}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={6} lg={6}>
          <OutlinedInputController
            required
            fullWidth
            name="parent.last_name"
            placeholder="Last Name"
            eventsIsDisabled={isReadOnly}
            errorField={form.errors.parent?.last_name}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={6} lg={6}>
          <OutlinedInputController
            fullWidth
            name="parent.address"
            placeholder="Address"
            errorField={form.errors.parent?.address}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={2} lg={2}>
          <OutlinedInputController
            fullWidth
            name="parent.zip"
            placeholder="Zip"
            eventsIsDisabled={isReadOnly}
            errorField={form.errors.parent?.zip}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={4}>
          <OutlinedInputController
            fullWidth
            name="parent.city"
            placeholder="City"
            eventsIsDisabled={isReadOnly}
            errorField={form.errors.parent?.city}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={6} lg={6}>
          <OutlinedInputController
            required
            fullWidth
            name="parent.email"
            placeholder="Contact Email"
            eventsIsDisabled={isReadOnly}
            errorField={form.errors.parent?.email}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={6} lg={6}>
          <OutlinedInputController
            required
            fullWidth
            name="parent.phone"
            inputComponent={Phone}
            placeholder="Contact Phone Number"
            errorField={form.errors.parent?.phone}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};
