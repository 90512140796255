import React, { ChangeEvent, FC, useState } from 'react';
// material-ui components
import { Grid, Typography } from '@material-ui/core';
// components
import { Button, Checkbox } from 'components';
// types
import { IChildren } from 'types';
// icons
import { ReactComponent as KidsPhoto } from 'assets/icons/origin/KidsPhoto.svg';
// styles
import useStyles from './styles';

interface IProps {
  title: string;
  subTitle: string;
  onOk: (childrenIds: SelectedChildrenType) => void;
  onClose: () => void;
  childrenList: IChildren[];
  spaceLeft: number;
  subTitleBold?: string;
}

export type SelectedChildrenType = { [key: number]: boolean | undefined };

export const NoSpaceForChildrenModal: FC<IProps> = ({
  title,
  subTitle,
  childrenList = [],
  onOk,
  onClose,
  spaceLeft,
  subTitleBold,
}) => {
  const classes = useStyles();

  const [selectedChildren, setSelectedChildren] = useState<SelectedChildrenType>({});

  const onSelectChild = (item: IChildren) => (
    _e: ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => {
    const countOfSelectedChildren = Object.values(selectedChildren).filter((i) => i).length;

    if ((checked && countOfSelectedChildren < spaceLeft) || !checked) {
      setSelectedChildren((prevState: SelectedChildrenType) => ({
        ...prevState,
        [item.id]: !prevState[item.id],
      }));
    }
  };

  const childrenCheckbox = childrenList.map((item) => (
    <Checkbox
      key={item.id}
      onChange={onSelectChild(item)}
      checked={!!selectedChildren[item.id]}
      label={`${item.first_name} ${item.last_name}`}
    />
  ));

  return (
    <Grid container direction="column" alignItems="center" className={classes.wrapper}>
      <Grid item>
        <KidsPhoto />
      </Grid>
      <Grid container justify="center" item>
        <Typography variant="h6" className={classes.heading}>
          {title}
        </Typography>
      </Grid>
      <Grid item className={classes.body}>
        {subTitleBold && <Typography variant="h6">{subTitleBold}</Typography>}
        <Typography variant="body1">{subTitle}</Typography>
      </Grid>
      <Grid container justify="center" item>
        <div className={classes.checkboxWrap}>{childrenCheckbox}</div>
      </Grid>
      <Grid container item justify="space-evenly" className={classes.buttonContainer}>
        <Button label="No, Thanks" variant="text" onClick={onClose} />
        <Button label="Ok" onClick={() => onOk(selectedChildren)} />
      </Grid>
    </Grid>
  );
};
