import React, { FunctionComponent, memo } from 'react';
import cx from 'classnames';
// styles
import useStyles from './styles';

export const Spinner: FunctionComponent<{
  isFullWidth?: boolean;
  backdrop?: boolean;
  Component?: any;
}> = memo(({ isFullWidth = false, backdrop, Component = 'div' }) => {
  const classes = useStyles();

  return (
    <Component
      className={cx(classes.container, {
        [classes.fullContainer]: isFullWidth,
        [classes.backdropContainer]: backdrop,
      })}
    >
      <Component className={classes.spinner} />
    </Component>
  );
});
