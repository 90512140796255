import React, { FunctionComponent, memo } from 'react';
// material-ui
import { Checkbox as CheckboxMU, FormControlLabel } from '@material-ui/core';
// utils
import cx from 'classnames';
// styles
import { useBoxLabelStyles, useBoxStyles } from 'components/commonBoxStyles';
// types
import { BoxType } from 'components/types';
import { Props } from './types';

export const Checkbox: FunctionComponent<Props> = memo<Props>(
  ({ sizePx = 24, label, marginLeft, onClick, isReadOnly, ...rest }) => {
    const classes = useBoxStyles({ sizePx, type: BoxType.Checkbox });
    const classesLabel = useBoxLabelStyles({ checked: rest.checked, marginLeft, isReadOnly });
    return (
      <FormControlLabel
        control={
          <CheckboxMU
            {...rest}
            onClick={onClick}
            icon={<span className={classes.icon} />}
            checkedIcon={<span className={cx(classes.icon, classes.checkedIcon)} />}
            color="primary"
            disableRipple
            className={classes.root}
          />
        }
        classes={classesLabel}
        label={label}
      />
    );
  }
);
