import React, { FunctionComponent, memo } from 'react';

interface Props {
  onClick?: () => void;
  className?: string;
}

export const ArrowRight: FunctionComponent<Props> = memo((props) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5 13H16.17L12.29 16.88C11.9 17.27 11.9 17.91 12.29 18.3C12.68 18.69 13.31 18.69 13.7 18.3L19.29 12.71C19.68 12.32 19.68 11.69 19.29 11.3L13.71 5.69997C13.32 5.30997 12.69 5.30997 12.3 5.69997C11.91 6.08997 11.91 6.71997 12.3 7.10997L16.17 11H5C4.45 11 4 11.45 4 12C4 12.55 4.45 13 5 13Z"
      fill="#B9C1CC"
    />
  </svg>
));
