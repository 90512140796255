import React, { memo, FunctionComponent } from 'react';
import cx from 'classnames';
// @material-ui
import { Typography } from '@material-ui/core';
// icons
import { Asterics } from 'assets/icons';
// styles
import useStyles from './styles';

interface Props {
  message?: string;
  error?: boolean;
  required?: boolean;
  centered?: boolean;
}

export const Error: FunctionComponent<Props> = memo(
  ({ message = '', error = false, required = false, centered = false }) => {
    const classes = useStyles();

    if (!error) return null;

    return (
      <Typography className={classes.error} component="span">
        {required && (
          <Asterics className={cx(classes.asterics, { [classes.centered]: centered })} />
        )}
        {error && message}
      </Typography>
    );
  }
);
