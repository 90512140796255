import React, { FunctionComponent } from 'react';
// form
import { Controller, useFormContext } from 'react-hook-form';
// types
import { SwitchProps } from '@material-ui/core';
// components
import { Switch } from 'components/Switch';

interface Props extends SwitchProps {
  helperChange?: (val: boolean) => void;
}

export const SwitchController: FunctionComponent<Props> = ({
  name = '',
  defaultChecked = false,
  helperChange,
  disabled,
}) => {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultChecked}
      render={(props) => (
        <Switch
          checked={props.value || false}
          disabled={disabled}
          onChange={(e) => {
            props.onChange(e.target.checked);
            helperChange && helperChange(e.target.checked);
          }}
        />
      )}
    />
  );
};
