import React, { memo } from 'react';
import { ButtonGroupProps } from 'react-multi-carousel';
import cx from 'classnames';
// icons
import { ReactComponent as LongCyanArrowRight } from 'assets/icons/origin/LongCyanArrowRight.svg';
// styles
import useStyles from './styles';

export const CarouselButtonGroup = memo(({ next, previous, carouselState }: ButtonGroupProps) => {
  const classes = useStyles();

  if (carouselState?.totalItems === 0) return null;

  return (
    <div className={classes.buttonsContainer}>
      <button type="button" onClick={previous} className={cx(classes.arrow, classes.reverse)}>
        <LongCyanArrowRight />
      </button>
      <button type="button" onClick={next} className={classes.arrow}>
        <LongCyanArrowRight />
      </button>
    </div>
  );
});
