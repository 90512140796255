import React, { FunctionComponent } from 'react';
// form
import { Controller, useFormContext } from 'react-hook-form';
// @material-ui
import Rating, { RatingProps } from '@material-ui/lab/Rating';
// icons
import { ReactComponent as ReviewStarEmpty } from 'assets/icons/origin/ReviewStarEmpty.svg';
import { ReactComponent as ReviewStarFull } from 'assets/icons/origin/ReviewStarFull.svg';

interface Props extends RatingProps {
  name: string;
}

export const RatingController: FunctionComponent<Props> = ({ name = '' }) => {
  const {
    control,
    formState: { isSubmitted },
  } = useFormContext();

  return (
    <Controller
      name={name}
      defaultValue={null}
      control={control}
      isSubmitted={isSubmitted}
      render={({ onChange, value }) => (
        <Rating
          name={name}
          icon={<ReviewStarFull />}
          emptyIcon={<ReviewStarEmpty />}
          onChange={(_e, val) => onChange(val)}
          value={value}
        />
      )}
    />
  );
};
