export enum Rates {
  monthly = 'monthly',
  quarterly = 'quarterly',
  yearly = 'yearly',
}

export interface ICost {
  id: number;
  pricePerMonth: number;
  rate: {
    id: number;
    title: string;
    monthMultiplier: number;
    durationVariants: number[];
  };
}

export interface IPromote {
  id: number;
  type: string;
  title: string;
  description: string;
  costs: ICost[];
  activeClasses: number;
  totalClasses: number;
  totalSpaces: number;
  occupiedSpaces: number;
}

export enum PromoteTypes {
  platinum = 'Platinum',
  premium = 'Premium',
  elite = 'Elite',
}
