import React, { FunctionComponent } from 'react';
// material-ui components
import { Grid, Typography } from '@material-ui/core';
// components
import { Button } from 'components';
// icons
import { ReactComponent as Coffee } from 'assets/icons/origin/Coffee.svg';
// custom hooks
import { useModalSetter } from 'hooks/useModal';
// styles
import useStyles from './styles';

export const BusinessStripeVerifying: FunctionComponent = () => {
  const { setModal } = useModalSetter();
  const classes = useStyles();

  const handleCloseModal = () => {
    setModal(null);
  };

  return (
    <Grid container direction="column" alignItems="center" className={classes.wrapper}>
      <Grid item>
        <Coffee />
      </Grid>
      <Grid item>
        <Typography variant="h5" className={classes.heading}>
          Almost done...
        </Typography>
      </Grid>
      <Grid item>
        <Typography variant="body1">Stripe is verifying Your account details.</Typography>
      </Grid>
      <Grid item>
        <Typography variant="body1">
          Please try to initiate Stripe activation again in few hrs
        </Typography>
      </Grid>
      <Grid item className={classes.buttonContainer}>
        <Button label="Ok" width="220px" onClick={handleCloseModal} />
      </Grid>
    </Grid>
  );
};
