import { createStyles, makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) =>
  createStyles({
    wrapper: {
      width: '500px',
      paddingBottom: '20px',
      [theme.breakpoints.only('xs')]: {
        width: '100%',
      },
    },
    heading: {
      margin: '16px 0',
      textAlign: 'center',
      fontWeight: 600,
      '@media (max-width:600px)': {
        fontSize: '16px',
        lineHeight: '26px',
        letterSpacing: '-0.24px',
      },
    },
    text: {
      '@media (max-width:600px)': {
        fontSize: '16px',
        lineHeight: '26px',
        letterSpacing: '-0.24px',
      },
    },
    buttonContainer: {
      marginTop: '32px',
      '& button': {
        margin: '0 10px',
      },
      '@media (max-width:600px)': {
        marginTop: '20px',
        '& button': {
          width: '120px',
          margin: '0 6px',
        },
      },
    },
    iconContainer: {
      '& svg': {
        width: '100px',
        height: '100px',
      },
    },
  })
);
