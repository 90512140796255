import React, { FunctionComponent, memo } from 'react';

interface Props {
  isActive?: boolean;
}

export const Enroll: FunctionComponent<Props> = memo(({ isActive = false }) => {
  const color = isActive ? '#268AFF' : '#B9C1CC';
  return (
    <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M11.3333 3.59131H18.6667C19.403 3.59131 20 4.16694 20 4.87702V20.3056C20 21.0157 19.403 21.5913 18.6667 21.5913H5.33333C4.59695 21.5913 4 21.0157 4 20.3056V13.2342M16.6667 8.73417H11.3333M16.6667 12.5913H11.3333M16.6667 16.4485H7.33333"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        d="M3 7.59131L5 9.59131L9 5.59131"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
});
