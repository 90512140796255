import React, { FunctionComponent, useState, useEffect } from 'react';
import { Map, Marker, TileLayer } from 'react-leaflet';
import { OpenStreetMapProvider } from 'leaflet-geosearch';
// types
import { IAddressMap } from './types';
import { LatLngTuple } from 'leaflet';

const defaultCoordinates: LatLngTuple = [25.766455, -80.186683];

export const AddressMap: FunctionComponent<IAddressMap> = ({ address = '', coords }) => {
  const [coordinates, setCoordinates] = useState(coords);

  useEffect(() => {
    const provider = new OpenStreetMapProvider();
    provider.search({ query: address }).then((response) => {
      const data = response[0];
      if (data) {
        setCoordinates([Number(data.y), Number(data.x)]);
      }
    });
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <Map center={coordinates || defaultCoordinates} zoom={coordinates ? 13 : 3}>
        <TileLayer
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
        />
        {coordinates && <Marker position={coordinates} />}
      </Map>
    </>
  );
};
