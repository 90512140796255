import { TFilterOptions } from 'components/Autocomplete/types';
import { IParentProfile } from 'types';
import { IChildren } from './types';

export const parseString = (value: string) =>
  value
    .toLowerCase()
    .trim()
    .replace(/[^0-9]/g, '');

export const filterOptions: TFilterOptions = (options, state) => {
  const inputValue = parseString(state.inputValue);
  return options.filter((item) => {
    const option = parseString(item.label);
    return item.value === +inputValue || option.includes(inputValue);
  });
};

export const childDefaultValues = {
  first_name: '',
  last_name: '',
  birthday: '',
  allergies: '',
  gender: '',
  grade_level: '',
  school: '',
};

export const parentDefaultValues = {
  first_name: '',
  last_name: '',
  email: '',
  phone: '',
};

export const defaultValues = {
  parentPhone: '',
  parent: parentDefaultValues,
  children: childDefaultValues,
};

export const createParentValues = (parent: IParentProfile) => ({
  first_name: parent.first_name,
  last_name: parent.last_name,
  email: parent.email || '',
  phone: parent.phone,
  zip: parent.zip || '',
  city: parent.city || '',
  address: parent.address,
});

export const successModalProps = {
  title: 'Success',
  subTitle: 'Student has been added',
};

export const createChildrenFormData = (data: IChildren) => {
  const formData = data;
  if (!formData.birthday) delete formData.birthday;
  if (!formData.gender) delete formData.gender;
  if (!formData.grade_level) delete formData.grade_level;
  return formData;
};
