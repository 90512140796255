import api from 'utils/api';
import { ICreateSessionChildrenData, StripeProducts, TSession } from './types';

export const openSessionUrl = (session: TSession) => window.open(session.url, '_self');

export const setNotPayedData = (data: Record<string, any>) => {
  window.localStorage.notPaidData = JSON.stringify(data);
};

export const getNotPayedData = (): Record<string, any> =>
  JSON.parse(window.localStorage.notPaidData || '""');

export const clearNotPayedData = () => {
  window.localStorage.notPaidData = '';
};

const generateRedirectUrl = (key: string, value: string) => {
  const url = new URL(window.location.href);
  url.searchParams.set(key, value);
  return url.href;
};

export const createSession = (product: {
  type: StripeProducts;
  data:
    | {
        courseId: number;
        startDate: string;
        endDate: string;
        rateId: number;
        promotionId: number;
        durationVariant: number;
        totalSum: number;
      }
    | {
        classId: number;
        children: ICreateSessionChildrenData[];
      }[];
}): Promise<TSession> =>
  api.stripe.createSession({
    data: {
      product,
      successUrl: generateRedirectUrl('isPaymentSuccess', 'true'),
      cancelUrl: generateRedirectUrl('isPaymentSuccess', 'false'),
    },
  });
