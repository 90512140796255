import React, { FunctionComponent, memo } from 'react';

interface ILike {
  onClick?: () => void;
  isActive?: boolean;
}

export const Like: FunctionComponent<ILike> = memo(({ isActive = false, ...rest }) => {
  const color = isActive ? '#268AFF' : '#B9C1CC';
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5 22C3.34315 22 2 20.6569 2 19V13C2 11.3431 3.34315 10 5 10H6.381L9.55279 3.65836C10.0311 2.70179 10.9794 2.07683 12.0366 2.00661L12.2361 2H12.5C13.8255 2 14.91 3.03154 14.9947 4.33562L15 4.5V10H18.9983C19.1958 10 19.3929 10.0195 19.5866 10.0583C21.1533 10.3716 22.1889 11.8524 21.9696 13.4145L21.94 13.5883L20.74 19.5883C20.4712 20.9322 19.3293 21.9152 17.9759 21.9948L17.7983 22H5ZM6 12H5C4.44772 12 4 12.4477 4 13V19C4 19.5523 4.44772 20 5 20H6V12ZM12.5 4H12.2361C11.8994 4 11.5884 4.16909 11.4045 4.4446L11.3416 4.55279L8.10557 11.0249C8.0535 11.1291 8.02015 11.2412 8.00673 11.3563L8 11.4721V20H17.7983C18.2352 20 18.6165 19.7173 18.749 19.3101L18.7788 19.1961L19.9788 13.1961C20.0872 12.6546 19.7359 12.1277 19.1944 12.0194L19.0968 12.0049L18.9983 12H15C13.9456 12 13.0818 11.1841 13.0055 10.1493L13 10V4.5C13 4.25454 12.8231 4.05039 12.5899 4.00806L12.5 4Z"
        fill={color}
      />
    </svg>
  );
});
