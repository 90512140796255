import React, { FunctionComponent, memo } from 'react';
// types
import { IKivityBadge } from './types';
// styles
import useStyles from './styles';

export const KivityBadge: FunctionComponent<IKivityBadge> = memo(({ isKivity }) => {
  const classes = useStyles({ isKivity });

  return <span className={classes.badge}>{isKivity ? 'Kivity' : 'Not Kivity'}</span>;
});
