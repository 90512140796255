import React, { FunctionComponent, useCallback } from 'react';
// material-ui components
import { Grid, Typography } from '@material-ui/core';
// components
import { Button } from 'components';
// icons
import { ReactComponent as SandClock } from 'assets/icons/origin/SandClock.svg';
// custom hooks
import { useModalSetter } from 'hooks/useModal';
// types
import { Props } from './types';
// styles
import useStyles from './styles';

export const PromoteDatesCheck: FunctionComponent<Props> = ({
  classEndDate,
  promoteEndDate,
  handleSubmit,
}) => {
  const { setModal } = useModalSetter();
  const classes = useStyles();

  const handleCloseModal = useCallback(() => {
    setModal(null);
  }, [setModal]);

  return (
    <Grid container direction="column" alignItems="center" className={classes.wrapper}>
      <Grid item className={classes.iconContainer}>
        <SandClock />
      </Grid>
      <Grid container justify="center">
        <Typography variant="h5" className={classes.heading}>
          Seems like selected time frames for Your Advertising are outside the Class period.
        </Typography>
      </Grid>
      <Grid container justify="center">
        <Typography variant="subtitle2" className={classes.text}>
          Class End Dates: {classEndDate}
        </Typography>
      </Grid>
      <Grid container justify="center">
        <Typography variant="subtitle2" className={classes.text}>
          Advertising End Date: <b>{promoteEndDate}</b>
        </Typography>
      </Grid>
      <Grid container justify="center">
        <Typography variant="subtitle1" className={classes.heading}>
          Do you want to proceed with this purchase?
        </Typography>
      </Grid>
      <Grid item className={classes.buttonContainer}>
        <Button variant="text" label="Cancel" width="120px" onClick={handleCloseModal} />
        <Button label="Proceed" width="150px" onClick={handleSubmit} />
      </Grid>
    </Grid>
  );
};
