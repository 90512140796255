import { makeStyles, createStyles } from '@material-ui/core/styles';
import { StylesProps } from './types';

export default makeStyles((theme) =>
  createStyles({
    wrapper: ({ withShadow }: StylesProps) => ({
      position: 'sticky',
      top: 0,
      height: '82px',
      padding: '0 26px',
      backgroundColor: theme.colors.grey6,
      filter: withShadow
        ? 'drop-shadow(0px 1px 2px rgba(67, 70, 74, 0.0001)) drop-shadow(0px 2px 5px rgba(67, 86, 100, 0.123689))'
        : 'unset',
      zIndex: 1300,
    }),
    categories: {
      height: '100%',
      paddingRight: '10px',
      overflowY: 'visible',
      whiteSpace: 'nowrap',

      /* webkit browsers scrollbar */
      '&::-webkit-scrollbar': {
        height: '6px',
        backgroundColor: '#fff',
      },
      '&::-webkit-scrollbar-thumb': {
        backgroundColor: '#8F8F8F',
        borderRadius: '4px',
      },

      /* firefox scrollbar */
      scrollbarWidth: 'thin',
      scrollbarColor: '#8F8F8F #fff',

      [theme.breakpoints.down('md')]: {
        overflowY: 'auto',
      },
    },
    link: {
      paddingRight: '10px',
      textDecoration: 'unset',
      transition: 'all 0.1s ease-in-out',
      '&:hover': {
        fontWeight: 600,
      },
    },
    skeleton: {
      marginRight: '10px',
    },
    categoriesItem: {
      position: 'relative',
      display: 'flex',
      height: '100%',
      alignItems: 'center',
      '&:hover $subcategories': {
        display: 'flex',
      },
      [theme.breakpoints.down('md')]: {
        '&:hover $subcategories': {
          display: 'none',
        },
      },
    },
    subcategories: {
      display: 'none',
      width: 265,
      maxHeight: 365,
      overflowY: 'scroll',
      position: 'absolute',
      top: 65,
      left: 0,
      filter: 'drop-shadow(0px 24px 64px rgba(67, 86, 100, 0.24))',
      borderRadius: 6,
      background: theme.colors.grey6,
    },
    subcategoriesItem: {
      color: theme.colors.black,
      width: '100%',
      padding: '12px 16px 12px 24px',
      '&:hover': {
        background: theme.colors.grey5,
      },
    },
    ellipsis: {
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
    },
  })
);
