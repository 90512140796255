import React, { FunctionComponent, useMemo } from 'react';
// material-ui components
import {
  FormControl,
  InputLabel,
  Select as MUISelect,
  MenuItem,
  FormHelperText,
} from '@material-ui/core';
// icons
import { Asterics, ChevronDown } from 'assets/icons';
// helpers
import { makeGenerateColor } from 'utils/helpers';
// types
import { SelectProps } from './types';
// styles
import useStyles from './styles';

export const Select: FunctionComponent<SelectProps> = ({
  options,
  value: inputValue,
  errorField,
  isSubmitted,
  fullWidth = false,
  width,
  placeholder,
  disabled,
  required,
  eventsIsDisabled,
  isReadOnly,
  children,
  ...inputProps
}) => {
  const generateColor = useMemo(
    () => makeGenerateColor({ isSubmitted, disabled, error: !!errorField }),
    [isSubmitted, disabled, errorField]
  );

  const borderColor = generateColor({ disabledColor: 'grey6', defaultColor: 'grey6', isReadOnly });
  const borderHoverColor = generateColor({
    disabledColor: 'grey6',
    defaultColor: 'cyan',
    isReadOnly,
  });
  const itemColor = generateColor({ disabledColor: 'grey6', defaultColor: 'cyan' });
  const placeholderColor = generateColor({ disabledColor: 'grey4', defaultColor: 'grey3' });

  const classes = useStyles({
    nonEmpty: Boolean(inputValue),
    eventsIsDisabled,
    fullWidth,
    width,
    disabled,
    placeholderColor,
    borderHoverColor,
    borderColor,
    itemColor,
    isReadOnly,
  });

  return (
    <FormControl
      fullWidth
      error={isSubmitted && !!errorField}
      classes={{ root: classes.formControlRoot }}
    >
      {!inputProps.selected && (
        <InputLabel
          variant="outlined"
          disableAnimation
          htmlFor={inputProps.name}
          classes={{ root: classes.inputLabelRoot }}
        >
          <span className={classes.placeholder}>
            {placeholder} {required && <Asterics />}
          </span>
        </InputLabel>
      )}
      <MUISelect
        aria-describedby="component-helper-text"
        displayEmpty
        value={inputValue}
        variant="outlined"
        disabled={disabled || isReadOnly}
        id={inputProps.name}
        IconComponent={ChevronDown}
        classes={{
          root: classes.selectRoot,
          icon: classes.icon,
          disabled: classes.disabled,
        }}
        MenuProps={{
          anchorOrigin: { vertical: 'bottom', horizontal: 'left' },
          transformOrigin: { vertical: 'top', horizontal: 'left' },
          getContentAnchorEl: null,
          classes: {
            list: classes.menuList,
            paper: classes.menuPaper,
          },
        }}
        {...inputProps}
      >
        {children ||
          options?.map(({ label, value }) => (
            <MenuItem
              value={value}
              key={`${label}-${value}`}
              classes={{ root: classes.menuItemRoot, selected: classes.menuItemSelected }}
            >
              {label}
            </MenuItem>
          ))}
      </MUISelect>
      <FormHelperText
        id="component-helper-text"
        error={isSubmitted && !!errorField?.message}
        classes={{ root: classes.errorText }}
      >
        {errorField?.message}
      </FormHelperText>
    </FormControl>
  );
};
