import React, { ChangeEvent, FunctionComponent } from 'react';
// form
import { Controller, useFormContext } from 'react-hook-form';
// types
import { RadioGroup as RadioGroupMUI, RadioGroupProps } from '@material-ui/core';
// components
import { RadioGroupCategories } from 'components/RadioGroupCategories';

interface Props extends RadioGroupProps {
  label?: string;
  height?: string;
  handleClick?: (e: ChangeEvent<{}>, id: number | string) => void;
  helperChange?: () => void;
  [x: string]: any;
}

export const CategoriesRadioController: FunctionComponent<Props> = ({
  name = '',
  defaultValue = '',
  height,
  values,
  handleClick,
  helperChange,
}) => {
  const { control } = useFormContext();

  const onHandleClick = (e: ChangeEvent<{}>, prevId: number, id: number | string) => {
    handleClick && handleClick(e, id);
    if (prevId && Number(prevId) !== Number(id)) {
      helperChange && helperChange();
    }
  };

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      render={(props) => (
        <RadioGroupMUI
          value={props.value || ''}
          style={{ flexDirection: 'row' }}
          onChange={(e) => props.onChange(+e.target.value)}
        >
          {values?.map((item: { value: number | string; label: string }) => (
            <RadioGroupCategories
              key={item.value.toString()}
              item={item}
              checked={props.value === item.value}
              height={height}
              handleClick={(e) => onHandleClick(e, props.value, item.value)}
            />
          ))}
        </RadioGroupMUI>
      )}
    />
  );
};
