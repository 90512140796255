import { makeStyles, Theme } from '@material-ui/core/styles';
// interfaces
import { StylesProps } from './types';

export default makeStyles((theme: Theme) => ({
  root: ({ isPassword, multiline }: StylesProps) => ({
    height: multiline ? 'auto' : '60px',
    '& svg, & .MuiInputAdornment-positionStart, & .MuiInputAdornment-positionEnd': {
      display: 'none',
    },
    '& .MuiInputAdornment-positionStart': {
      height: '100%',
      marginRight: 'unset',
      paddingLeft: '16px',
    },
    '& .MuiInputAdornment-positionEnd': {
      height: '100%',
      marginLeft: 'unset',
      paddingRight: '16px',
      '& svg': {
        cursor: isPassword ? 'pointer' : 'auto',
      },
    },
  }),
  input: ({ centeredPlaceholder, textColor, withStartIcon }: StylesProps) => ({
    ...theme.fontStyle.p16,
    minHeight: '26px',
    padding: '0 16px',
    color: theme.colors[textColor],
    justifyContent: centeredPlaceholder ? 'center' : 'flex-start',
    '&:focus, &:not(:placeholder-shown)': {
      padding: withStartIcon ? '0 12px' : '0 16px',
      justifyContent: 'flex-start',
    },
  }),
  underline: ({ underlineColor, isWhite }: StylesProps) => ({
    '&:hover:not(.Mui-disabled):before': {
      borderBottomWidth: '1px',
    },
    '&:after': {
      borderBottom: `1px solid ${theme.colors[underlineColor]}`,
    },
    '&:before': {
      borderBottom: isWhite ? `1px solid ${theme.colors.white}` : '1px solid rgba(0, 0, 0, 0.42)',
    },
  }),
  fieldProps: () => ({
    '& svg, & .MuiInputAdornment-positionStart, & .MuiInputAdornment-positionEnd': {
      display: 'flex',
    },
  }),
  focused: () => ({
    '& svg, & .MuiInputAdornment-positionStart, & .MuiInputAdornment-positionEnd': {
      display: 'flex',
    },
  }),
  error: () => ({
    '&:before': {
      borderBottom: `1px solid ${theme.colors.red}`,
    },
    '&:after': {
      borderBottom: `1px solid ${theme.colors.red}`,
    },
  }),
  errorText: () => ({
    ...theme.fontStyle.error,
    textTransform: 'inherit',
  }),
  disabled: () => ({
    color: `${theme.colors.grey4}`,
  }),
  inputFormControl: {
    marginTop: 'unset !important',
  },
  formControlRoot: ({ width = '360px', fullWidth }: StylesProps) => ({
    width: fullWidth ? '100%' : width,
  }),
  inputLabelRoot: ({ centeredPlaceholder, placeholderColor, multiline }: StylesProps) => ({
    ...theme.fontStyle.p16,
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    height: multiline ? 'auto' : '60px',
    padding: '0 16px',
    color: theme.colors[placeholderColor],
    transform: multiline ? 'translateY(7px)' : 'translateY(0)',
    justifyContent: centeredPlaceholder ? 'center' : 'flex-start',
    '&.Mui-focused': {
      color: theme.colors[placeholderColor],
    },
  }),
  inputLabelShrink: ({ nonEmpty, centeredPlaceholder, withStartIcon, multiline }: StylesProps) => ({
    display: nonEmpty ? 'none' : 'flex',
    justifyContent: !nonEmpty && centeredPlaceholder ? 'center' : 'flex-start',
    transform: multiline ? 'translateY(7px)' : 'translateY(0)',
    '&.Mui-focused': {
      width: 'calc(100% - 34px)',
      justifyContent: 'flex-start',
      transform: multiline
        ? 'translateY(7px)'
        : withStartIcon
        ? 'translateX(34px) translateY(0)'
        : 'translateY(0)',
    },
  }),
  placeholder: {
    lineHeight: '25px',
    display: 'block',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },
}));
