import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

export default makeStyles((theme: Theme) =>
  createStyles({
    wrap: {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      color: theme.colors.cyan,
      '& a': {
        color: theme.colors.cyan,
        textDecoration: 'unset',
      },
    },
  })
);
