import { makeStyles, createStyles } from '@material-ui/core/styles';

export default makeStyles(() =>
  createStyles({
    wrapper: {
      maxWidth: '1052px',
      flexDirection: 'column',
    },
    container: {
      padding: '0 36px 24px',
    },
    heading: {
      marginBottom: '15px',
    },
    selectContainer: {
      margin: '10px 0 30px',
    },
    spinnerContainer: {
      height: '615px',
    },
  })
);
