import React, { FunctionComponent, memo } from 'react';
import { Link } from 'react-router-dom';
// components
import { Avatar, Button } from 'components';
import CreateClassButton from './components/CreateClassButton';
// constants
import { Roles } from 'utils/constants';
import { businessLandingLinks, links } from 'layouts/Header/constants';
// types
import { IHeaderButtonsView } from './types';
import { OutlinedButtonType } from 'components/Button/types';
// styles
import useStyles from './styles';
import CopyBusinessLinkButton from './components/CopyBusinessLinkButton';
// assets
import { ReactComponent as SearchByBusinessIcon } from 'assets/icons/origin/SearchByBusiness.svg';

const HeaderButtonsView: FunctionComponent<IHeaderButtonsView> = ({
  role,
  avatar,
  // searchValue,
  // handleSearch,
  // handleKeyDown,
  businessIsApproved,
  headerIsTransparent,
  isBusinessLandingPage,
  openAdminApproveModal,
}) => {
  const classes = useStyles({ headerIsTransparent });

  if (isBusinessLandingPage) {
    return (
      <div className={classes.buttonsContainer}>
        <Button
          width="auto"
          size="small"
          component={Link}
          variant="outlined"
          to="/business-registration"
          label="Claim Your Free Business Account"
          outlinedButtonType={OutlinedButtonType.orange}
        />
      </div>
    );
  }

  if (role === Roles.unauthorized) {
    return (
      <div className={classes.buttonsContainer}>
        <Button
          type="button"
          variant="outlined"
          outlinedButtonType={OutlinedButtonType.white}
          withStrokeIcon
          component={Link}
          to="/search?limit=10&page=1&search=&tab=1"
          width="auto"
          size="small"
          label="Search by Businesses"
          startIcon={<SearchByBusinessIcon />}
        />
        <Button
          width="auto"
          size="small"
          component={Link}
          variant="outlined"
          to="/business"
          label="FOR BUSINESSES"
          outlinedButtonType={OutlinedButtonType.orange}
        />
        <Button component={Link} to="/login" size="small" width="96px" label="LOGIN" />
      </div>
    );
  }

  return (
    <div className={classes.rightContentContainer}>
      <div className={classes.buttonsContainer}>
        {role !== Roles.business && (
          <Button
            type="button"
            variant="outlined"
            outlinedButtonType={OutlinedButtonType.white}
            withStrokeIcon
            component={Link}
            to="/search?limit=10&page=1&search=&tab=1"
            width="auto"
            size="small"
            label="Search by Businesses"
            startIcon={<SearchByBusinessIcon />}
          />
        )}

        {role === Roles.business && (
          <>
            <CopyBusinessLinkButton />
            <CreateClassButton
              businessIsApproved={businessIsApproved}
              openAdminApproveModal={openAdminApproveModal}
            />
          </>
        )}
      </div>

      {/* <div className={classes.searchContainer}>
        <Search
          placeholder="Search ..."
          width="248px"
          isSmall
          value={searchValue}
          handleChange={handleSearch}
          handleKeyDown={handleKeyDown}
        />
      </div> */}
      <Avatar
        photo={avatar || ''}
        links={isBusinessLandingPage ? businessLandingLinks.menu : links[role].menu}
        isBusiness={role === Roles.business}
        isBusinessLandingPage={isBusinessLandingPage}
      />
    </div>
  );
};

export default memo(HeaderButtonsView);
