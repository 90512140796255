import React, { FunctionComponent } from 'react';
// material-ui components
import { Grid } from '@material-ui/core';
// types
import { ITabPanel } from './types';

export const TabPanel: FunctionComponent<ITabPanel> = ({
  children,
  value,
  index,
  type = 'user-type',
  ...other
}) => (
  <Grid
    container
    justify="center"
    role="tabpanel"
    hidden={value !== index}
    id={`simple-tabpanel-${index}`}
    aria-labelledby={`${type}-${index}`}
    {...other}
  >
    {value === index && <>{children}</>}
  </Grid>
);
