import React, { FunctionComponent, memo } from 'react';

interface Props {
  className?: string;
}

export const Asterics: FunctionComponent<Props> = memo((props) => (
  <svg
    width="8"
    height="8"
    viewBox="0 0 8 8"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M1.57631 4.5442C1.29709 4.75875 1.2572 5.14954 1.48058 5.41773C1.70395 5.68592 2.11081 5.72423 2.39003 5.50968L3.39521 4.7281L3.19577 5.95411C3.18779 6.01541 3.18779 6.08437 3.19577 6.14567C3.25162 6.48282 3.5787 6.7127 3.93769 6.65906C4.28871 6.60542 4.52804 6.29126 4.4722 5.94645L4.27276 4.72044L5.27794 5.50202C5.32581 5.54033 5.38963 5.57098 5.45345 5.60163C5.78851 5.72423 6.16346 5.56332 6.2911 5.24916C6.41875 4.92733 6.25121 4.56719 5.92413 4.44459L4.71153 4.00016L5.91615 3.55574C5.97997 3.53275 6.03582 3.5021 6.09166 3.45612C6.37088 3.24157 6.41077 2.85078 6.18739 2.58259C5.96402 2.31441 5.55716 2.27609 5.27794 2.49064L4.27276 3.27222L4.4722 2.04622C4.48017 1.98492 4.48017 1.91595 4.4722 1.85465C4.41635 1.5175 4.08927 1.28763 3.73027 1.34126C3.37128 1.3949 3.13993 1.70906 3.19577 2.05388L3.39521 3.27988L2.39003 2.49064C2.34216 2.45233 2.27834 2.42168 2.21452 2.39103C1.87946 2.26843 1.50451 2.42934 1.37687 2.74351C1.24922 3.06533 1.41675 3.42547 1.74384 3.54807L2.95644 4.00016L1.75182 4.44459C1.69597 4.46758 1.63215 4.49823 1.57631 4.5442Z"
      fill="#FF3A29"
    />
  </svg>
));
