import { createStyles, makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) =>
  createStyles({
    isActive: {
      backgroundColor: theme.colors.orange,
      color: theme.colors.white,
      '& path': {
        stroke: theme.colors.white,
      },
    },
    tooltip: {
      ...theme.fontStyle.p14,
      maxWidth: '268px',
      padding: '16px 11px',
      marginBottom: '14px',
      fontWeight: 500,
      borderRadius: '5px',
      color: theme.colors.white,
      backgroundColor: theme.colors.cyan,
    },
    tooltipArrow: {
      color: theme.colors.cyan,
    },
  })
);
