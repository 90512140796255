import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

export default makeStyles((theme: Theme) =>
  createStyles({
    wrapper: {
      width: '432px',
      paddingBottom: '42px',
      [theme.breakpoints.only('xs')]: {
        width: '100%',
      },
    },
    text: {
      padding: '10px 0',
    },
    buttonContainer: {
      marginTop: '32px',
    },
    email: {
      color: theme.colors.cyan,
    },
  })
);
