import React, { FunctionComponent, memo } from 'react';
import MaskedInput from 'react-text-mask';
// types
import { IPhoneMask } from './types';

export const Phone: FunctionComponent<IPhoneMask> = memo(({ inputRef, ...other }) => (
  <MaskedInput
    {...other}
    ref={(ref: any) => {
      inputRef(ref ? ref.inputElement : null);
    }}
    mask={[
      '+',
      '1',
      ' ',
      '(',
      /[2-9]/,
      /\d/,
      /\d/,
      ')',
      ' ',
      /[2-9]/,
      /\d/,
      /\d/,
      '-',
      /\d/,
      /\d/,
      /\d/,
      /\d/,
    ]}
  />
));
