import React, {
  FunctionComponent,
  ReactNode,
  createContext,
  useState,
  useCallback,
  useContext,
} from 'react';
// components
import ModalLayout from 'layouts/ModalLayout';

interface IContext {
  modals: Array<ReactNode | never>;
  setModal: (component?: ReactNode) => void;
  closeAllModals: () => void;
}

const ModalContext = createContext<IContext>({} as IContext);

export const useModalSetter = () => useContext(ModalContext);

export const ModalProvider: FunctionComponent = ({ children }) => {
  const [modals, setState] = useState<Array<ReactNode | never>>([]);

  const setModal = useCallback(
    (component) => {
      if (component) {
        setState([...modals, component]);
      } else {
        const lastIndex = modals.length - 1;
        const newModals = modals.filter((_item, key) => key !== lastIndex);
        setState(newModals);
      }
    },
    [modals]
  );

  const closeModal = () => {
    setModal(null);
  };

  const closeAllModals = () => {
    setState([]);
  };

  return (
    <ModalContext.Provider value={{ modals, setModal, closeAllModals }}>
      {children}
      {modals.map((modal, key) => (
        <ModalLayout
          key={key.toString()}
          slideDirection="down"
          isModalOpen={!!modal}
          handleModalClick={closeModal}
        >
          {modal}
        </ModalLayout>
      ))}
    </ModalContext.Provider>
  );
};
