import React, { FunctionComponent, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
// view component
import HeaderView from './view/index';
import BannerForUkraine from './BannerForUkraine';
// hooks
import { useStore } from 'hooks/useStore';
// constants
import { authPages, authUpPages, businessLandingPages, Roles } from 'utils/constants';
import { businessLandingLinks, links, signUpLinks } from './constants';

const Header: FunctionComponent = () => {
  const { user } = useStore();
  const location = useLocation();
  const headerIsTransparent = authPages.includes(location.pathname);
  const withShadow = useMemo(
    () =>
      location.pathname.includes('/search') ||
      location.pathname.includes('/dashboard') ||
      (user.role === Roles.business && location.pathname.includes('/profile')) ||
      (user.role === Roles.business && location.pathname.includes('/schedule')) ||
      (user.role === Roles.admin &&
        (location.pathname.includes('/business-profile/edit') ||
          location.pathname.includes('/business-profile/create'))),
    [user.role, location.pathname]
  );

  const isAuthUpPage = useMemo(() => authUpPages.includes(location.pathname), [location.pathname]);
  const isBusinessLandingPage = useMemo(() => businessLandingPages.includes(location.pathname), [
    location.pathname,
  ]);

  const headerLinks = useMemo(() => {
    if (isAuthUpPage) {
      return signUpLinks;
    }

    if (isBusinessLandingPage) {
      return businessLandingLinks.header;
    }

    return links[user.role].header;
  }, [user.role, isAuthUpPage, isBusinessLandingPage]);

  return (
    <>
      <BannerForUkraine isTransparent={headerIsTransparent} />
      <HeaderView
        user={user}
        withShadow={withShadow}
        headerLinks={headerLinks}
        isAuthUpPage={isAuthUpPage}
        isBusinessLandingPage={isBusinessLandingPage}
        headerIsTransparent={headerIsTransparent}
      />
    </>
  );
};

export default Header;
