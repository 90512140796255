import React, { FunctionComponent } from 'react';
// form
import { Controller, useFormContext } from 'react-hook-form';
// types
import { CheckboxProps } from '@material-ui/core';
// components
import { CheckboxButton } from 'components/CheckboxButton';

interface Props extends CheckboxProps {
  label?: string;
  height?: string;
  colorKey?: string;
  [x: string]: any;
}

export const CheckBoxButtonController: FunctionComponent<Props> = ({
  name = '',
  defaultChecked = false,
  label,
  height,
  colorKey,
  onCheck,
}) => {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultChecked}
      render={(props) => (
        <CheckboxButton
          onChange={(e) => {
            props.onChange(e.target.checked);
            onCheck && onCheck(e.target.checked);
          }}
          checked={props.value || false}
          label={label}
          height={height}
          colorKey={colorKey}
        />
      )}
    />
  );
};
