import React, { FunctionComponent } from 'react';
import { Link } from 'react-router-dom';
// material-ui components
import { Grid, Typography } from '@material-ui/core';
// components
import { Button } from 'components';
// custom hooks
import { useModalSetter } from 'hooks/useModal';
// styles
import useStyles from './styles';

export const SignUpUnauthorized: FunctionComponent = () => {
  const classes = useStyles();
  const { setModal } = useModalSetter();

  const handleCloseModal = () => {
    setModal(null);
  };

  return (
    <Grid container direction="column" className={classes.wrapper}>
      <Grid container>
        <Typography variant="h5" paragraph className={classes.title}>
          Want to be the first to know when new and fun kid’s activities start? Join our list and be
          the first to know and a whole lot more.
        </Typography>
      </Grid>
      <Grid container justify="center" className={classes.buttonsContainer}>
        <Button
          variant="text"
          label="Cancel"
          width="112px"
          onClick={handleCloseModal}
          className={classes.button}
        />
        <Button
          label="Sign Up"
          width="164px"
          component={Link}
          to="/sign-up"
          onClick={handleCloseModal}
        />
      </Grid>
    </Grid>
  );
};
