import { makeStyles, createStyles } from '@material-ui/core/styles';

export default makeStyles((theme) =>
  createStyles({
    dot: {
      width: '8px',
      height: '8px',
      margin: '0 4px',
      border: 'unset',
      cursor: 'pointer',
      borderRadius: '50%',
      backgroundColor: theme.colors.grey5,
    },
    dotActive: {
      backgroundColor: theme.colors.cyan,
    },
  })
);
