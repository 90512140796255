import { Theme } from '@material-ui/core';
import { ObjectProps, StatusProps } from './types';

export const statusProps = (theme: Theme, status?: StatusProps): ObjectProps => {
  if (!status) return {};

  const colors = {
    [StatusProps.active]: {
      textColor: theme.colors.orange,
      backgroundColor: theme.colors.orangePastel,
    },
    [StatusProps.inactive]: {
      textColor: theme.colors.cyan,
      backgroundColor: theme.colors.cyanLight,
    },
    [StatusProps.draft]: {
      textColor: theme.colors.red,
      backgroundColor: theme.colors.redPastel,
    },
    [StatusProps.expired]: {
      textColor: theme.colors.cyan,
      backgroundColor: theme.colors.cyanLight,
    },
    [StatusProps.opened]: {
      textColor: theme.colors.velvet,
      backgroundColor: theme.colors.pastelVelvet,
    },
    [StatusProps.deactivated]: {
      textColor: theme.colors.flaming,
      backgroundColor: theme.colors.pastelFlaming,
    },
    [StatusProps.published]: {
      textColor: theme.colors.blue3,
      backgroundColor: theme.colors.pastelBlue,
    },
  };

  return {
    margin: 'unset',
    textTransform: 'capitalize',
    color: colors[status]?.textColor,
    borderColor: colors[status]?.backgroundColor,
    backgroundColor: colors[status]?.backgroundColor,
  };
};
