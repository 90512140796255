import React, { FunctionComponent, memo } from 'react';
// colors
import colors from 'styles/colors';

interface ICheck {
  color?: 'white' | 'green';
}

export const Check: FunctionComponent<ICheck> = memo(({ color }) => (
  <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17.0628 5.69304C17.4625 6.00378 17.5346 6.57969 17.2239 6.97936L10.0968 16.146C9.93809 16.3501 9.70054 16.4778 9.44269 16.4974C9.18485 16.5171 8.93071 16.4269 8.74294 16.249L4.86996 12.5824C4.50232 12.2343 4.48644 11.6541 4.8345 11.2865C5.18255 10.9189 5.76273 10.903 6.13037 11.251L9.26964 14.2231L15.7765 5.85406C16.0872 5.45439 16.6631 5.38229 17.0628 5.69304Z"
      fill={color ? colors[color] : colors.grey4}
    />
  </svg>
));
