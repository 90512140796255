import React, { FunctionComponent, MouseEvent, useState } from 'react';
import { EmailShareButton, FacebookShareButton, EmailIcon, FacebookIcon } from 'react-share';
// material-ui components
import { Grid, Typography, Menu, MenuItem } from '@material-ui/core';
// icons
import { ReactComponent as ShareIcon } from 'assets/icons/origin/Share.svg';
import { ReactComponent as MessageIcon } from 'assets/icons/origin/Message.svg';
// types
import { Props } from './types';
// styles
import useStyles from './styles';

export const API_URL = process.env.REACT_APP_PUBLIC_URL;

export const ShareButton: FunctionComponent<Props> = ({ id, title, url }) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [isCopied, setIsCopied] = useState<boolean>(false);

  const handleClick = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const shareLink = url ?? `${API_URL}/classes/view/${id}`;

  const handleCopyLink = () => {
    navigator.clipboard.writeText(shareLink);
    setIsCopied(true);
  };

  return (
    <Grid item>
      <Grid item className={classes.shareButton} onClick={handleClick}>
        <ShareIcon />
        <Typography variant="body2" color="primary" className={classes.shareItemLabel}>
          Share
        </Typography>
      </Grid>
      {!!anchorEl && (
        <Menu
          keepMounted
          elevation={5}
          variant="menu"
          anchorEl={anchorEl}
          onClose={handleClose}
          open={!!anchorEl}
          getContentAnchorEl={null}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          classes={{ list: classes.menuList, paper: classes.menuPaper }}
        >
          <MenuItem classes={{ root: classes.menuItemRoot }} onClick={handleClose}>
            <EmailShareButton
              url={`Check this Class on Kivity: ${shareLink}`}
              subject={title}
              className={classes.icons}
            >
              <EmailIcon size={32} round />
              <Typography variant="subtitle2" className={classes.menuItemText}>
                Email
              </Typography>
            </EmailShareButton>
          </MenuItem>
          <MenuItem classes={{ root: classes.menuItemRoot }} onClick={handleClose}>
            <FacebookShareButton url={shareLink} hashtag="#Kivity" className={classes.icons}>
              <FacebookIcon size={32} round />
              <Typography variant="subtitle2" className={classes.menuItemText}>
                Facebook
              </Typography>
            </FacebookShareButton>
          </MenuItem>
          <MenuItem classes={{ root: classes.menuItemRoot }} onClick={handleClose}>
            <a href={`sms:?&body=Check this Class on Kivity: ${shareLink}`} className={classes.sms}>
              <MessageIcon />
              <Typography variant="subtitle2" className={classes.menuItemText}>
                Messages
              </Typography>
            </a>
          </MenuItem>
          <MenuItem classes={{ root: classes.menuItemRoot }} onClick={handleClose}>
            <button type="button" onClick={handleCopyLink} className={classes.copyButton}>
              <Typography variant="subtitle2" className={classes.menuItemText}>
                {isCopied ? 'Copied' : 'Copy link'}
              </Typography>
            </button>
          </MenuItem>
        </Menu>
      )}
    </Grid>
  );
};
