import React, { FunctionComponent, memo } from 'react';
// @material-ui
import { Grid } from '@material-ui/core';
// icons
import { Delete } from 'assets/icons';
// styles
import { useStylesImage } from './styles';

interface Props {
  url?: string;
  onDelete: () => void;
}

export const PreviewImage: FunctionComponent<Props> = memo(({ url, onDelete }) => {
  const classes = useStylesImage();

  return (
    <Grid container direction="column" alignItems="center" className={classes.root}>
      <Delete color="#FF8A00" className={classes.delete} onClick={onDelete} />
      <img src={url} className={classes.img} alt="" />
    </Grid>
  );
});
