import React, { FunctionComponent, useMemo } from 'react';
import cx from 'classnames';
// material-ui components
import { Avatar, Grid } from '@material-ui/core';
// types
import { IUserAvatar } from './types';
import { AvatarGender } from 'types';
// icons
import { Delete } from 'assets/icons';
import { ReactComponent as BusinessAvatar } from 'assets/icons/origin/avatar/Bisiness.svg';
import { ReactComponent as ParentAvatar } from 'assets/icons/origin/avatar/Parent.svg';
import { ReactComponent as GirlAvatar } from 'assets/icons/origin/avatar/Girl.svg';
import { ReactComponent as BoyAvatar } from 'assets/icons/origin/avatar/Boy.svg';
// styles
import useStyles from './styles';
import colors from 'styles/colors';

export const UserAvatar: FunctionComponent<IUserAvatar> = ({
  alt,
  src,
  className,
  size = '30px',
  gender,
  onDelete,
  isReadOnly,
}) => {
  const classes = useStyles({ size, isReadOnly });

  const Placeholder = useMemo(() => {
    switch (gender) {
      case AvatarGender.male:
        return <BoyAvatar />;
      case AvatarGender.female:
        return <GirlAvatar />;
      case AvatarGender.business:
        return <BusinessAvatar />;
      default:
        return <ParentAvatar />;
    }
  }, [gender]);

  return (
    <Grid className={classes.container}>
      <Avatar alt={alt} src={src} className={cx(classes.avatar, className)}>
        {Placeholder}
      </Avatar>
      {onDelete && <Delete color={colors.red} className={classes.delete} onClick={onDelete} />}
    </Grid>
  );
};
