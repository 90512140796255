import React, { FunctionComponent, memo } from 'react';
import RMCarousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import cn from 'classnames';
// material-ui components
import { Grid } from '@material-ui/core';
// components
import { CarouselButtonGroup, CarouselDot } from './components';
// icons
import { ReactComponent as ImagePlaceholder } from 'assets/icons/origin/ImagePlaceholder.svg';
// types
import { ICarousel } from './types';
// styles
import useStyles, { defaultResponsiveOptions } from './styles';

export const Carousel: FunctionComponent<ICarousel> = memo(
  ({
    children,
    autoPlay,
    autoPlaySpeed = 5000,
    showButtons = true,
    showDots = true,
    itemsLength,
    responsiveOptions = defaultResponsiveOptions,
    className,
  }) => {
    const classes = useStyles();

    return (
      <Grid
        container
        alignItems="flex-start"
        justify="space-between"
        className={cn(classes.container, className)}
      >
        <RMCarousel
          autoPlay={autoPlay}
          autoPlaySpeed={autoPlaySpeed}
          showDots={showDots}
          infinite
          renderDotsOutside
          renderButtonGroupOutside
          arrows={false}
          draggable={false}
          responsive={responsiveOptions}
          containerClass={classes.carouselContainer}
          customButtonGroup={showButtons ? <CarouselButtonGroup /> : null}
          customDot={<CarouselDot />}
        >
          {itemsLength > 0 ? (
            children
          ) : (
            <Grid container className={classes.imagePlaceholder}>
              <ImagePlaceholder />
            </Grid>
          )}
        </RMCarousel>
      </Grid>
    );
  }
);
