import React, { FunctionComponent, memo } from 'react';

interface Props {
  isActive?: boolean;
}

export const Children: FunctionComponent<Props> = memo(({ isActive = false }) => {
  const color = isActive ? '#268AFF' : '#B9C1CC';
  return (
    <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7 14.3462H8C9.63573 14.3462 11.088 15.1317 12.0002 16.346C12.864 15.1956 14.2129 14.4301 15.7433 14.3527L16 14.3462H17C19.7614 14.3462 22 16.5848 22 19.3462C22 19.8985 21.5523 20.3462 21 20.3462C20.4872 20.3462 20.0645 19.9602 20.0067 19.4628L19.9949 19.1699C19.907 17.6534 18.6928 16.4392 17.1763 16.3513L17 16.3462H16C14.3431 16.3462 13 17.6893 13 19.3462C13 19.859 12.614 20.2817 12.1166 20.3395L12 20.3462C11.4872 20.3462 11.0645 19.9602 11.0067 19.4628L10.9949 19.1699C10.907 17.6534 9.69275 16.4392 8.17627 16.3513L8 16.3462H7C5.34315 16.3462 4 17.6893 4 19.3462C4 19.8985 3.55228 20.3462 3 20.3462C2.44772 20.3462 2 19.8985 2 19.3462C2 16.6574 4.12231 14.4644 6.78311 14.3508L7 14.3462ZM7 4.34619C9.20914 4.34619 11 6.13705 11 8.34619C11 10.5553 9.20914 12.3462 7 12.3462C4.79086 12.3462 3 10.5553 3 8.34619C3 6.13705 4.79086 4.34619 7 4.34619ZM17 4.34619C19.2091 4.34619 21 6.13705 21 8.34619C21 10.5553 19.2091 12.3462 17 12.3462C14.7909 12.3462 13 10.5553 13 8.34619C13 6.13705 14.7909 4.34619 17 4.34619ZM7 6.34619C5.89543 6.34619 5 7.24162 5 8.34619C5 9.45076 5.89543 10.3462 7 10.3462C8.10457 10.3462 9 9.45076 9 8.34619C9 7.24162 8.10457 6.34619 7 6.34619ZM17 6.34619C15.8954 6.34619 15 7.24162 15 8.34619C15 9.45076 15.8954 10.3462 17 10.3462C18.1046 10.3462 19 9.45076 19 8.34619C19 7.24162 18.1046 6.34619 17 6.34619Z"
        fill={color}
      />
    </svg>
  );
});
