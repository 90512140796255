import React, { FunctionComponent } from 'react';
// material-ui components
import { Grid, Typography } from '@material-ui/core';
// components
import { Button } from 'components/Button';
// icons
import { ReactComponent as CloudError } from 'assets/icons/origin/CloudError.svg';
// custom hooks
import { useModalSetter } from 'hooks/useModal';
// types
import { Props } from './types';
// styles
import useStyles from './styles';

export const CustomErrorModal: FunctionComponent<Props> = ({
  message = 'Something went wrong...',
  handleOkClick,
}) => {
  const { setModal } = useModalSetter();
  const classes = useStyles();

  const handleCloseModal = () => {
    setModal(null);
  };

  return (
    <Grid container direction="column" alignItems="center" className={classes.wrapper}>
      <Grid item>
        <CloudError />
      </Grid>
      <Grid container justify="center" item>
        <Typography variant="h5" className={classes.heading}>
          Oooops!
        </Typography>
      </Grid>
      <Grid item>
        <Typography variant="body1" className={classes.text}>
          {message}
        </Typography>
      </Grid>
      <Grid item className={classes.buttonContainer}>
        <Button label="Ok" width="220px" onClick={handleOkClick || handleCloseModal} />
      </Grid>
    </Grid>
  );
};
