import React, { FunctionComponent, useMemo } from 'react';
import cx from 'classnames';
// stripe
import { CardNumberElement, CardCvcElement, CardExpiryElement } from '@stripe/react-stripe-js';
// form
import { Controller, useFormContext } from 'react-hook-form';
// material-ui components
import { Grid } from '@material-ui/core';
// types
import { Props } from './types';
// styles
import { useStyles, style } from './styles';

const elements: { [x: string]: any } = {
  cart: CardNumberElement,
  cvc: CardCvcElement,
  date: CardExpiryElement,
  own: (props: any) => <input {...props} />,
};

export const CardElementType: FunctionComponent<Props> = ({
  type,
  name,
  width = '100%',
  ...rest
}) => {
  const classes = useStyles({ width });

  const {
    control,
    errors,
    formState: { isSubmitted },
  } = useFormContext();

  const Element = useMemo(() => elements[type], [type]);

  return (
    <Grid className={cx(classes.cardNumber, { [classes.error]: !!errors[name] && isSubmitted })}>
      <Controller
        name={name}
        control={control}
        defaultValue=""
        render={(props) => (
          <Element {...props} {...rest} className={classes.cardOwner} options={{ style }} />
        )}
      />
    </Grid>
  );
};
