import { createStyles, makeStyles } from '@material-ui/core/styles';
import { StyleProps } from './types';

export default makeStyles(() =>
  createStyles({
    buttonsContainer: ({ headerIsTransparent }: StyleProps) => ({
      display: headerIsTransparent ? 'none' : 'flex',
      '& > *': {
        marginRight: '15px',
      },
      '& > *:last-child': {
        marginRight: 0,
      },
    }),
    rightContentContainer: {
      display: 'flex',
      alignItems: 'center',
    },
    searchContainer: {
      margin: '0 4px 0 24px',
    },
  })
);
