import { makeStyles, Theme } from '@material-ui/core/styles';
// icons
import Check from 'components/Checkbox/CheckBoxCheck.svg';
// types
import { BoxType, StylesProps, StylesLabelProps } from './types';

const styles = {
  [BoxType.Radio]: {
    before: {
      backgroundImage: 'radial-gradient(#fff,#fff 28%,transparent 32%)',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center center',
    },
    borderRadius: '50%',
    top: '25%',
    height: '50%',
  },
  [BoxType.Checkbox]: {
    before: {
      backgroundImage: `url(${Check})`,
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center center',
    },
    borderRadius: '3px',
    top: '50%',
    height: '2px',
  },
};

export const useBoxStyles = makeStyles((theme: Theme) => ({
  root: {
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  icon: ({ sizePx, type }: StylesProps) => ({
    border: `2px solid ${theme.colors.grey4}`,
    boxSizing: 'border-box',
    borderRadius: styles[type].borderRadius,
    width: sizePx,
    height: sizePx,
    backgroundColor: 'transparent',
    'input:hover ~ &': {
      backgroundColor: 'transparent',
      border: `2px solid ${theme.colors.cyan}`,
    },
    'input:disabled ~ &': {
      backgroundColor: theme.colors.grey5,
      border: 'none',
      verticalAlign: 'middle',
      position: 'relative',
      '&:before': {
        display: 'block',
        position: 'absolute',
        top: styles[type].top,
        left: '25%',
        width: '50%',
        height: styles[type].height,
        content: '""',
        borderRadius: '50%',
        backgroundColor: theme.colors.grey4,
      },
    },
  }),
  checkedIcon: ({ type }: StylesProps) => ({
    backgroundColor: theme.colors.cyan,
    border: `2px solid ${theme.colors.cyan}`,
    boxSizing: 'border-box',
    '&:before': {
      display: 'block',
      width: '100%',
      height: '100%',
      content: '""',
      ...styles[type].before,
    },
    'input:hover ~ &': {
      backgroundColor: theme.colors.cyan,
    },
  }),
}));

export const useBoxLabelStyles = makeStyles((theme: Theme) => ({
  root: ({ isReadOnly }: StylesLabelProps) => ({
    pointerEvents: isReadOnly ? 'none' : 'auto',
  }),
  label: ({ checked, marginLeft = '23px' }: StylesLabelProps) => ({
    fontStyle: 'normal',
    fontSize: '18px',
    lineHeight: '28px',
    color: theme.colors.black,
    mixBlendMode: 'normal',
    letterSpacing: '-0.2px',
    fontWeight: checked ? 600 : 'normal',
    marginLeft,
    userSelect: 'none',
  }),
}));
