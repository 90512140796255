import { makeStyles, Theme } from '@material-ui/core/styles';

export default makeStyles((theme: Theme) => ({
  container: {
    display: 'flex',
    alignItems: 'center',
  },
  imageContainer: {
    cursor: 'pointer',
    marginLeft: 20,
    '& img': {
      borderRadius: '50%',
      objectFit: 'cover',
    },
  },
  cart: {
    marginLeft: 20,
  },
  dotsContainer: {
    cursor: 'pointer',
    marginLeft: 20,
  },
  menuContainer: {
    cursor: 'pointer',
    width: '24px',
    height: '24px',
  },
  list: {
    paddingTop: 'unset',
    paddingBottom: 'unset',
  },
  paper: ({ isMini }: { isMini: boolean }) => ({
    top: isMini ? '80px !important' : 'inherit',
    left: isMini ? '0px !important' : 'inherit',
    width: isMini ? '100%' : '250px',
    maxWidth: isMini ? '100%' : '250px',
    borderRadius: '6px',
    filter: 'drop-shadow(0px 24px 64px rgba(67, 86, 100, 0.24))',
  }),
  item: {
    '&:first-child': {
      borderTop: '1px solid #E2E2EA',
    },
    ...theme.fontStyle.p16,
    padding: '0 36px',
    borderBottom: '1px solid #E2E2EA',
    height: '81px',
    '&:hover': {
      backgroundColor: theme.colors.cyan,
      color: theme.colors.white,
    },
  },
  info: {
    padding: '32px 36px 37px',
    [theme.breakpoints.only('xs')]: {
      padding: '32px 16px',
    },
  },
  button: {
    '@media (max-width:374px)': {
      width: '130px',
    },
  },
  buttonsContainer: {
    marginBottom: '36px',
    '& > a:first-child': {
      marginRight: '20px',
    },
    [theme.breakpoints.only('xs')]: {
      justifyContent: 'space-between',
      '& > a:first-child': {
        marginRight: 'unset',
      },
    },
  },
}));
