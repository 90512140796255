import { createStyles, makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) =>
  createStyles({
    wrapper: {
      width: '550px',
      paddingBottom: '42px',
      [theme.breakpoints.only('xs')]: {
        width: '100%',
      },
    },
    heading: {
      margin: '32px 0 16px',
      textAlign: 'center',
    },
    textContainer: {
      padding: '0 30px',
    },
    text: {
      textAlign: 'center',
    },
    buttonContainer: {
      marginTop: '32px',
    },
    link: {
      color: theme.colors.cyan,
    },
  })
);
