import React, { FunctionComponent, useMemo, memo, useRef } from 'react';
import { Link, useLocation } from 'react-router-dom';
import cn from 'classnames';
// material-ui components
import { Grid, Typography } from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';
// components
import { FilterMenu } from './components';
// custom hooks
import { useStore } from 'hooks/useStore';
// constants
import { skeletons } from './constants';
// types
import { ICategoriesFilters } from './types';
// styles
import useStyles from './styles';

export const CategoriesFilter: FunctionComponent<ICategoriesFilters> = memo(({ isSearchPage }) => {
  const location = useLocation();
  const withShadow = useMemo(() => location.pathname.includes('/profile'), [location.pathname]);
  const classes = useStyles({ withShadow });
  const { parentCategories, categories } = useStore();
  const subcategoriesRef = useRef<HTMLDivElement>(null);

  const categoriesWithSubcategories = useMemo(
    () =>
      parentCategories.map((item) => ({
        ...item,
        subcategories: categories.filter((categoriesItem) => item.id === categoriesItem.parent_id),
      })),
    [categories, parentCategories]
  );

  return (
    <Grid container justify="center" className={classes.wrapper}>
      <Grid container justify="space-between" alignItems="center" wrap="nowrap">
        <Grid
          container
          wrap="nowrap"
          alignItems="center"
          justify="space-between"
          item
          xs={11}
          className={classes.categories}
        >
          {parentCategories.length ? (
            categoriesWithSubcategories.map(({ id, title, subcategories, slug }) => (
              <div key={id} className={classes.categoriesItem}>
                <Typography
                  variant="body1"
                  component={Link}
                  to={{ pathname: `/${slug}` }}
                  className={classes.link}
                >
                  {title}
                </Typography>
                <Grid container className={classes.subcategories} ref={subcategoriesRef}>
                  {subcategories.map((item) => (
                    <Grid
                      container
                      key={item.id}
                      component={Link}
                      to={{
                        pathname: '/search',
                        search: `?categories=${id}&subCategories=${item.id}`,
                      }}
                      className={cn(classes.subcategoriesItem, classes.link)}
                    >
                      <span className={classes.ellipsis}>{item.title}</span>
                    </Grid>
                  ))}
                </Grid>
              </div>
            ))
          ) : (
            <>
              {skeletons.map(({ width }, index) => (
                <Skeleton
                  key={index.toString()}
                  animation="wave"
                  variant="text"
                  width={width}
                  height={28}
                  className={classes.skeleton}
                />
              ))}
            </>
          )}
        </Grid>
        <Grid container justify="flex-end" item xs>
          <FilterMenu isSearchPage={isSearchPage} />
        </Grid>
      </Grid>
    </Grid>
  );
});
