import React, { FunctionComponent } from 'react';
import { Link } from 'react-router-dom';
// components
import { Button } from 'components';
// icons
import { Plus } from 'assets/icons';
// types
import { ICreateClassButton } from './types';

const CreateClassButton: FunctionComponent<ICreateClassButton> = ({
  businessIsApproved,
  openAdminApproveModal,
}) => {
  return (
    <>
      {businessIsApproved ? (
        <Button
          component={Link}
          to="/classes/create"
          size="small"
          label="Add class"
          startIcon={<Plus />}
        />
      ) : (
        <Button
          size="small"
          label="Add class"
          startIcon={<Plus />}
          onMouseDown={openAdminApproveModal}
        />
      )}
    </>
  );
};

export default CreateClassButton;
