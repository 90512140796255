import React, { FunctionComponent, memo } from 'react';
// material-ui
import { SwitchProps, Switch as SwitchMUI } from '@material-ui/core';
// styles
import useStyles from './styles';

export const Switch: FunctionComponent<SwitchProps> = memo<SwitchProps>((props) => {
  const classes = useStyles();
  return <SwitchMUI {...props} classes={classes} disableRipple />;
});
