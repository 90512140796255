export enum StatusProps {
  active = 'ACTIVE',
  inactive = 'INACTIVE',
  draft = 'DRAFT',
  opened = 'OPENED',
  published = 'PUBLISHED',
  expired = 'EXPIRED',
  deactivated = 'DESACTIVATED',
}

export interface IBadge {
  text: string;
  status?: StatusProps;
  [x: string]: any;
}

export interface StylesProps {
  status?: StatusProps;
  [x: string]: any;
}

export interface ObjectProps {
  [x: string]: any;
}
