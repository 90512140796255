import React, { FunctionComponent } from 'react';
// material-ui components
import { Grid, Typography, LinearProgress } from '@material-ui/core';
// icons
import { ReactComponent as SandClock } from 'assets/icons/origin/SandClock.svg';
// styles
import useStyles from './styles';

export const ConvertHEIC: FunctionComponent = () => {
  const classes = useStyles();

  return (
    <Grid container direction="column" alignItems="center" className={classes.wrapper}>
      <Grid item className={classes.iconContainer}>
        <SandClock />
      </Grid>
      <Grid container justify="center" item>
        <Typography variant="h5" className={classes.heading}>
          Please wait, we will convert your image to a suitable format, it may take some time.
        </Typography>
      </Grid>
      <Grid container>
        <LinearProgress className={classes.progress} />
      </Grid>
      <Grid container justify="center" item>
        <Typography variant="h5" className={classes.heading}>
          Thanks for waiting!
        </Typography>
      </Grid>
    </Grid>
  );
};
