import React, { FunctionComponent, useCallback } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers';
// material-ui components
import { Grid, Typography } from '@material-ui/core';
// components
import { Button } from 'components';
import { ErrorModal } from 'modals/ErrorModal';
// controllers
import { OutlinedInputController, RatingController } from 'controllers';
// custom hooks
import { useModalSetter } from 'hooks/useModal';
// types
import { IFormData, Props } from './types';
// api
import api from 'utils/api';
// styles
import useStyles from './styles';
// validations
import { Schema } from './validation';
// helpers
import { defaultValues } from './helpers';

export const PostAReview: FunctionComponent<Props> = ({ id, mutateRequest }) => {
  const classes = useStyles();
  const { setModal } = useModalSetter();

  const form = useForm({
    resolver: yupResolver(Schema),
    defaultValues,
  });

  const handleCloseModal = () => {
    setModal(null);
  };

  const onSubmit = useCallback(
    async (formData: IFormData) => {
      try {
        await api.businessReview.post({ data: formData, id });
        await mutateRequest();
        setModal(null);
      } catch (err) {
        setModal(<ErrorModal message={err?.data?.message?.toString()} />);
      }
    },
    [id, setModal, mutateRequest]
  );

  return (
    <FormProvider {...form}>
      <Grid container direction="column" className={classes.wrapper}>
        <Typography variant="h5" paragraph>
          Post a Review
        </Typography>
        <Grid container component="form" onSubmit={form.handleSubmit(onSubmit)} direction="column">
          <Grid container item className={classes.inputContainer}>
            <OutlinedInputController
              name="text"
              placeholder="Please let us know what do you think about this business"
              fullWidth
              multiline
              rows={7}
              inputProps={{ maxLength: 2048 }}
              errorField={form?.errors?.text}
              required
            />
          </Grid>
          <Typography variant="caption" className={classes.rating}>
            general impression
          </Typography>
          <RatingController name="rating" />
          <Grid container justify="center" className={classes.buttonsContainer}>
            <Button
              variant="text"
              label="Cancel"
              width="126px"
              onClick={handleCloseModal}
              className={classes.button}
            />
            <Button type="submit" label="Post" width="185px" />
          </Grid>
        </Grid>
      </Grid>
    </FormProvider>
  );
};
