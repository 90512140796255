import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

export default makeStyles((theme: Theme) =>
  createStyles({
    switchBase: {
      color: theme.colors.grey5,
      '&$checked': {
        color: theme.colors.cyan,
        opacity: 1,
      },
      '&$checked + $track': {
        opacity: 1,
        backgroundColor: theme.colors.cyan,
      },
    },
    checked: {},
    thumb: {
      background: '#FFFFFF',
      boxShadow: '0px 0px 4px rgba(67, 86, 100, 0.25)',
    },
    track: {
      background: theme.colors.grey5,
      opacity: '1',
      borderRadius: '12312px',
    },
  })
);
