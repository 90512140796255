import { makeStyles, Theme } from '@material-ui/core/styles';

export default makeStyles((theme: Theme) => ({
  label: {
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '18px',
    lineHeight: '28px',
    color: theme.colors.black,
    mixBlendMode: 'normal',
    letterSpacing: '-0.2px',
  },
}));
