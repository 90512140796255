import { makeStyles, createStyles } from '@material-ui/core/styles';

export default makeStyles((theme) =>
  createStyles({
    shareButton: {
      display: 'flex',
      alignItems: 'center',
      gap: 8,
      cursor: 'pointer',
      '& img': {
        width: '24px',
        height: '24px',
      },
    },
    menuList: {
      padding: '22px 0',
      borderRadius: '6px',
    },
    menuPaper: {
      marginTop: '8px',
    },
    menuItemRoot: {
      height: '40px',
      padding: '0 30px',
    },
    menuItemText: {
      marginLeft: '16px',
      color: theme.colors.cyan,
    },
    icons: {
      display: 'flex',
    },
    copyButton: {
      border: 'none',
      background: 'none',
      outline: 'none',
      cursor: 'pointer',
    },
    sms: {
      textDecoration: 'none',
      display: 'flex',
      '& svg': {
        width: '32px',
        height: '32px',
      },
    },
    shareItemLabel: {
      marginLeft: 8,
    },
  })
);
