import React, { FunctionComponent, memo, SyntheticEvent } from 'react';

interface Props {
  onClick?: (event: SyntheticEvent) => void;
  className?: string;
}

export const Remove: FunctionComponent<Props> = memo((props) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18 13H6C5.45 13 5 12.55 5 12C5 11.45 5.45 11 6 11H18C18.55 11 19 11.45 19 12C19 12.55 18.55 13 18 13Z"
      fill="#B9C1CC"
    />
  </svg>
));
