import { createStyles, makeStyles } from '@material-ui/core/styles';

interface StylesProps {
  width?: string;
}

export default makeStyles((theme) =>
  createStyles({
    root: ({ width = '100%' }: StylesProps) => ({
      position: 'relative',
      width,
      [theme.breakpoints.only('xs')]: {
        position: 'unset',
      },
    }),
    icon: {
      cursor: 'pointer',
    },
    dropdown: {
      position: 'absolute',
      top: 55,
      right: 0,
      left: 0,
      zIndex: 10,
      width: '290px',
      backgroundColor: theme.colors.white,
      boxShadow: '0px 24px 64px rgba(67, 86, 100, 0.24)',
      borderRadius: '6px',
      '& .MuiPickersStaticWrapper-staticWrapperRoot': {
        minWidth: '290px',
        alignItems: 'center',
      },
    },
    wrap: {
      padding: '13px 33px 14px',
    },
    time: {
      margin: '25px 0',
      userSelect: 'none',
    },
    dot: {
      width: '4px',
      height: '4px',
      backgroundColor: theme.colors.grey4,
      borderRadius: '50%',
      margin: '6px 0',
    },
  })
);
