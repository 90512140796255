import { makeStyles, createStyles } from '@material-ui/core/styles';

export default makeStyles((theme) =>
  createStyles({
    wrapper: {
      width: '1280px',
    },
    container: {
      padding: '0 36px 24px',
      [theme.breakpoints.down('xs')]: {
        padding: '0 0 24px',
      },
    },
    heading: {
      marginBottom: '15px',
    },
    selectContainer: {
      margin: '20px 0',
    },
    spinnerContainer: {
      height: '615px',
    },
    itemsContainer: {
      [theme.breakpoints.down('md')]: {
        flexDirection: 'column',
      },
    },
    buttonContainer: {
      [theme.breakpoints.down('md')]: {
        paddingBottom: '20px',
      },
    },
    tabs: {
      height: '2px',
      width: '100%',
      transform: 'matrix(1, 0, 0, -1, 0, 0)',
      background: theme.colors.grey5,
      margin: '-2px 0 16px',
    },
    tabsRoot: {
      width: '100%',
    },
    tabRoot: {
      width: '100%',
      height: '60px',
      opacity: 1,
      textTransform: 'initial',
      ...theme.typography.h5,
    },
    tabIndicator: {
      background: theme.colors.cyan,
      borderRadius: '10px 10px 0px 0px',
      zIndex: 1,
    },
    tabsContainer: {
      position: 'sticky',
      top: '82px',
      zIndex: 10,
      backgroundColor: theme.colors.white,
    },
  })
);
