import { createStyles, makeStyles } from '@material-ui/core';

export default makeStyles(() =>
  createStyles({
    error: {
      color: '#FF3A29',
      textTransform: 'initial',
      fontSize: 'inherit',
      lineHeight: 'inherit',
      fontWeight: 'inherit',
    },
    asterics: {
      verticalAlign: 'text-top',
      width: '12px',
      height: '12px',
    },
    centered: {
      verticalAlign: 'unset',
    },
  })
);
