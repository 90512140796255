import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  container: {
    display: 'flex',
    alignItems: 'center',
    width: '240px',
    height: '88px',
    padding: '15px 20px 17px',
    border: `1px solid ${theme.colors.grey5}`,
    borderRadius: '12px',
  },
  iconContainer: {
    marginRight: '24px',
  },
  infoContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  count: {
    fontWeight: 600,
    lineHeight: 1,
  },
}));
