import axios, { AxiosInstance, Method, AxiosRequestConfig } from 'axios';
import { getAuthData, setAuthData, clearAuthData } from './authData';

export const API_URL = process.env.REACT_APP_API_URL;

interface AxiosResponseData {
  [key: string]: any;
}

const instance: AxiosInstance = axios.create({
  baseURL: `${API_URL}`,
  responseType: 'json',
});

/* request interceptor */
instance.interceptors.request.use((request) => {
  Object.assign(request.headers, getAuthData());
  return request;
});

/* response interceptor */
instance.interceptors.response.use(
  ({ data }) => {
    if (data?.accessToken) setAuthData({ Authorization: `Bearer ${data?.accessToken}` });
    return data;
  },
  (responseData = {}) => {
    const { response, message } = responseData;
    const data = response?.data;
    const status = response?.status;

    if (status === 401) {
      clearAuthData();
    }

    return Promise.reject({ data, message, status });
  }
);

type URL = string | ((id?: number | string) => string);

interface IArg extends AxiosRequestConfig {
  id?: number | string;
}

export type GetAxiosResponse = <T = AxiosResponseData>(args?: IArg) => Promise<T>;

export type TMakeAxiosRequest = (url: URL, method: Method) => GetAxiosResponse;

const makeAxiosRequest: TMakeAxiosRequest = (url, method) => (args = {}) => {
  const { id } = args;
  return instance({
    url: typeof url === 'function' ? url(id) : url,
    method,
    ...args,
  }) as Promise<any>;
};

export default makeAxiosRequest;
