import React, { FunctionComponent, MouseEvent, memo } from 'react';

interface IVisibilityOn {
  onClick?: () => void;
  onMouseDown?: (event: MouseEvent) => void;
}

export const VisibilityOn: FunctionComponent<IVisibilityOn> = memo(({ onClick, onMouseDown }) => (
  <svg
    width="22"
    height="22"
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    onClick={onClick}
    onMouseDown={onMouseDown}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.0002 3.66669C14.289 3.66669 17.1926 5.8964 19.7188 10.2149L19.9076 10.5427L20.1668 11L19.7188 11.7851C17.1926 16.1036 14.289 18.3334 11.0002 18.3334C7.7113 18.3334 4.80775 16.1036 2.28151 11.7851L2.09275 11.4573L1.8335 11L2.09275 10.5427C4.66281 6.00928 7.62908 3.66669 11.0002 3.66669ZM11.0002 5.50002C8.52233 5.50002 6.16775 7.25593 3.9467 11C6.16775 14.7441 8.52233 16.5 11.0002 16.5C13.4107 16.5 15.7016 14.8409 17.8633 11.316L18.0537 11.0001L17.863 10.6841C15.698 7.15706 13.4072 5.50002 11.0002 5.50002ZM11.0002 8.25002C12.5189 8.25002 13.7502 9.48124 13.7502 11C13.7502 12.5188 12.5189 13.75 11.0002 13.75C9.48138 13.75 8.25016 12.5188 8.25016 11C8.25016 9.48124 9.48138 8.25002 11.0002 8.25002Z"
      fill="#B9C1CC"
    />
  </svg>
));
