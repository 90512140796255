import React, { FunctionComponent } from 'react';
// form
import { Controller, useFormContext } from 'react-hook-form';
// types
import { InputProps } from '@material-ui/core';
// components
import { Datepicker } from 'components';

interface Props extends InputProps {
  centeredPlaceholder?: boolean;
  isPassword?: boolean;
  fullWidth?: boolean;
  width?: string;
  required?: boolean;
  name: string;
  errorField?: {
    type: string;
    message?: string;
  };
  [x: string]: any;
  readOnly?: boolean;
}

export const DatePickerController: FunctionComponent<Props> = ({
  name = '',
  defaultValue = null,
  errorField,
  ...props
}) => {
  const {
    control,
    formState: { isSubmitted },
  } = useFormContext();

  return (
    <Controller
      as={<Datepicker {...props} errorField={errorField} isSubmitted={isSubmitted} />}
      name={name}
      control={control}
      defaultValue={defaultValue}
    />
  );
};
