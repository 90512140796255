import { createStyles, makeStyles } from '@material-ui/core/styles';

export default makeStyles(() =>
  createStyles({
    wrapper: {
      width: '426px',
      padding: '13px 31px 35px',
    },
    buttonsContainer: {
      marginTop: '62px',
    },
    button: {
      marginLeft: '27px',
    },
    rating: {
      marginBottom: '4px',
    },
    inputContainer: {
      marginBottom: '27px',
      '& label': {
        height: '80px !important',
        '& > span': {
          whiteSpace: 'normal',
        },
      },
    },
  })
);
