import { createStyles, makeStyles } from '@material-ui/core/styles';

export const useStylesBanner = makeStyles((theme) =>
  createStyles({
    root: {
      width: '100%',
      height: '72px',
      padding: '18px 32px',
      paddingRight: '50px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-start',
      position: 'relative',
      backgroundColor: '#343E5C',
      '@media (max-width:580px)': {
        padding: '10px 32px 10px 10px',
      },
    },
    imgContaier: {
      display: 'flex',
    },
    heart: {
      height: '100%',
      objectFit: 'contain',
      marginRight: '10px',
      '@media (max-width:580px)': {
        height: '50%',
      },
    },
    standWithUkraineContaner: {
      display: 'flex',
      alignItems: 'stretch',
      alignContent: 'stretch',
      justifyContent: 'flex-start',
      flexDirection: 'column',
      marginRight: '24px',
      '@media (max-width:580px)': {
        width: '90px',
      },
      '@media (max-width:460px)': {
        width: '45px',
        marginRight: '10px',
      },
    },
    standWithContaner: {
      display: 'flex',
      alignItems: 'stretch',
      alignContent: 'stretch',
      justifyContent: 'flex-start',
      marginBottom: '5px',
    },
    standWith: {
      '@media (max-width:460px)': {
        transform: 'scaleY(2.2)',
        position: 'relative',
        top: '2px',
      },
    },
    flag: {
      width: '15px',
      marginLeft: '3px',
    },
    ukraine: {
      width: 'auto',
    },
    text: {
      fontSize: '14px',
      lineHeight: '24px',
      fontWeight: 500,
      textAlign: 'center',
      color: theme.colors.white,
      flexGrow: 1,
      flexBasis: '100%',
      '@media (max-width:780px)': {
        fontSize: '12px',
        lineHeight: '16px',
        fontWeight: 400,
      },
      '@media (max-width:580px)': {
        fontSize: '10px',
        lineHeight: '16px',
        fontWeight: 400,
      },
    },
    delete: {
      cursor: 'pointer',
    },
    supportLink: {
      color: theme.colors.orange,
      cursor: 'pointer',
      backgroundColor: 'transparent',
      textDecoration: 'underline',
      border: 'none',

      fontSize: 'inherit',
      lineHeight: 'inherit',
      fontWeight: 'inherit',
    },
    closeBtn: {
      position: 'absolute',
      top: '50%',
      transform: 'translateY(-50%)',
      right: '24px',
      '@media (max-width:580px)': {
        right: '10px',
      },
    },
    close: {
      '& svg': {
        cursor: 'pointer',
        willChange: 'transform',
        transition: 'transform 0.3s ease-in-out',
        '&:hover': {
          transform: 'scale(1.1)',
        },
      },
    },
    closeModalContainer: {
      position: 'absolute',
      zIndex: 1302,
      top: '35px',
      right: '35px',
      '@media (max-width:600px)': {
        top: '25px',
        right: '20px',
      },
    },
    closeModalBtn: {
      '& svg': {
        cursor: 'pointer',
        willChange: 'transform',
        transition: 'transform 0.3s ease-in-out',
        '&:hover': {
          transform: 'scale(1.1)',
        },
      },
    },
  })
);
