import { Theme } from '@material-ui/core';
import { ObjectProps, StatusProps } from './types';

export const statusProps = (theme: Theme, status?: StatusProps): ObjectProps => {
  if (!status) return {};

  const colors = {
    [StatusProps.active]: {
      textColor: theme.colors.orange,
      backgroundColor: theme.colors.orangePastel,
    },
    [StatusProps.inactive]: {
      textColor: theme.colors.cyan,
      backgroundColor: theme.colors.orangePastel,
    },
  };

  return {
    margin: 'unset',
    textTransform: 'capitalize',
    color: colors[status].textColor,
    borderColor: colors[status].backgroundColor,
    backgroundColor: colors[status].backgroundColor,
  };
};
