import React, { FunctionComponent, memo } from 'react';
// material-ui components
import { Grid, Typography } from '@material-ui/core';
// icons
import { ReactComponent as PrivacyPolicy } from 'assets/icons/origin/PrivacyPolicy.svg';
import { ReactComponent as Handshake } from 'assets/icons/origin/Handshake.svg';
// types
import { IEnroll } from './types';
// styles
import useStyles from './styles';

export const Enroll: FunctionComponent<IEnroll> = memo(({ type, count }) => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <Grid item className={classes.iconContainer}>
        {type === 'capacity' ? <PrivacyPolicy /> : <Handshake />}
      </Grid>
      <Grid item className={classes.infoContainer}>
        <Typography variant="subtitle2" className={classes.count}>
          {count}
        </Typography>
        <Typography variant="body2">
          {type === 'capacity' ? 'Enroll Capacity' : 'Enrolled'}
        </Typography>
      </Grid>
    </div>
  );
});
