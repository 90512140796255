import React, { FunctionComponent, memo } from 'react';

interface Props {
  isActive?: boolean;
}

export const DocumentsIcon: FunctionComponent<Props> = memo(({ isActive = false }) => {
  const color = isActive ? '#268AFF' : '#B9C1CC';
  return (
    <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14 2.41187C14.2652 2.41187 14.5196 2.51722 14.7071 2.70476L19.7071 7.70476C19.8946 7.89229 20 8.14665 20 8.41187V19.4119C20 21.0687 18.6569 22.4119 17 22.4119H7C5.34315 22.4119 4 21.0687 4 19.4119V5.41187C4 3.75501 5.34315 2.41187 7 2.41187H14ZM11.999 4.41187H7C6.44772 4.41187 6 4.85958 6 5.41187V19.4119C6 19.9641 6.44772 20.4119 7 20.4119H17C17.5523 20.4119 18 19.9641 18 19.4119V10.4119H13C12.4872 10.4119 12.0645 10.0258 12.0067 9.52849L12 9.41187L11.999 4.41187ZM17.586 8.41187L13.999 4.82587L14 8.41187H17.586Z"
        fill={color}
      />
    </svg>
  );
});
