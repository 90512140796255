import React, { FunctionComponent } from 'react';
// form
import { Controller, useFormContext } from 'react-hook-form';
// types
import { InputProps } from '@material-ui/core';
// components
import { OutlinedInput } from 'components/Inputs';

interface Props extends InputProps {
  centeredPlaceholder?: boolean;
  isPassword?: boolean;
  fullWidth?: boolean;
  width?: string;
  inputComponent?: FunctionComponent<any>;
  errorField?: {
    type: string;
    message?: string;
  };
  [x: string]: any;
}

export const OutlinedInputController: FunctionComponent<Props> = ({
  name = '',
  defaultValue,
  errorField,
  ...props
}) => {
  const {
    control,
    formState: { isSubmitted },
  } = useFormContext();

  return (
    <Controller
      as={<OutlinedInput {...props} errorField={errorField} isSubmitted={isSubmitted} />}
      name={name}
      control={control}
      defaultValue={defaultValue}
    />
  );
};
