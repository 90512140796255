import { CSSProperties } from 'react';
import { createMuiTheme } from '@material-ui/core/styles';
// colors
import colors from './colors';

interface IFontStyles {
  fontSize: CSSProperties['fontSize'];
  lineHeight: CSSProperties['lineHeight'];
  fontWeight: CSSProperties['fontWeight'];
  letterSpacing: CSSProperties['letterSpacing'];
  color: CSSProperties['color'];
  textTransform?: CSSProperties['textTransform'];
}

type DeclaredColors = {
  blue: CSSProperties['color'];
  blue2: CSSProperties['color'];
  blue3: CSSProperties['color'];
  blue4: CSSProperties['color'];
  blue5: CSSProperties['color'];
  pastelBlue: CSSProperties['color'];
  cyan: CSSProperties['color'];
  orange: CSSProperties['color'];
  black: CSSProperties['color'];
  blackBasic: CSSProperties['color'];
  white: CSSProperties['color'];
  red: CSSProperties['color'];
  redPastel: CSSProperties['color'];
  green: CSSProperties['color'];
  yellow: CSSProperties['color'];
  grey1: CSSProperties['color'];
  grey2: CSSProperties['color'];
  grey3: CSSProperties['color'];
  grey4: CSSProperties['color'];
  grey5: CSSProperties['color'];
  grey6: CSSProperties['color'];
  grey7: CSSProperties['color'];
  grey8: CSSProperties['color'];
  grey9: CSSProperties['color'];
  grey10: CSSProperties['color'];
  background: CSSProperties['color'];
  primaryLight: CSSProperties['color'];
  primaryDark: CSSProperties['color'];
  buttonDisabled: CSSProperties['color'];
  buttonDisabledOutline: CSSProperties['color'];
  facebook: CSSProperties['color'];
  facebookLight: CSSProperties['color'];
  facebookDark: CSSProperties['color'];
  goggle: CSSProperties['color'];
  goggleLight: CSSProperties['color'];
  goggleDark: CSSProperties['color'];
  darkBlue: CSSProperties['color'];
  orangeLight: CSSProperties['color'];
  orangeDark: CSSProperties['color'];
  orangeDisabled: CSSProperties['color'];
  orangePastel: CSSProperties['color'];
  cyanLight: CSSProperties['color'];
  flaming: CSSProperties['color'];
  pastelFlaming: CSSProperties['color'];
  velvet: CSSProperties['color'];
  pastelVelvet: CSSProperties['color'];
  [x: string]: CSSProperties['color'];
};

type DeclaredFontStyles = {
  leadText: IFontStyles;
  p18: IFontStyles;
  p18_bold: IFontStyles;
  p16: IFontStyles;
  p16_bold: IFontStyles;
  p14: IFontStyles;
  p14_bold: IFontStyles;
  subtitle: IFontStyles;
  studio_bold: IFontStyles;
  studio_medium: IFontStyles;
  studio_light: IFontStyles;
  caption: IFontStyles;
  overline: IFontStyles;
  error: IFontStyles;
};

declare module '@material-ui/core/styles/createMuiTheme' {
  interface Theme {
    colors: DeclaredColors;
    fontStyle: DeclaredFontStyles;
  }

  // allow configuration using `createMuiTheme`
  interface ThemeOptions {
    colors?: DeclaredColors;
    fontStyle?: DeclaredFontStyles;
  }
}

export default createMuiTheme({
  colors,
  typography: {
    fontFamily: 'Gilroy, sans-serif',
    fontSize: 12,
    h1: {
      fontSize: '72px',
      lineHeight: '78px',
      fontWeight: 600,
      letterSpacing: '-1.6px',
      color: '#111111',
      '@media (max-width:600px)': {
        fontSize: '48px',
        lineHeight: '56px',
        letterSpacing: '-1.2px',
      },
    },
    h2: {
      fontSize: '64px',
      lineHeight: '72px',
      fontWeight: 600,
      letterSpacing: '-1.2px',
      color: '#111111',
    },
    h3: {
      fontSize: '48px',
      lineHeight: '56px',
      fontWeight: 600,
      letterSpacing: '-1.2px',
      color: '#111111',
      '@media (max-width:600px)': {
        fontSize: '36px',
        lineHeight: '46px',
        letterSpacing: '-0.8px',
      },
    },
    h4: {
      fontSize: '36px',
      lineHeight: '46px',
      fontWeight: 600,
      letterSpacing: '-0.8px',
      color: '#111111',
      '@media (max-width:600px)': {
        fontSize: '24px',
        lineHeight: '34px',
      },
    },
    h5: {
      fontSize: '24px',
      lineHeight: '34px',
      fontWeight: 600,
      letterSpacing: '-0.8px',
      color: '#111111',
      '@media (max-width:600px)': {
        fontSize: '16px',
        lineHeight: '26px',
        letterSpacing: '-0.24px',
      },
    },
    subtitle1: {
      fontSize: '22px',
      lineHeight: '32px',
      fontWeight: 'normal',
      letterSpacing: '-0.4px',
      color: '#111111',
    },
    subtitle2: {
      fontSize: '18px',
      lineHeight: '28px',
      fontWeight: 'normal',
      letterSpacing: '-0.2px',
      color: '#111111',
    },
    body1: {
      fontSize: '16px',
      lineHeight: '26px',
      fontWeight: 'normal',
      letterSpacing: '-0.24px',
      color: '#111111',
    },
    body2: {
      fontSize: '14px',
      lineHeight: '24px',
      fontWeight: 'normal',
      letterSpacing: '-0.3px',
      color: '#111111',
    },
    caption: {
      fontSize: '14px',
      lineHeight: '24px',
      fontWeight: 'bold',
      letterSpacing: '1.5px',
      textTransform: 'uppercase',
      color: '#111111',
    },
    overline: {
      fontSize: '12px',
      lineHeight: '16px',
      fontWeight: 'normal',
      letterSpacing: '0.4px',
      color: '#111111',
    },
  },
  palette: {
    primary: {
      light: colors.primaryLight,
      main: colors.cyan,
      dark: colors.primaryDark,
    },
  },
  fontStyle: {
    leadText: {
      fontSize: '22px',
      lineHeight: '32px',
      fontWeight: 'normal',
      letterSpacing: '-0.4px',
      color: '#111111',
    },
    p18: {
      fontSize: '18px',
      lineHeight: '28px',
      fontWeight: 'normal',
      letterSpacing: '-0.2px',
      color: '#111111',
    },
    p18_bold: {
      fontSize: '18px',
      lineHeight: '28px',
      fontWeight: 'bold',
      letterSpacing: '-0.2px',
      color: '#111111',
    },
    p16: {
      fontSize: '16px',
      lineHeight: '26px',
      fontWeight: 'normal',
      letterSpacing: '-0.24px',
      color: '#111111',
    },
    p16_bold: {
      fontSize: '16px',
      lineHeight: '26px',
      fontWeight: 'bold',
      letterSpacing: '-0.24px',
      color: '#111111',
    },
    p14: {
      fontSize: '14px',
      lineHeight: '24px',
      fontWeight: 'normal',
      letterSpacing: '-0.3px',
      color: '#111111',
    },
    p14_bold: {
      fontSize: '14px',
      lineHeight: '24px',
      fontWeight: 'bold',
      letterSpacing: '-0.3px',
      color: '#111111',
    },
    subtitle: {
      fontSize: '14px',
      lineHeight: '22px',
      fontWeight: 'bold',
      letterSpacing: '-0.3px',
      color: '#111111',
    },
    studio_bold: {
      fontSize: '16px',
      lineHeight: '19px',
      fontWeight: 'bold',
      letterSpacing: '0.75px',
      color: '#0045FF',
      textTransform: 'uppercase',
    },
    studio_medium: {
      fontSize: '16px',
      lineHeight: '19px',
      fontWeight: 500,
      letterSpacing: '-0.355556px',
      color: '#0045FF',
    },
    studio_light: {
      fontSize: '14px',
      lineHeight: '16px',
      fontWeight: 500,
      letterSpacing: '-0.3px',
      color: '#0045FF',
    },
    caption: {
      fontSize: '11px',
      lineHeight: '16px',
      fontWeight: 'normal',
      letterSpacing: '0.4px',
      color: '#111111',
    },
    overline: {
      fontSize: '10px',
      lineHeight: '16px',
      fontWeight: 'bold',
      letterSpacing: '1.5px',
      color: '#111111',
      textTransform: 'uppercase',
    },
    error: {
      fontSize: '11px',
      lineHeight: '16px',
      fontWeight: 'normal',
      letterSpacing: '0.4px',
      color: '#FF3A29',
    },
  },
});
