import React, { FunctionComponent, useCallback } from 'react';
// material-ui components
import { Grid, Typography } from '@material-ui/core';
// components
import { Button } from 'components';
// icons
import { ReactComponent as CloudError } from 'assets/icons/origin/CloudError.svg';
// custom hooks
import { useModalSetter } from 'hooks/useModal';
// types
import { Props } from './types';
// styles
import useStyles from './styles';

export const PromoteLimitReached: FunctionComponent<Props> = ({ businessName }) => {
  const { setModal } = useModalSetter();
  const classes = useStyles();

  const handleCloseModal = useCallback(() => {
    setModal(null);
  }, [setModal]);

  return (
    <Grid container direction="column" alignItems="center" className={classes.wrapper}>
      <Grid item>
        <CloudError />
      </Grid>
      <Grid container justify="center" item>
        <Typography variant="h5" className={classes.heading}>
          Hi {businessName}
        </Typography>
      </Grid>
      <Grid item>
        <Typography variant="body1" className={classes.text}>
          Your Kivity Ad can not be processed at this time. If you are interested in purchasing in
          one of our advertising tiers please feel free to contact{' '}
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="mailto: sales@kivity.com"
            className={classes.link}
          >
            sales@kivity.com
          </a>
          , or your Account Manager.
        </Typography>
      </Grid>
      <Grid container justify="center" item className={classes.textContainer}>
        <Typography variant="body1" className={classes.text}>
          Someone from the Kivity Team will be reaching out to you shortly, to assist you with this
          issue.
        </Typography>
      </Grid>
      <Grid item className={classes.buttonContainer}>
        <Button label="Ok" width="220px" onClick={handleCloseModal} />
      </Grid>
    </Grid>
  );
};
