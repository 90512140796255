import { createStyles, makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) =>
  createStyles({
    close: {
      '& svg': {
        cursor: 'pointer',
        willChange: 'transform',
        transition: 'transform 0.3s ease-in-out',
        '&:hover': {
          transform: 'scale(1.1)',
        },
      },
    },
    wrap: {
      width: 'auto',
      maxHeight: '100%',
      overflowY: 'auto',
      padding: '24px',
      borderRadius: '6px',
      backgroundColor: theme.colors.white,
      boxShadow: '0px 60px 80px rgba(40, 44, 65, 0.26)',
      [theme.breakpoints.down('xs')]: {
        padding: '24px 18px',
      },
    },
  })
);
