import GoogleCalendar from 'assets/icons/origin/GoogleCalendar.svg';
import AppleCalendar from 'assets/icons/origin/AppleCalendar.svg';
import OutlookCalendar from 'assets/icons/origin/OutlookCalendar.svg';

export const links = [
  {
    href: 'https://support.apple.com/en-us/HT202361',
    Icon: AppleCalendar,
  },
  {
    href:
      'https://support.google.com/calendar/answer/37100?co=GENIE.Platform%3DDesktop&oco=1&hl=en',
    Icon: GoogleCalendar,
  },
  {
    href:
      'https://support.microsoft.com/en-us/office/import-or-subscribe-to-a-calendar-in-outlook-com-cff1429c-5af6-41ec-a5b4-74f2c278e98c?omkt=en-us&ui=en-us&rs=en-us&ad=us',
    Icon: OutlookCalendar,
  },
];
