export enum AvatarGender {
  male = 'MALE',
  female = 'FEMALE',
  other = 'OTHER',
  business = 'BUSINESS',
}

export interface IAvatar {
  path: string;
  size: number;
  createdAt: string;
  mimeType: string;
  guid: string | null;
  original_name: string;
}
