import React, { FunctionComponent, memo } from 'react';

interface Props {
  isActive?: boolean;
}

export const Camera: FunctionComponent<Props> = memo(({ isActive = false }) => {
  const color = isActive ? '#268AFF' : '#B9C1CC';
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.7639 3C14.9002 3 15.939 3.64201 16.4472 4.65836L17.3416 6.44721C17.511 6.786 17.8573 7 18.2361 7H19C20.6569 7 22 8.34315 22 10V18C22 19.6569 20.6569 21 19 21H5C3.34315 21 2 19.6569 2 18V10C2 8.34315 3.34315 7 5 7H5.76393C6.1427 7 6.48897 6.786 6.65836 6.44721L7.55279 4.65836C8.06096 3.64201 9.09975 3 10.2361 3H13.7639ZM13.7639 5H10.2361C9.8573 5 9.51103 5.214 9.34164 5.55279L8.44721 7.34164C7.93904 8.35799 6.90025 9 5.76393 9H5C4.44772 9 4 9.44772 4 10V18C4 18.5523 4.44772 19 5 19H19C19.5523 19 20 18.5523 20 18V10C20 9.44772 19.5523 9 19 9H18.2361C17.0998 9 16.061 8.35799 15.5528 7.34164L14.6584 5.55279C14.489 5.214 14.1427 5 13.7639 5ZM12 9C14.2091 9 16 10.7909 16 13C16 15.2091 14.2091 17 12 17C9.79086 17 8 15.2091 8 13C8 10.7909 9.79086 9 12 9ZM12 11C10.8954 11 10 11.8954 10 13C10 14.1046 10.8954 15 12 15C13.1046 15 14 14.1046 14 13C14 11.8954 13.1046 11 12 11Z"
        fill={color}
      />
    </svg>
  );
});
