export enum StatusProps {
  active = 'active',
  inactive = 'inactive',
}

export interface IBadge {
  text: string;
  status?: StatusProps;
  [x: string]: any;
}

export interface StylesProps {
  status?: StatusProps;
  [x: string]: any;
}

export interface ObjectProps {
  [x: string]: any;
}
