import React, { FunctionComponent, MouseEvent, useMemo, memo } from 'react';
// material-ui components
import { FormControl, InputLabel, Input, InputAdornment, FormHelperText } from '@material-ui/core';
// icons
import { Asterics, Check, Close } from 'assets/icons';
// helpers
import { makeGenerateColor } from 'utils/helpers';
// styles
import useStyles from './styles';
// interfaces
import { InputProps } from './types';

export const BaseInput: FunctionComponent<InputProps> = memo(
  ({
    type,
    errorField,
    placeholder,
    centeredPlaceholder,
    startIcon,
    endIcon,
    isSubmitted,
    fullWidth,
    width,
    isPassword,
    disabled,
    required,
    multiline,
    isWhite,
    ...inputProps
  }) => {
    const generateColor = useMemo(
      () => makeGenerateColor({ isSubmitted, disabled, error: !!errorField, isWhite }),
      [isSubmitted, disabled, errorField, isWhite]
    );

    const placeholderColor = generateColor({
      defaultColor: isWhite ? 'white' : 'grey3',
      disabledColor: 'grey4',
    });
    const underlineColor = generateColor({
      defaultColor: isWhite ? 'white' : 'cyan',
      disabledColor: 'grey4',
    });
    const textColor = generateColor({
      defaultColor: isWhite ? 'white' : 'black',
      disabledColor: 'grey4',
    });

    const classes = useStyles({
      nonEmpty: Boolean(inputProps.value),
      withStartIcon: Boolean(startIcon),
      centeredPlaceholder,
      placeholderColor,
      underlineColor,
      textColor,
      isPassword,
      fullWidth,
      width,
      multiline,
      isWhite,
    });

    const currentEndAdornment = useMemo(() => {
      if (isSubmitted && !errorField) {
        return <Check color="green" />;
      }
      if (isSubmitted && !!errorField) {
        return <Close color="red" />;
      }
      return null;
    }, [errorField, isSubmitted]);

    const handleMouseDown = (event: MouseEvent) => {
      event.preventDefault();
    };

    return (
      <FormControl classes={{ root: classes.formControlRoot }}>
        <InputLabel
          htmlFor={inputProps.name}
          disableAnimation
          classes={{
            root: classes.inputLabelRoot,
            shrink: classes.inputLabelShrink,
          }}
        >
          <span className={classes.placeholder}>
            {placeholder} {required && <Asterics />}
          </span>
        </InputLabel>
        <Input
          multiline={multiline}
          disabled={disabled}
          id={inputProps.name}
          error={isSubmitted && !!errorField}
          type={type}
          autoComplete="off"
          aria-describedby="component-helper-text"
          endAdornment={
            currentEndAdornment && (
              <InputAdornment position="end" onMouseDown={handleMouseDown}>
                {currentEndAdornment}
              </InputAdornment>
            )
          }
          startAdornment={
            startIcon && (
              <InputAdornment position="start" onMouseDown={handleMouseDown}>
                {startIcon}
              </InputAdornment>
            )
          }
          {...inputProps}
          className={inputProps.value && classes.fieldProps}
          classes={{
            root: classes.root,
            input: classes.input,
            focused: classes.focused,
            underline: classes.underline,
            error: classes.error,
            disabled: classes.disabled,
            formControl: classes.inputFormControl,
          }}
        />
        <FormHelperText
          id="component-helper-text"
          error={isSubmitted && !!errorField?.message}
          classes={{ root: classes.errorText }}
        >
          {errorField?.message}
        </FormHelperText>
      </FormControl>
    );
  }
);
