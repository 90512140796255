import { makeStyles, createStyles } from '@material-ui/core/styles';

export default makeStyles((theme) =>
  createStyles({
    link: {
      ...theme.fontStyle.p14,
      color: theme.colors.cyan,
      textDecoration: 'none',
    },
    title: {
      color: theme.colors.grey3,
    },
    separator: {
      margin: '0 4px',
    },
  })
);
