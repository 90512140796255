import { makeStyles, Theme } from '@material-ui/core/styles';
// interfaces
import { StyleProps } from './types';

export default makeStyles((theme: Theme) => ({
  header: ({ headerIsTransparent, withShadow }: StyleProps) => ({
    backgroundColor: headerIsTransparent ? 'transparent' : theme.colors.white,
    position: headerIsTransparent ? 'absolute' : 'unset',
    top: headerIsTransparent ? 0 : 'unset',
    left: headerIsTransparent ? 0 : 'unset',
    right: headerIsTransparent ? 0 : 'unset',
    filter: withShadow
      ? 'drop-shadow(0px 1px 2px rgba(67, 70, 74, 0.0001)) drop-shadow(0px 2px 5px rgba(67, 86, 100, 0.123689))'
      : 'unset',
    zIndex: withShadow ? 1300 : 'unset',
  }),
  container: {
    padding: '20px 32px',
    [theme.breakpoints.only('xs')]: {
      padding: '20px 16px',
    },
  },
  logo: {
    display: 'flex',
    alignItems: 'flex-end',
    transition: 'transform 0.3s ease-in-out',
    textDecoration: 'none',
    color: theme.colors.cyan,
    willChange: 'transform',
    '&:hover': {
      transform: 'scale(1.05)',
    },
  },
  forBusiness: {
    marginLeft: '20px',
  },
  link: ({ headerIsTransparent }: StyleProps) => ({
    ...theme.fontStyle.p16_bold,
    textDecoration: 'none',
    textTransform: 'none',
    margin: '0 12px',
    padding: '6px',
    borderRadius: '4px',
    transition: 'background 0.3s ease-in-out',
    color: headerIsTransparent ? theme.colors.white : theme.colors.black,
    '&:hover': {
      color: theme.colors.cyan,
    },
  }),
  activeLink: () => ({
    color: theme.colors.cyan,
  }),
  linkContainer: {
    '& img': {
      width: '24px',
      height: '24px',
      marginRight: '10px',
    },
  },
}));
