import React, { FunctionComponent, useCallback, useState, useMemo } from 'react';
import moment, { Moment } from 'moment';
// @material-ui
import { Grid, ClickAwayListener } from '@material-ui/core';
import { TimePicker } from '@material-ui/pickers';
// icons
import { Clock } from 'assets/icons';
// components
import { OutlinedInput } from 'components/Inputs';
// types
import { Props } from './types';
// styles
import useStyles from './styles';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';

const options = {
  shouldValidate: true,
  shouldDirty: true,
};

export const Timepicker: FunctionComponent<Props> = ({
  value,
  width,
  setValue,
  name = '',
  disabled,
  ...props
}) => {
  const classes = useStyles({ width });

  const defaultValues = useMemo(() => (value ? moment(value as string, 'hh:mm A') : null), [value]);

  const [open, setOpen] = useState(false);
  const [valueTime, setTime] = useState<Moment | null>(defaultValues);

  const onOpen = useCallback(() => {
    if (disabled) return;
    setOpen(true);
  }, [disabled]);

  const onClose = () => {
    setOpen(false);
  };

  const handleDateChange = (val: MaterialUiPickersDate) => {
    const res = val ? val.format('hh:mm A') : '';
    setTime(val);
    setValue(name, res, options);
  };

  return (
    <ClickAwayListener onClickAway={onClose}>
      <Grid container className={classes.root}>
        <OutlinedInput
          {...props}
          name={name}
          startIcon={<Clock className={classes.icon} onClick={onOpen} />}
          invariableIcon
          fullWidth
          value={value}
          disabled={disabled}
        />
        {open ? (
          <div className={classes.dropdown}>
            <TimePicker
              autoOk
              ampm={true}
              variant="static"
              value={valueTime}
              onChange={handleDateChange}
            />
          </div>
        ) : null}
      </Grid>
    </ClickAwayListener>
  );
};
