import React, { FunctionComponent, memo } from 'react';
import { Link } from 'react-router-dom';
import queryString from 'query-string';
// material-ui components
import { Menu, MenuItem } from '@material-ui/core';
// components
import { Button } from 'components/Button';
import { Checkbox } from 'components/Checkbox';
// icons
import { ReactComponent as Filter } from 'assets/icons/origin/Filter.svg';
// constants
import { checkboxes } from './constants';
// types
import { IFilterMenuView } from './types';
import { OutlinedButtonType } from 'components/Button/types';
// styles
import useStyles from './styles';

const FilterMenuView: FunctionComponent<IFilterMenuView> = ({
  isSearchPage,
  handleClick,
  anchorEl,
  handleClose,
  startSoonFilter,
  handleChangeStartSoon,
  handleChange,
  newSearchValue,
}) => {
  const classes = useStyles();

  return (
    <div>
      <Button
        aria-controls="filter-menu"
        aria-haspopup="true"
        variant="outlined"
        label="Filter"
        size="small"
        width="96px"
        endIcon={<Filter />}
        onClick={handleClick}
      />
      {!!anchorEl && (
        <Menu
          keepMounted
          elevation={5}
          variant="menu"
          anchorEl={anchorEl}
          onClose={handleClose}
          open={!!anchorEl}
          getContentAnchorEl={null}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          classes={{ list: classes.menuList, paper: classes.menuPaper }}
        >
          <MenuItem disableRipple classes={{ root: classes.menuItemRoot }}>
            <Checkbox
              label="Starting soon"
              marginLeft="8px"
              value={startSoonFilter}
              onChange={handleChangeStartSoon}
            />
          </MenuItem>
          {checkboxes.map(({ label, value }) => (
            <MenuItem disableRipple key={label} classes={{ root: classes.menuItemRoot }}>
              <Checkbox label={label} marginLeft="8px" value={value} onChange={handleChange} />
            </MenuItem>
          ))}
          <div className={classes.buttonContainer}>
            {isSearchPage ? (
              <Button
                fullWidth
                component="a"
                variant="outlined"
                label="Apply Filters"
                outlinedButtonType={OutlinedButtonType.orange}
                href={`/search?${queryString.stringify(newSearchValue, { arrayFormat: 'comma' })}`}
              />
            ) : (
              <Button
                fullWidth
                component={Link}
                variant="outlined"
                label="Apply Filters"
                outlinedButtonType={OutlinedButtonType.orange}
                to={{
                  pathname: '/search',
                  search: `?${queryString.stringify(newSearchValue, { arrayFormat: 'comma' })}`,
                }}
              />
            )}
          </div>
        </Menu>
      )}
    </div>
  );
};

export default memo(FilterMenuView);
