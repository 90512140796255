import { Roles } from 'utils/constants';
// icons
import HeartTransparent from 'assets/icons/origin/HeartTransparent.svg';
import CalendarSelectDays from 'assets/icons/origin/Calendar-select-day.svg';

export interface ILinksRole {
  header: { path: string; label: string; icon?: string }[];
  menu: { path: string; label: string }[];
  mobileMenu: { path: string; label: string }[];
}

interface ILinks {
  [x: string]: ILinksRole;
}

export interface ISignUpLinks {
  path: string;
  label: string;
  icon?: string;
}

export const links: ILinks = {
  [Roles.business]: {
    header: [
      { path: '/dashboard', label: 'Dashboard' },
      // { path: '/schedule', label: 'Schedule' },
      { path: '/classes', label: 'Classes' },
      { path: '/students', label: 'Students' },
      { path: '/profile/ads', label: 'Kivity Ads' },
      { path: '/help', label: 'Help' },
    ],
    menu: [
      { path: '/profile', label: 'Business Profile' },
      { path: '/profile/notifications', label: 'Notifications' },
      { path: '/profile/media', label: 'Photos' },
      { path: '/profile/ads', label: 'Kivity Ads' },
      { path: '/profile/payout', label: 'Payout Preferences' },
      { path: '/profile/settings', label: 'Account & Profile Settings' },
    ],
    mobileMenu: [
      { path: '/dashboard', label: 'Dashboard' },
      // { path: '/schedule', label: 'Schedule' },
      { path: '/classes', label: 'Classes' },
      { path: '/students', label: 'Students' },
      { path: '/profile', label: 'Business Profile' },
      { path: '/profile/notifications', label: 'Notifications' },
      { path: '/profile/media', label: 'Photos' },
      { path: '/profile/ads', label: 'Kivity Ads' },
      { path: '/profile/payout', label: 'Payout Preferences' },
      { path: '/profile/settings', label: 'Account & Profile Settings' },
    ],
  },
  [Roles.parent]: {
    header: [
      { path: '/calendar', label: 'Calendar', icon: CalendarSelectDays },
      { path: '/favorites', label: 'Favorites', icon: HeartTransparent },
    ],
    menu: [
      { path: '/profile', label: 'Parent profile' },
      { path: '/profile/kids', label: 'Kids' },
      { path: '/calendar', label: 'Calendar' },
      { path: '/profile/notifications', label: 'Notifications' },
      { path: '/profile/settings', label: 'Account Settings' },
    ],
    mobileMenu: [
      { path: '/calendar', label: 'Calendar' },
      { path: '/favorites', label: 'Favorites' },
      { path: '/search?limit=10&page=1&search=&tab=1', label: 'Businesses' },
      { path: '/profile', label: 'Parent profile' },
      { path: '/profile/kids', label: 'Kids' },
      { path: '/profile/notifications', label: 'Notifications' },
      { path: '/profile/settings', label: 'Account Settings' },
    ],
  },
  [Roles.unauthorized]: {
    header: [
      { path: '/about', label: 'About' },
      { path: '/help', label: 'Help' },
    ],
    menu: [],
    mobileMenu: [
      { path: '/about', label: 'About' },
      { path: '/search?limit=10&page=1&search=&tab=1', label: 'Businesses' },
      { path: '/help', label: 'Help' },
    ],
  },
  [Roles.admin]: {
    header: [
      { path: '/dashboard', label: 'Dashboard' },
      { path: '/parents', label: 'Parents' },
      { path: '/businesses', label: 'Business' },
      { path: '/classes', label: 'Classes' },
      { path: '/advertising', label: 'Advertising' },
      { path: '/partners', label: 'Partners ' },
    ],
    menu: [],
    mobileMenu: [
      { path: '/dashboard', label: 'Dashboard' },
      { path: '/parents', label: 'Parents' },
      { path: '/businesses', label: 'Business' },
      { path: '/classes', label: 'Classes' },
      { path: '/advertising', label: 'Advertising' },
      { path: '/partners', label: 'Partners ' },
    ],
  },
};

export const signUpLinks: ISignUpLinks[] = [
  { path: '/about', label: 'About' },
  { path: '/sign-up?tab=1', label: 'Businesses' },
  { path: '/help', label: 'Help' },
];

export const businessLandingLinks = {
  header: [
    { path: '/about', label: 'About' },
    { path: '/business/learn-more', label: 'Help' },
    { path: '/business/kivity-ads', label: 'Kivity Ads' },
    { path: '/business', label: 'Business Page' },
  ],
  menu: [
    { path: '/about', label: 'About' },
    { path: '/business/learn-more', label: 'Help' },
    { path: '/business/kivity-ads', label: 'Kivity Ads' },
    { path: '/business', label: 'Business Page' },
  ],
  mobileMenu: [
    { path: '/about', label: 'About' },
    { path: '/business/learn-more', label: 'Help' },
    { path: '/business/kivity-ads', label: 'Kivity Ads' },
    { path: '/business', label: 'Business Page' },
  ],
};
