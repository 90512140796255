import { createStyles, makeStyles } from '@material-ui/core/styles';

export const useStylesFile = makeStyles(() =>
  createStyles({
    root: {
      width: '160px',
    },
    text: {
      marginTop: '32px',
    },
    icon: {
      cursor: 'pointer',
    },
  })
);

export const useStylesImage = makeStyles((theme) =>
  createStyles({
    root: {
      width: '320px',
      height: '180px',
      position: 'relative',
    },
    img: {
      width: '100%',
      height: '100%',
      objectFit: 'cover',
    },
    text: {
      marginTop: '32px',
    },
    update: {
      position: 'absolute',
      margin: '12px',
      top: 0,
      left: 0,
      cursor: 'pointer',
      '& path': {
        fill: theme.colors.cyan,
      },
    },
    delete: {
      position: 'absolute',
      margin: '12px',
      top: 0,
      right: 0,
      cursor: 'pointer',
    },
  })
);
