import { createStyles, makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) =>
  createStyles({
    wrapper: {
      width: '432px',
      paddingBottom: '10px',
      [theme.breakpoints.only('xs')]: {
        width: '100%',
      },
    },
    heading: {
      margin: '32px 0 16px',
      textAlign: 'center',
    },
    iconContainer: {
      '& svg': {
        width: '100px',
        height: '100px',
      },
    },
    progress: {
      width: '100%',
      marginTop: '20px',
    },
  })
);
