import React, { FunctionComponent, lazy, Suspense, useEffect, useMemo } from 'react';
import { Redirect, Route, Switch, useLocation } from 'react-router-dom';
import MomentUtils from '@date-io/moment';
import { Integrations } from '@sentry/tracing';
import * as Sentry from '@sentry/react';
import ReactPixel from 'react-facebook-pixel';
import ReactGA from 'react-ga';
import { Helmet } from 'react-helmet';
// '@material-ui
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
// hooks
import { useStore } from 'hooks/useStore';
import { useModalSetter } from 'hooks/useModal';
// api
import Api from 'utils/api';
// components
import { Spinner } from 'components';
// layouts
import Header from 'layouts/Header';
// modals
import { SignUpUnauthorized } from 'modals';
// constants
import routesPath, { categories as categoriesRoute } from './routes';
import { authPages, Roles } from 'utils/constants';
// types
import { IRoutes } from './types';
import { ICategory } from 'types';
import { IAgesOptions } from 'hooks/useStore/types';
import PageCart from 'components/PageCart';
// lazy-load
const Search = lazy(() => import('pages/Search'));
const BusinessProfileView = lazy(() => import('pages/BusinessProfileView'));
const AdminBusinessProfileView = lazy(() => import('pages/Admin/Business/BusinessProfileView'));
// lazy components
const Footer = lazy(() => import('layouts/Footer'));
const TransparentFooter = lazy(() => import('layouts/TransparentFooter'));

ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS_TRACKING_ID || '');

Sentry.init({
  dsn: 'https://643733fed1fa4bf5b87239cb8f263755@o486179.ingest.sentry.io/5542529',
  integrations: [new Integrations.BrowserTracing()],
  environment: process.env.NODE_ENV,
});

if (process.env.REACT_APP_FB_PIXEL) {
  const reactPixelOptions = {
    autoConfig: true,
    debug: false,
  };
  ReactPixel.init(process.env.REACT_APP_FB_PIXEL, undefined, reactPixelOptions);
  ReactPixel.pageView();
}

const App: FunctionComponent = () => {
  const { user, setUserData, setStripeKey, setCategories, setCities, setAgesOptions } = useStore();
  const { setModal } = useModalSetter();
  const location = useLocation();
  let timer: NodeJS.Timer;
  let secondTimer: NodeJS.Timer;

  useEffect(() => {
    ReactGA.pageview(location.pathname + location.search);
  }, [location]);

  useEffect(() => {
    if (user.role !== Roles.unauthorized) {
      localStorage.removeItem('startTime');
      localStorage.removeItem('secondStartTime');
      clearTimeout(timer);
      clearTimeout(secondTimer);
    }
    // eslint-disable-next-line
  }, [user.role]);

  const footerIsTransparent = useMemo(() => authPages.includes(location.pathname), [
    location.pathname,
  ]);

  const setModalTimer = () => {
    const timerCount = 12000;
    const secondTimerCount = 3 * 60000;
    localStorage.setItem('startTime', new Date().valueOf().toString());
    localStorage.setItem('secondStartTime', new Date().valueOf().toString());

    timer = setTimeout(() => {
      const startTimeLocal = localStorage.getItem('startTime');
      if (startTimeLocal) setModal(<SignUpUnauthorized />);
      localStorage.removeItem('startTime');
    }, timerCount);

    secondTimer = setTimeout(() => {
      const secondStartTimeLocal = localStorage.getItem('secondStartTime');
      if (secondStartTimeLocal) setModal(<SignUpUnauthorized />);
      localStorage.removeItem('secondStartTime');
    }, secondTimerCount);
  };

  useEffect(() => {
    const accessToken = localStorage.getItem('Authorization');

    const fetchData = async () => {
      try {
        const categories = await Api.categories.getCategories<ICategory[]>();
        const cities = await Api.businessAddress.getCities<string[]>();
        const ages = await Api.auth.getAgesOptions<IAgesOptions[]>();
        setAgesOptions(ages);
        // TODO: remove filter when the client says (now filter Religious Schools)
        setCategories(categories.filter(({ title }) => title !== 'Religious Schools'));
        setCities(cities);
      } catch (err) {
        console.error(err);
      }
    };

    const fetchUserData = async () => {
      try {
        const [userData, stripeKey] = await Promise.all([
          Api.auth.getUserData(),
          Api.stripe.getStripeKey(),
        ]);
        setStripeKey(stripeKey?.stripe_public_key);
        if (userData.role === Roles.parent) {
          const children = await Api.parentProfiles.getAllChild();
          setUserData({ ...userData, children, isAuthorized: true });
        } else {
          setUserData({ ...userData, isAuthorized: true });
        }
      } catch (err) {
        console.error(err);
      }
    };

    fetchData().catch((err) => console.error(err));

    if (accessToken) {
      fetchUserData().catch((err) => console.error(err));
      localStorage.removeItem('startTime');
    } else {
      setUserData({ isAuthorized: false });
      setModalTimer();
    }

    window.onunload = () => {
      localStorage.removeItem('backToRegisterData');
      localStorage.removeItem('redirect_url');
      localStorage.removeItem('startTime');
      localStorage.removeItem('secondStartTime');
      clearTimeout(timer);
      clearTimeout(secondTimer);
    };
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (authPages.includes(location.pathname)) {
      localStorage.removeItem('startTime');
      clearTimeout(timer);
    }
    // eslint-disable-next-line
  }, [location.pathname]);

  useEffect(() => {
    const isParentProfile = user.role === Roles.parent && location.pathname.includes('/profile');
    if (!isParentProfile) {
      // TODO: check why is it here.
      // setModal(null);
    }
    // eslint-disable-next-line
  }, [location.pathname, user.role]);

  useEffect(() => {
    if (location.hash !== '') {
      setTimeout(() => {
        const id = location.hash.replace('#', '');
        const element = document.getElementById(id);
        if (element) {
          element.scrollIntoView();
        }
      }, 0);
      return;
    }
    if (!location.state?.scrollToTabs) {
      window.scrollTo(0, 0);
    }
  }, [location.pathname, location.hash, location.state]);

  const routes: IRoutes[] = useMemo(() => routesPath[user.role], [user.role]);

  const isValidRootParam = useMemo(
    () => !![...routes, ...categoriesRoute].filter((i) => i.path === location.pathname).length,
    [location.pathname, routes]
  );

  const isCategoriesRoute = useMemo(
    () => !!categoriesRoute.filter((i) => i.path === location.pathname).length,
    [location.pathname]
  );

  if (user.isAuthorized === null) return <Spinner />;

  return (
    <MuiPickersUtilsProvider utils={MomentUtils}>
      <Helmet titleTemplate="%s - Kivity" defaultTitle="Kivity">
        <meta
          name="description"
          content="Kivity is an online marketplace that helps parents discover and enroll in local after school activities."
        />
        <meta name="locale" content="en_US" />
        <meta name="type" content="website" />
        <meta name="site_name" content="Kivity.com" />
      </Helmet>
      <Header />
      <PageCart />
      <Suspense fallback={<Spinner />}>
        <Switch>
          {!isValidRootParam && (
            <Route
              path="/:slug"
              exact
              component={user.role === Roles.admin ? AdminBusinessProfileView : BusinessProfileView}
            />
          )}
          {isCategoriesRoute && <Route path="/:slug" component={Search} />}
          {routes.map(({ path, component, exact = false }) => (
            <Route key={path} path={path} component={component} exact={exact} />
          ))}
          <Redirect to="/404" />
        </Switch>
        {footerIsTransparent ? <TransparentFooter /> : <Footer />}
      </Suspense>
    </MuiPickersUtilsProvider>
  );
};

export default App;
