import React, { FunctionComponent, useEffect } from 'react';
// @material-ui
import { Stepper as StepperMUI, Step, StepLabel, Typography } from '@material-ui/core';
// types
import { Props } from './types';
// styles
import { useStyles, useStepStyles, useStylesStepper, Connector, useStylesStepIcon } from './styles';

export const Stepper: FunctionComponent<Props> = ({
  activeStep,
  steps,
  errors = [],
  setStep,
  startErrorsPulse,
}) => {
  const classes = useStyles();
  const classesStep = useStepStyles();
  const classesStepper = useStylesStepper();
  const classesStepIcon = useStylesStepIcon({ startErrorsPulse });

  useEffect(() => {
    if (startErrorsPulse) {
      const componentWithError = document.getElementsByClassName('Mui-error')[0];
      componentWithError.scrollIntoView({ block: 'center', behavior: 'smooth' });
    }
  }, [startErrorsPulse]);

  return (
    <StepperMUI
      activeStep={activeStep}
      alternativeLabel
      classes={classesStepper}
      connector={<Connector />}
    >
      {steps.map((label, index) => (
        <Step
          key={label}
          onClick={() => {
            setStep(index);
          }}
          classes={classesStep}
        >
          <StepLabel StepIconProps={{ classes: classesStepIcon }} error={errors[index]}>
            <Typography variant="body2" className={classes.label}>
              {label}
            </Typography>
          </StepLabel>
        </Step>
      ))}
    </StepperMUI>
  );
};
