import { createStyles, makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) =>
  createStyles({
    wrapper: {
      width: '685px',
      paddingBottom: '42px',
      [theme.breakpoints.only('xs')]: {
        width: '100%',
      },
    },
    heading: {
      margin: '32px 0 16px',
      textAlign: 'center',
      fontWeight: 600,
    },
    body: {
      fontWeight: 600,
      textAlign: 'center',
    },
    buttonContainer: {
      marginTop: '32px',
    },
    checkboxWrap: {
      display: 'flex',
      flexDirection: 'column',
      margin: '32px 0 0 0',
    },
  })
);
