import React, { FunctionComponent, memo } from 'react';
// material-ui
import { FormControlLabel, Radio as RadioUI } from '@material-ui/core';
// utils
import cx from 'classnames';
// styles
import { useBoxStyles, useBoxLabelStyles } from 'components/commonBoxStyles';
// types
import { BoxType } from 'components/types';
import { Props } from './types';

export const Radio: FunctionComponent<Props> = memo<Props>(
  ({ sizePx = 24, label, checked = false, ...rest }) => {
    const classes = useBoxStyles({ sizePx, type: BoxType.Radio });
    const classesLabel = useBoxLabelStyles({ checked });
    return (
      <FormControlLabel
        control={
          <RadioUI
            {...rest}
            color="primary"
            className={classes.root}
            disableRipple
            checkedIcon={<span className={cx(classes.icon, classes.checkedIcon)} />}
            icon={<span className={classes.icon} />}
          />
        }
        classes={classesLabel}
        label={label}
      />
    );
  }
);
