export interface IReview {
  created_at: string;
  id: string;
  rating: number;
  service: string;
  parent_id: string | null;
  text: string;
  user_ava: string | null;
  user_name: string;
  count: number;
  isAdmin: boolean;
  isLikedByMe: boolean;
  likeCount: number;
}
export interface PaginationReviews {
  data: IReview[];
  count: number;
  total: number;
  page: number;
  pageCount: number;
}

export enum ReviewServiceName {
  google = 'GOOGLE',
  yelp = 'YELP',
}

export interface IReviewService {
  name: ReviewServiceName;
  url: string;
  count: number;
  rating: number;
}

export interface IReviewsResponse {
  reviews: PaginationReviews;
  services: IReviewService[];
}

export interface IBusinessRating {
  average: string;
  external: [number, number];
  internal: [number, number];
}
