import React, { FunctionComponent, useCallback, useMemo } from 'react';
import moment from 'moment';
// @material-ui
import { Typography, Select, Grid, MenuItem } from '@material-ui/core';
// styles
import { useStyles, useStylesSelect, useStylesMenuItem } from './styles';
// types
import { Props } from './types';

const currentYear = new Date().getFullYear();
const fromMonth = new Date(currentYear - 90, 0);
const toMonth = new Date(currentYear + 10, 11);

export const YearMonthForm: FunctionComponent<Props> = ({ date, onChange }) => {
  const classes = useStyles();
  const classesSelect = useStylesSelect();
  const classesMenuItem = useStylesMenuItem();
  const years = [];

  for (let i = fromMonth.getFullYear(); i <= toMonth.getFullYear(); i += 1) {
    years.push(i);
  }

  const currentMonth = useMemo(() => moment(date).format('MMMM'), [date]);

  const handleChange = useCallback(
    (event: React.ChangeEvent<{ name?: string; value: unknown }>) => {
      onChange(event.target.value as number);
    },
    [onChange]
  );

  return (
    <Grid className="DayPicker-Caption">
      <Grid container alignItems="center" justify="center" className={classes.wrap}>
        <Typography variant="body1" component="span" className={classes.month}>
          {currentMonth},
        </Typography>
        <Select
          value={date.getFullYear()}
          onChange={handleChange}
          name="year"
          classes={classesSelect}
          variant="standard"
          disableUnderline
          MenuProps={{
            anchorOrigin: { vertical: 'bottom', horizontal: 'left' },
            transformOrigin: { vertical: 'top', horizontal: 'left' },
            getContentAnchorEl: null,
            classes: { list: classes.menuList },
          }}
        >
          {years.map((item) => (
            <MenuItem key={item} value={item} classes={classesMenuItem}>
              {item}
            </MenuItem>
          ))}
        </Select>
      </Grid>
    </Grid>
  );
};
