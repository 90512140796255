import React, { FunctionComponent, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers';
import debounce from 'lodash.debounce';
// material-ui components
import { Grid, Typography } from '@material-ui/core';
// components
import { Autocomplete, Button } from 'components';
// sections
import { ChildForm, ParentForm } from './sections';
// modals
import { ErrorModal } from 'modals/ErrorModal';
import { SuccessfullyModal } from 'modals/SuccessfullyModal';
// custom hooks
import { useStore } from 'hooks/useStore';
import { useModalSetter } from 'hooks/useModal';
// helpers
import {
  createParentValues,
  defaultValues,
  filterOptions,
  parentDefaultValues,
  parseString,
  successModalProps,
  createChildrenFormData,
} from './helpers';
// api
import Api from 'utils/api';
// validation
import { Schema, OnlyChildrenSchema } from './validation';
// types
import { IClass, IParentProfile } from 'types';
import { Roles } from 'utils/constants';
import { IAddStudentForm, IAddStudentFormData, IParent, IChildren } from './types';
// styles
import useStyles from './styles';
import { ReactComponent as SandsClock } from 'assets/svg/sands-clock.svg';

export const AddStudentForm: FunctionComponent<IAddStudentForm> = ({
  classId,
  addStudent,
  handleMutateEnrollmentList,
  handleMutateStudents,
  waitlistData,
}) => {
  const classes = useStyles();
  const [parents, setParents] = useState<IParentProfile[]>([]);
  const [parentPhone, setParentPhone] = useState();
  const [parentFormIsOpened, setParentFormIsOpened] = useState(false);
  const [pending, setPending] = useState(false);
  const [currentChildId, setCurrentChildId] = useState();
  const [buttonsIsDisabled, setButtonsIsDisabled] = useState(false);
  const { setModal, modals } = useModalSetter();
  const { user } = useStore();

  const currentParent = useMemo(() => parents.find(({ phone }) => parentPhone === phone), [
    parents,
    parentPhone,
  ]);

  const form = useForm<IAddStudentFormData>({
    resolver: currentParent
      ? yupResolver(OnlyChildrenSchema)
      : currentParent && currentChildId
      ? undefined
      : yupResolver(Schema),
    defaultValues,
  });

  useEffect(() => {
    if (currentParent && parentPhone === currentParent.phone && parentFormIsOpened) {
      const parentValues = createParentValues(currentParent);
      form.setValue('parent', parentValues);
    } else if (!currentParent && parentFormIsOpened) {
      form.setValue('parent', parentDefaultValues);
    }
    // eslint-disable-next-line
  }, [currentParent, parentPhone, parentFormIsOpened]);

  useEffect(() => {
    if (modals.length > 1) {
      setButtonsIsDisabled(true);
    } else if (modals.length <= 1) {
      setButtonsIsDisabled(false);
    }
  }, [modals]);

  const delayedParents = useRef(
    debounce(async (value: string) => {
      try {
        const response: IParentProfile[] = await Api.businessParentProfiles.getProfiles({
          params: { search: value },
        });
        setParents(response);
      } catch (err) {
        setModal(<ErrorModal message={err?.data?.message?.toString()} />);
      }
      setPending(false);
    }, 300)
  ).current;

  const handleChange = useCallback(
    (value: string) => {
      form.clearErrors('parentPhone');
      form.setValue('parentPhone', value);
      setParentPhone(value?.split(',', 1)[0] || '');
      setPending(true);
      delayedParents(value?.split(',', 1)[0] || '');
    },
    // eslint-disable-next-line
    [delayedParents]
  );

  useEffect(
    () => {
      handleChange('');
    },
    // eslint-disable-next-line
    []
  );

  const handleSearch = debounce(handleChange, 300);

  const options = useMemo(
    () =>
      parents.map(({ phone, first_name, last_name }) => ({
        label: `${phone}, ${first_name} ${last_name}`,
        value: +parseString(phone),
        phone,
      })),
    [parents]
  );

  const handleOpenParentForm = () => {
    setParentFormIsOpened(true);
  };

  const handleSelectChange = useCallback(
    (_value, label?: string) => {
      form.reset({
        parentPhone: label,
        parent: {
          first_name: '',
          last_name: '',
          address: '',
          zip: '',
          city: '',
          email: '',
          phone: '',
        },
      });
      handleChange(label || '');
    },
    // eslint-disable-next-line
    [handleChange]
  );

  const handleSetChildId = (id?: number | null) => {
    setCurrentChildId(id);
  };

  const onSubmit = async (data: IAddStudentFormData) => {
    setButtonsIsDisabled(true);
    const registerChild =
      user.role === Roles.business
        ? Api.classes.registerChildren
        : Api.admin.classes.createChildren;
    try {
      let childId: number = currentChildId;
      let children: IChildren | undefined = currentParent?.children.find(
        ({ id }) => id === childId
      );
      let parent: IParent | undefined = currentParent;

      if (currentParent && !childId) {
        const newChild: IChildren = await Api.parentChild.createChild({
          id: currentParent.id,
          data: createChildrenFormData(data.children as IChildren),
        });
        childId = newChild.id;
        children = newChild;
      }

      if (!currentParent && !childId) {
        const newParent: IParent = await Api.parentProfiles.createProfile({ data: data.parent });
        const newChild: IChildren = await Api.parentChild.createChild({
          id: newParent.id,
          data: createChildrenFormData(data.children as IChildren),
        });
        childId = newChild.id;
        children = newChild;
        parent = newParent;
      }

      if (waitlistData?.find(({ id }: any) => id === childId)) {
        setModal(
          <SuccessfullyModal
            icon={<SandsClock />}
            title="There is such student in a waitlist already."
            text="Please use action button to move this student"
          />
        );

        return;
      }

      if (addStudent && parent && children) {
        await addStudent({ ...children, parent_profile: parent });
      } else if (classId) {
        const response: IClass = await registerChild({ id: classId, data: { ids: [childId] } });
        handleMutateEnrollmentList && handleMutateEnrollmentList(response.enrollmentList);
      } else {
        handleMutateStudents && handleMutateStudents();
      }
      setModal(<SuccessfullyModal {...successModalProps} />);
    } catch (err) {
      setModal(<ErrorModal message={err?.data?.message?.toString()} />);
    }
  };

  const handleCancel = () => setModal(null);

  return (
    <FormProvider {...form}>
      <Grid
        container
        direction="column"
        alignItems="center"
        component="form"
        onSubmit={form.handleSubmit(onSubmit)}
        className={classes.wrapper}
      >
        <Grid container>
          <Typography variant="h5" className={classes.heading}>
            Add student
          </Typography>
        </Grid>
        <Grid container className={classes.textContainer}>
          <Typography variant="body1">
            Add your student to make sure you have the most up to date enrollment.
          </Typography>
        </Grid>
        <Grid
          container
          justify="space-between"
          alignItems="center"
          className={classes.contentContainer}
        >
          <Grid
            container
            item
            xs={12}
            sm={parentFormIsOpened ? 12 : 8}
            md={parentFormIsOpened ? 12 : 8}
            lg={parentFormIsOpened ? 12 : 8}
          >
            <Autocomplete
              name="parentPhone"
              pending={pending}
              options={options}
              filterOptions={filterOptions}
              errorField={form.errors.parentPhone}
              placeholder="Search by parent name or phone number"
              handleInputChange={handleSearch}
              handleSelectChange={handleSelectChange}
            />
          </Grid>
          {!parentFormIsOpened && (
            <Grid
              container
              justify="flex-end"
              item
              xs={12}
              sm={4}
              md={4}
              lg={4}
              className={classes.searchButtonContainer}
            >
              <Button label="Search" onClick={handleOpenParentForm} />
            </Grid>
          )}
        </Grid>
        {parentFormIsOpened && (
          <>
            <ParentForm isReadOnly={!!currentParent} />
            <ChildForm
              parent={currentParent}
              handleCancel={handleCancel}
              currentChildId={currentChildId}
              handleSetChildId={handleSetChildId}
              buttonsIsDisabled={buttonsIsDisabled}
            />
          </>
        )}
      </Grid>
    </FormProvider>
  );
};
