import React, { forwardRef, FunctionComponent, Ref } from 'react';
// material-ui components
import { Grid } from '@material-ui/core';
// types
import { Props } from './types';
// icons
import { Close } from 'assets/icons';
// styles
import useStyles from './styles';

export const ModalContent: FunctionComponent<Props> = forwardRef(
  ({ children, close }, ref: Ref<HTMLDivElement>) => {
    const classes = useStyles();

    return (
      <Grid container tabIndex={-1} ref={ref} className={classes.wrap}>
        <Grid container>
          <Grid container justify="flex-end" className={classes.close}>
            <Grid item onClick={close}>
              <Close />
            </Grid>
          </Grid>
          {children}
        </Grid>
      </Grid>
    );
  }
);
