import React, { useMemo, useCallback, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
// material-ui components
import { Grid, Typography, Tabs, Tab, Hidden } from '@material-ui/core';
// controllers
import { SelectController } from 'controllers';
// custom hooks
import useRequest from 'hooks/useRequest';
import { useModalSetter } from 'hooks/useModal';
import { createSession, setNotPayedData, openSessionUrl } from 'hooks/stripeHooks/helpers';
// api
import api from 'utils/api';
// types
import { PaginationClassList, IPromote } from 'types';
import { StripeProducts } from 'hooks/stripeHooks/types';
import { GetAxiosResponse } from 'utils/axios.instance';
import { StatusProps } from 'components/Badges/Badge/types';
import { IAvailableDates, IFormData, IPromote as IPromoteProps } from './types';
// components
import { Button, Spinner, TabPanel } from 'components';
import { PromoteItem } from './components';
// modals
import { ErrorModal, PromoteLimitReached } from 'modals';
// helpers
import { checkPromoteLimitReachedMessage, createCheckFormData } from './helpers';
// validations
import { Schema } from './validation';
// styles
import useStyles from './styles';

export const Promote = ({ defaultId, pathAfterClose }: IPromoteProps) => {
  const classes = useStyles();

  const history = useHistory();

  const { setModal } = useModalSetter();

  const [tabValue, setTabValue] = useState(0);

  const [isLoading, setIsLoading] = useState(false);

  const form = useForm<{ classId: number }>({
    resolver: yupResolver(Schema),
    defaultValues: { classId: defaultId },
  });

  const fetcher: GetAxiosResponse = () =>
    api.classes.getBusinessClasses({
      params: { page: 1, limit: 999, statuses: ['PUBLISHED', 'OPENED'] },
    });

  const { data: list } = useRequest<PaginationClassList>('/promote-classes', fetcher);

  const { data: promotions = [] } = useRequest<IPromote[]>(
    '/promotions',
    api.promotions.getPromotions
  );

  const datesFetcher: GetAxiosResponse = () =>
    api.promotions.getAvailableDate({ id: form.watch('classId') });

  const { data: availableDates } = useRequest<IAvailableDates>(
    `/platinum-date/${form.watch('classId')}`,
    datesFetcher
  );

  const classList = useMemo(
    () =>
      list?.data
        ?.filter(
          ({ status }) =>
            status !== StatusProps.expired &&
            status !== StatusProps.draft &&
            status !== StatusProps.active
        )
        .map(({ id, title }) => ({ label: title, value: id })) || [],
    [list]
  );

  const handleCloseModal = () => {
    setModal(null);
    pathAfterClose && history.push(pathAfterClose);
  };

  const onSubmit = useCallback(
    async (formData: IFormData) => {
      let id: number;
      await form.handleSubmit(({ classId }) => {
        id = classId;
      })();
      const selectedClass = list?.data?.find((item) => item.id === id);
      if (!selectedClass) return;

      const buyPromote = async () => {
        try {
          setIsLoading(true);
          const dataForCheck = createCheckFormData(formData);
          await api.promotions.checkPromotionData({
            data: dataForCheck,
            id: form.watch('classId'),
          });

          const selectedRate = promotions
            .find((p) => p.id === dataForCheck.promotion_id)
            ?.costs.find((i) => i.rate.id === dataForCheck.rate_id) ?? {
            rate: { monthMultiplier: 1 },
          };

          const durationInMonth = dataForCheck.duration_variant * selectedRate.rate.monthMultiplier;

          const data = {
            rateId: dataForCheck.rate_id,
            promotionId: dataForCheck.promotion_id,
            durationVariant: dataForCheck.duration_variant,
            totalSum: dataForCheck.total_sum,
            courseId: selectedClass.id,
            startDate: formData.start_date,
            endDate: moment(formData.start_date).add(durationInMonth, 'month').toString(),
          };

          const session = await createSession({
            type: StripeProducts.CourseAd,
            data,
          });
          setNotPayedData(data);
          openSessionUrl(session);
        } catch (err) {
          const isLimitReachedMessage = checkPromoteLimitReachedMessage(
            err?.data?.message?.toString()
          );
          if (isLimitReachedMessage) {
            const businessName = list?.data?.find(
              ({ id: classId }) => classId === form.watch('classId')
            )?.title;
            if (businessName) {
              setModal(<PromoteLimitReached businessName={businessName} />);
            }
          } else {
            setModal(<ErrorModal message={err?.data?.message?.toString()} />);
          }
        } finally {
          setIsLoading(false);
        }
      };

      await buyPromote();

      // const amount = calculateMonthsAmount(formData, promotions);
      // const formatPromoteEndDate = moment(formData.start_date)
      //   .add(amount, 'months')
      //   .format('DD-MMM YYYY');
      // const formatClassEndDate = moment(selectedClass.endDate).format('DD-MMM YYYY');

      // if (moment(formatPromoteEndDate).isAfter(moment(formatClassEndDate))) {
      //   setModal(
      //     <PromoteDatesCheck
      //       classEndDate={formatClassEndDate}
      //       promoteEndDate={formatPromoteEndDate}
      //       handleSubmit={buyPromote}
      //     />
      //   );
      // } else {
      //   await buyPromote();
      // }
    },
    [list, form, setModal, promotions]
  );

  const selectPlaceholder = useMemo(() => {
    if (!list) return 'Loading';
    if (list && classList.length === 0) return 'Now there are no classes';
    return 'Choose a class to promote';
  }, [classList.length, list]);

  const registrationDate = useMemo(
    () =>
      list?.data?.find((item) => item.id === form.watch('classId'))?.registrationStartDate || null,
    [form, list]
  );

  const handleChange = (_event: React.ChangeEvent<{}>, newValue: number) => {
    setTabValue(newValue);
  };

  return (
    <FormProvider {...form}>
      {isLoading && <Spinner backdrop />}
      <Grid container direction="column" className={classes.wrapper}>
        <Grid container className={classes.container}>
          <Grid container>
            <Typography variant="h5" className={classes.heading}>
              Start Advertising Your Class
            </Typography>
          </Grid>
          <Grid container>
            <Typography variant="subtitle2">
              Attract more customers with Kivity Ads. Reach people while they’re searching with
              sponsored results.
            </Typography>
          </Grid>
          <Grid container className={classes.selectContainer}>
            <SelectController
              fullWidth
              name="classId"
              required={classList.length > 0}
              disabled={classList.length === 0}
              placeholder={selectPlaceholder}
              options={classList}
              errorField={form.errors.classId}
            />
          </Grid>
          <Grid container alignItems="center" justify="center" className={classes.itemsContainer}>
            {promotions.length ? (
              <>
                <Hidden lgUp>
                  <Tabs
                    value={tabValue}
                    aria-label="search-type"
                    onChange={handleChange}
                    centered
                    variant="fullWidth"
                    classes={{ indicator: classes.tabIndicator, root: classes.tabsRoot }}
                  >
                    <Tab disableRipple label="Platinum" classes={{ root: classes.tabRoot }} />
                    <Tab disableRipple label="Elite" classes={{ root: classes.tabRoot }} />
                    <Tab disableRipple label="Premium" classes={{ root: classes.tabRoot }} />
                  </Tabs>
                  <Grid className={classes.tabs} />
                  <Grid container justify="center">
                    <TabPanel index={0} value={tabValue}>
                      <PromoteItem
                        isLeftSection
                        data={promotions[1]}
                        onSubmit={onSubmit}
                        registrationDate={registrationDate}
                        availableDay={availableDates?.Platinum}
                      />
                    </TabPanel>
                    <TabPanel index={1} value={tabValue}>
                      <PromoteItem
                        isCentered
                        data={promotions[0]}
                        onSubmit={onSubmit}
                        registrationDate={registrationDate}
                        availableDay={availableDates?.Elite}
                      />
                    </TabPanel>
                    <TabPanel index={2} value={tabValue}>
                      <PromoteItem
                        data={promotions[2]}
                        onSubmit={onSubmit}
                        registrationDate={registrationDate}
                        availableDay={availableDates?.Premium}
                      />
                    </TabPanel>
                  </Grid>
                </Hidden>
                <Hidden mdDown>
                  <PromoteItem
                    isLeftSection
                    data={promotions[1]}
                    onSubmit={onSubmit}
                    registrationDate={registrationDate}
                    availableDay={availableDates?.Platinum}
                  />
                  <PromoteItem
                    isCentered
                    data={promotions[0]}
                    onSubmit={onSubmit}
                    registrationDate={registrationDate}
                    availableDay={availableDates?.Elite}
                  />
                  <PromoteItem
                    data={promotions[2]}
                    onSubmit={onSubmit}
                    registrationDate={registrationDate}
                    availableDay={availableDates?.Premium}
                  />
                </Hidden>
              </>
            ) : (
              <Grid container className={classes.spinnerContainer}>
                <Spinner isFullWidth />
              </Grid>
            )}
          </Grid>
        </Grid>
        <Grid container justify="center" className={classes.buttonContainer}>
          <Button
            stringButton
            type="button"
            variant="text"
            label="I'll do this later"
            onClick={handleCloseModal}
          />
        </Grid>
      </Grid>
    </FormProvider>
  );
};
