import React, { FunctionComponent, useMemo, memo } from 'react';
import { Helmet } from 'react-helmet';
// types
import { ISeo } from './types';

export const Seo: FunctionComponent<ISeo> = memo(({ title, description }) => {
  const meta = useMemo(() => {
    const metaArray: any = [
      {
        property: 'og:title',
        content: `${title} - Kivity`,
      },
    ];

    if (description) {
      metaArray.push({
        name: 'description',
        content: description,
      });
      metaArray.push({
        property: 'og:description',
        content: description,
      });
    }

    return metaArray;
  }, [title, description]);

  return <Helmet title={title} meta={meta} />;
});
