import React, { FunctionComponent, memo } from 'react';

export const TopBadge: FunctionComponent = memo(() => (
  <svg width="74" height="66" viewBox="0 0 74 66" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M40.8523 0H74L0 66V36.9176L40.8523 0Z" fill="#FF8A00" />
    <path
      d="M19.4695 26.3087L20.8883 27.8732L18.6695 29.9279L24.7744 36.66L23.1543 38.1602L17.0494 31.4281L14.8423 33.4719L13.4236 31.9073L19.4695 26.3087ZM33.188 26.8846C33.1108 28.4825 32.4774 29.8322 31.2878 30.9338C30.0981 32.0354 28.7124 32.5555 27.1304 32.4941C25.5491 32.4175 24.2103 31.7747 23.1139 30.5658C22.0176 29.3568 21.5042 27.957 21.5736 26.3664C21.6437 24.7606 22.2735 23.4069 23.4631 22.3053C24.6527 21.2037 26.0421 20.6876 27.6313 20.7569C29.2132 20.8184 30.5524 21.4535 31.6487 22.6625C32.745 23.8714 33.2581 25.2788 33.188 26.8846ZM27.2588 30.3254C28.2261 30.3601 29.0854 30.0295 29.8368 29.3337C30.5881 28.638 30.9888 27.8017 31.039 26.8249C31.0819 25.8403 30.7451 24.9528 30.0286 24.1627C29.3121 23.3725 28.4666 22.9562 27.4921 22.9137C26.5176 22.8712 25.6547 23.1978 24.9034 23.8935C24.152 24.5893 23.7549 25.4295 23.7119 26.4142C23.669 27.3988 24.0057 28.2863 24.7223 29.0764C25.4388 29.8666 26.2843 30.2829 27.2588 30.3254ZM31.9134 14.7855C32.7039 14.0535 33.6128 13.7061 34.6401 13.7433C35.6673 13.7806 36.5357 14.1903 37.245 14.9726C37.9544 15.7548 38.2827 16.6646 38.2299 17.702C38.1772 18.7394 37.7555 19.6241 36.9651 20.3561L35.5211 21.6932L37.9932 24.4192L36.3731 25.9195L28.8494 17.6228L31.9134 14.7855ZM35.5786 18.8271C35.9229 18.5082 36.1011 18.1252 36.1131 17.678C36.1179 17.2228 35.9591 16.8175 35.6367 16.4619C35.3071 16.0984 34.9213 15.9033 34.4794 15.8764C34.0375 15.8495 33.6443 15.9955 33.3 16.3144L31.856 17.6516L34.1346 20.1643L35.5786 18.8271Z"
      fill="white"
    />
  </svg>
));
