import React, { FunctionComponent, useCallback, useMemo, useRef, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers';
import debounce from 'lodash.debounce';
// material-ui components
import { Grid, Typography } from '@material-ui/core';
// components
import { Autocomplete, Spinner } from 'components';
import { PromoteItem } from './components';
// controllers
import { SelectController } from 'controllers';
// custom hooks
import useRequest from 'hooks/useRequest';
import { useModalSetter } from 'hooks/useModal';
// modals
import { ErrorModal } from '../ErrorModal';
import { SuccessfullyModal } from '../SuccessfullyModal';
import { PromoteDatesCheck } from '../PromoteDatesCheck';
// api
import api from 'utils/api';
// validations
import { Schema } from './validation';
// types
import { IBusinessListItem, PaginationBusinessList, PaginationClassList } from 'types';
import { GetAxiosResponse } from 'utils/axios.instance';
import { IFormData, IAdminPromote } from './types';
// styles
import useStyles from './styles';
import moment from 'moment';

export const AdminPromote: FunctionComponent<IAdminPromote> = ({ updateTableData }) => {
  const classes = useStyles();
  const { setModal } = useModalSetter();
  const [businessList, setBusinessList] = useState<IBusinessListItem[]>([]);
  const [pending, setPending] = useState(false);
  const [currentBusinessId, setCurrentBusinessId] = useState();

  const form = useForm({ resolver: yupResolver(Schema) });

  const businessClassesFetcher: GetAxiosResponse = () =>
    api.businessProfiles.businessCoursesById({
      id: currentBusinessId,
      params: { page: 1, limit: 99999, statuses: ['PUBLISHED', 'OPENED'] },
    });

  const { data: businessClassList } = useRequest<PaginationClassList>(
    `/business-classes/${currentBusinessId}`,
    businessClassesFetcher,
    { shouldRetryOnError: false }
  );

  const { data: promotions } = useRequest('/promotions', api.promotions.getPromotions);

  const businessOptions = useMemo(
    () => businessList.map(({ id, company_name }) => ({ label: company_name, value: id })) || [],
    [businessList]
  );

  const delayedBusiness = useRef(
    debounce(async (value: string) => {
      const response: PaginationBusinessList = await api.businessProfiles.getProfilesList({
        params: { search: value, page: 1, limit: 99999 },
      });
      setBusinessList(response.data);
      setPending(false);
    }, 300)
  ).current;

  const onSubmit = useCallback(
    async (formData: IFormData) => {
      let formClassId: number | undefined = undefined;
      let formBusinessName: string | undefined = undefined;
      await form.handleSubmit(({ classId, businessName }) => {
        formClassId = classId;
        formBusinessName = businessName;
      })();
      if (!formClassId || !formBusinessName) return;
      const formDataForSend = {
        ...formData,
        course: {
          id: formClassId,
        },
      };

      const selectedClass = businessClassList?.data?.find((item) => item.id === formClassId);
      const formatPromoteEndDate = moment(formData.end_date).format('DD-MMM YYYY');
      const formatClassEndDate = moment(selectedClass?.endDate).format('DD-MMM YYYY');

      const buyPromote = async () => {
        try {
          await api.admin.promotions.promoteClass({ data: formDataForSend });
          updateTableData && updateTableData();
          setModal(
            <SuccessfullyModal title="Done!" text="Class has been successfully promoted." />
          );
        } catch (err) {
          setModal(<ErrorModal message={err?.data?.message?.toString()} />);
        }
      };
      if (moment(formatPromoteEndDate).isAfter(moment(formatClassEndDate))) {
        setModal(
          <PromoteDatesCheck
            classEndDate={formatClassEndDate}
            promoteEndDate={formatPromoteEndDate}
            handleSubmit={buyPromote}
          />
        );
      } else {
        await buyPromote();
      }
    },
    [form, setModal, updateTableData, businessClassList]
  );

  const handleChange = useCallback(
    (value: string) => {
      form.clearErrors('businessName');
      form.setValue('businessName', value);
      form.setValue('classId', '');
      setPending(true);
      delayedBusiness(value || 'no business');
    },
    // eslint-disable-next-line
    [delayedBusiness]
  );

  const handleSearch = debounce(handleChange, 300);

  const handleSelectChange = useCallback(
    (value?: number | string, label?: string) => {
      setCurrentBusinessId(value);
      handleChange(label || '');
      form.reset({ businessName: label });
    },
    // eslint-disable-next-line
    [handleChange]
  );

  const classList = useMemo(
    () => businessClassList?.data?.map(({ title, id }) => ({ label: title, value: id })) || [],
    [businessClassList]
  );

  const classSelectPlaceholder = useMemo(
    () => (!!classList.length ? 'Choose your class for promotion' : 'Now there are no classes'),
    [classList]
  );

  return (
    <FormProvider {...form}>
      <Grid container direction="column" className={classes.wrapper}>
        <Grid container className={classes.container}>
          <Grid container>
            <Typography variant="h5" className={classes.heading}>
              Promote the Class
            </Typography>
          </Grid>
          <Grid container>
            <Typography variant="subtitle2">
              Please select business You want to promote class for
            </Typography>
          </Grid>
          <Grid container className={classes.selectContainer}>
            <Autocomplete
              name="businessName"
              pending={pending}
              options={businessOptions}
              errorField={form.errors.businessName}
              placeholder="Choose business for promotion"
              handleInputChange={handleSearch}
              handleSelectChange={handleSelectChange}
            />
          </Grid>
          <Grid container>
            <Typography variant="subtitle2">Please select class You want to promote</Typography>
          </Grid>
          <Grid container className={classes.selectContainer}>
            <SelectController
              fullWidth
              name="classId"
              options={classList}
              required={classList.length > 0}
              // disabled={classList.length === 0}
              placeholder={classSelectPlaceholder}
              errorField={form.errors.classId}
            />
          </Grid>
          <Grid container alignItems="center">
            {promotions ? (
              <>
                <PromoteItem isLeftSection data={promotions[1]} onSubmit={onSubmit} />
                <PromoteItem isCentered data={promotions[0]} onSubmit={onSubmit} />
                <PromoteItem data={promotions[2]} onSubmit={onSubmit} />
              </>
            ) : (
              <Grid container className={classes.spinnerContainer}>
                <Spinner isFullWidth />
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>
    </FormProvider>
  );
};
