import React, { FunctionComponent, MouseEvent, memo } from 'react';

interface IVisibilityOff {
  onClick?: () => void;
  onMouseDown?: (event: MouseEvent) => void;
}

export const VisibilityOff: FunctionComponent<IVisibilityOff> = memo(({ onClick, onMouseDown }) => (
  <svg
    width="22"
    height="22"
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    onClick={onClick}
    onMouseDown={onMouseDown}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.16653 5.1016L9.72346 9.65774C10.0536 9.33879 10.5038 9.14243 11.0002 9.14243C12.0127 9.14243 12.8335 9.95961 12.8335 10.9677C12.8335 11.4649 12.6337 11.9158 12.3098 12.245L16.8338 16.7689C17.1918 17.1268 17.1918 17.7072 16.8338 18.0652C16.4758 18.4232 15.8954 18.4232 15.5374 18.0652L14.7124 17.2411C13.5346 17.9232 12.2974 18.2686 11.0002 18.2686C7.71336 18.2686 4.81138 16.0515 2.28625 11.7574L2.09275 11.4229L1.8335 10.9677L2.09275 10.5124C2.86404 9.15788 3.67101 7.99982 4.51391 7.0423L3.87017 6.39796C3.51219 6.03998 3.51219 5.45958 3.87017 5.1016C4.22815 4.74362 4.80855 4.74362 5.16653 5.1016ZM4.16708 10.605L3.94672 10.9677L4.13742 11.2823C6.30242 14.7937 8.59315 16.4433 11.0002 16.4433C11.8003 16.4433 12.5872 16.2614 13.3606 15.8899L9.89686 12.4255C9.75833 12.3214 9.63511 12.1982 9.53114 12.0598L5.80954 8.33627C5.25435 8.97918 4.70681 9.73434 4.16708 10.605ZM11.0002 3.66675C14.287 3.66675 17.189 5.88383 19.7141 10.178L19.9076 10.5124L20.1668 10.9677L19.7188 11.7493C19.0141 12.9487 18.28 13.9862 17.5164 14.8589L16.222 13.5645C16.8425 12.8408 17.4531 11.9767 18.0537 10.9678C15.8326 7.24016 13.478 5.49197 11.0002 5.49197C10.2153 5.49197 9.44269 5.6674 8.68283 6.02561L7.32836 4.67088C8.49404 4.00423 9.71782 3.66675 11.0002 3.66675Z"
      fill="#B9C1CC"
    />
  </svg>
));
