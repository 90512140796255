import React, { FunctionComponent, memo } from 'react';
// material-ui
import { Radio as RadioMUI, FormControlLabel } from '@material-ui/core';
// types
import { Props } from './types';
// styles
import useStyles from './styles';

export const RadioGroupCategories: FunctionComponent<Props> = memo<Props>(
  ({ item, height = '40px', checked, handleClick }) => {
    const classes = useStyles({ height, checked });

    return (
      <FormControlLabel
        onChange={handleClick}
        control={<RadioMUI value={item.value} className={classes.radioBox} />}
        classes={{ root: classes.root, label: classes.label }}
        label={item.label}
        aria-describedby={`simple-popper-${item.value}`}
      />
    );
  }
);
