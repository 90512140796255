import { makeStyles, Theme } from '@material-ui/core/styles';
// interfaces
import { StylesProps } from './types';

export default makeStyles((theme: Theme) => ({
  root: ({ isPassword, multiline, invariableIcon, borderColor }: StylesProps) => ({
    height: multiline ? 'auto' : '50px',
    borderRadius: '5px',
    backgroundColor: theme.colors.grey6,
    '& .MuiInputAdornment-positionStart': {
      height: '100%',
      marginRight: 'unset',
      paddingLeft: '16px',
      display: invariableIcon ? 'flex' : 'none',
    },
    '& .MuiInputAdornment-positionEnd': {
      display: 'none',
      height: '100%',
      marginLeft: 'unset',
      paddingRight: '16px',
      '& svg': {
        cursor: isPassword ? 'pointer' : 'auto',
      },
    },
    '&:hover .MuiOutlinedInput-notchedOutline': {
      border: `1px solid ${theme.colors[borderColor]}`,
    },
  }),
  input: ({ centeredPlaceholder, textColor, withStartIcon, isCurrencyInput }: StylesProps) => ({
    ...theme.fontStyle.p16,
    minHeight: '26px',
    letterSpacing: isCurrencyInput ? 1 : theme.fontStyle.p16.letterSpacing,
    padding: '0 16px',
    color: theme.colors[textColor],
    justifyContent: centeredPlaceholder ? 'center' : 'flex-start',
    '&:focus, &:not(:placeholder-shown)': {
      padding: withStartIcon ? '0 12px' : '0 16px',
      justifyContent: 'flex-start',
    },
  }),
  multiline: ({ multiline }: StylesProps) => ({
    padding: multiline ? '13px 0' : 0,
  }),
  fieldProps: () => ({
    '& svg, & .MuiInputAdornment-positionStart, & .MuiInputAdornment-positionEnd': {
      display: 'flex',
    },
  }),
  focused: ({ borderColor }: StylesProps) => ({
    '& svg, & .MuiInputAdornment-positionStart, & .MuiInputAdornment-positionEnd': {
      display: 'flex',
    },
    '& fieldset': {
      borderColor: `${theme.colors[borderColor]} !important`,
      borderWidth: '1px !important',
    },
  }),
  adornedStart: {
    paddingLeft: 'unset',
  },
  adornedEnd: {
    paddingRight: 'unset',
  },
  notchedOutline: ({ notchedColor }: StylesProps) => ({
    borderColor: theme.colors[notchedColor],
  }),
  disabled: ({ isReadOnly }: StylesProps) => ({
    color: isReadOnly ? theme.colors.black : theme.colors.grey4,
    '& fieldset': {
      borderColor: `${theme.colors.grey6} !important`,
    },
  }),
  formControlRoot: ({ width = '360px', fullWidth, hidden, eventsIsDisabled }: StylesProps) => ({
    width: fullWidth ? '100%' : width,
    height: 'auto',
    display: hidden ? 'none' : 'flex',
    pointerEvents: eventsIsDisabled ? 'none' : 'auto',
  }),
  inputLabelRoot: ({
    centeredPlaceholder,
    placeholderColor,
    withStartIcon,
    invariableIcon,
    labelWidth,
    nonEmpty,
  }: StylesProps) => ({
    '&.MuiInputLabel-outlined': {
      ...theme.fontStyle.p16,
      display: 'flex',
      alignItems: 'center',
      width: invariableIcon ? 'calc(100% - 37px)' : '100%',
      height: '50px',
      padding: invariableIcon ? '0 12px' : '0 16px',
      color: theme.colors[placeholderColor],
      transform: invariableIcon ? 'translateX(37px) translateY(0)' : 'translateY(0)',
      justifyContent: centeredPlaceholder ? 'center' : 'flex-start',
      '&.Mui-focused': {
        padding: withStartIcon ? '0 12px' : '0 16px',
        color: theme.colors[placeholderColor],
      },
      '&.MuiInputLabel-shrink': {
        display: nonEmpty ? 'none' : 'flex',
        justifyContent: !nonEmpty && centeredPlaceholder ? 'center' : 'flex-start',
        transform: invariableIcon ? 'translateX(37px) translateY(0)' : 'translateY(0)',
        '&.Mui-focused': {
          width: labelWidth,
          justifyContent: 'flex-start',
          transform: withStartIcon ? 'translateX(37px) translateY(0)' : 'translateY(0)',
        },
      },
    },
  }),
  placeholder: {
    lineHeight: '25px',
    display: 'block',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },
  errorText: () => ({
    ...theme.fontStyle.error,
    textTransform: 'inherit',
  }),
}));
