import { createStyles, makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) =>
  createStyles({
    wrapper: {
      maxWidth: '500px',
      padding: '16px',
    },
    buttonsContainer: {
      marginTop: '30px',
      [theme.breakpoints.only('xs')]: {
        flexDirection: 'column',
        alignItems: 'center',
        marginTop: '16px',
      },
    },
    button: {
      marginRight: '24px',
      [theme.breakpoints.only('xs')]: {
        marginRight: 'unset',
      },
    },
    title: {
      textAlign: 'center',
    },
  })
);
