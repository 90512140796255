import React from 'react';
import { Link } from 'react-router-dom';
// material-ui components
import { Grid, Typography, Breadcrumbs as BreadcrumbsMUI } from '@material-ui/core';
// icons
import { ReactComponent as ChevronRight } from 'assets/icons/origin/Chevron Right.svg';
// types
import { TBreadcrumbs } from './types';
// styles
import useStyles from './styles';

const initialValues = {
  to: '/search',
  title: 'Classes',
};

export const Breadcrumbs: TBreadcrumbs = ({ title, secondStep = initialValues }) => {
  const classes = useStyles();

  return (
    <Grid container>
      <BreadcrumbsMUI
        aria-label="breadcrumb"
        separator={<ChevronRight />}
        classes={{ separator: classes.separator }}
      >
        <Link to="/" className={classes.link}>
          Home
        </Link>
        <Link to={secondStep.to} className={classes.link}>
          {secondStep.title}
        </Link>
        <Typography variant="body2" className={classes.title}>
          {title}
        </Typography>
      </BreadcrumbsMUI>
    </Grid>
  );
};
