import { createStyles, makeStyles } from '@material-ui/core/styles';
// types
import { StylesProps } from './types';
// styles
import { statusProps } from './helpers';

export default makeStyles((theme) =>
  createStyles({
    badge: ({ status }: StylesProps) => ({
      ...theme.fontStyle.caption,
      display: 'flex',
      justifyContent: 'center',
      padding: '5px 12px 4px',
      margin: '8px 4px 0 0',
      border: `1px solid ${theme.colors.grey4}`,
      borderRadius: '20px',
      pointerEvents: 'none',
      textTransform: 'capitalize',
      ...statusProps(theme, status),
    }),
  })
);
