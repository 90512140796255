import React, { FunctionComponent, MouseEvent, useMemo, memo } from 'react';
import cx from 'classnames';
// material-ui components
import {
  FormControl,
  InputLabel,
  OutlinedInput as MUOutlinedInput,
  InputAdornment,
  FormHelperText,
} from '@material-ui/core';
// icons
import { Asterics } from 'assets/icons';
import { ReactComponent as CheckGreenFilled } from 'assets/icons/origin/CheckGreenFilled.svg';
import { ReactComponent as AlertRedFilled } from 'assets/icons/origin/AlertRedFilled.svg';
// helpers
import { makeGenerateColor } from 'utils/helpers';
// styles
import useStyles from './styles';
// interfaces
import { InputProps } from './types';

export const OutlinedInput: FunctionComponent<InputProps> = memo(
  ({
    type,
    placeholder,
    centeredPlaceholder,
    startIcon,
    endIcon,
    isSubmitted,
    fullWidth,
    width,
    multiline = false,
    isPassword,
    disabled,
    required,
    invariableIcon = false,
    hidden = false,
    errorField,
    eventsIsDisabled,
    id,
    isCurrencyInput,
    isReadOnly,
    ...inputProps
  }) => {
    const generateColor = useMemo(
      () => makeGenerateColor({ isSubmitted, disabled, error: !!errorField }),
      [isSubmitted, disabled, errorField]
    );

    const labelWidth = useMemo(() => {
      if (invariableIcon && isSubmitted) return 'calc(100% - 74px)';
      if (invariableIcon || isSubmitted) return 'calc(100% - 37px)';
      return '100%';
    }, [invariableIcon, isSubmitted]);

    const placeholderColor = generateColor({ defaultColor: 'grey3', disabledColor: 'grey4' });
    const notchedColor = generateColor({ defaultColor: 'grey6', disabledColor: 'grey6' });
    const borderColor = generateColor({
      defaultColor: 'cyan',
      disabledColor: 'grey4',
      isReadOnly,
    });
    const textColor = generateColor({ defaultColor: 'black', disabledColor: 'grey4' });

    const isNonEmpty = Boolean(inputProps.value) || inputProps.value === 0;

    const classes = useStyles({
      nonEmpty: isNonEmpty,
      withStartIcon: Boolean(startIcon),
      invariableIcon: invariableIcon && Boolean(startIcon),
      centeredPlaceholder,
      placeholderColor,
      eventsIsDisabled,
      notchedColor,
      borderColor,
      textColor,
      isPassword,
      fullWidth,
      width,
      multiline,
      labelWidth,
      hidden,
      isCurrencyInput,
      isReadOnly,
    });

    const currentEndAdornment = useMemo(() => {
      if (isSubmitted && !errorField) {
        return <CheckGreenFilled />;
      }
      if (isSubmitted && !!errorField) {
        return <AlertRedFilled />;
      }
      return null;
    }, [errorField, isSubmitted]);

    const handleMouseDown = (event: MouseEvent) => {
      event.preventDefault();
    };

    return (
      <FormControl classes={{ root: classes.formControlRoot }}>
        <InputLabel
          htmlFor={inputProps.name}
          variant="outlined"
          disableAnimation
          classes={{
            root: classes.inputLabelRoot,
          }}
        >
          <span className={classes.placeholder}>
            {placeholder} {required && <Asterics />}
          </span>
        </InputLabel>
        <MUOutlinedInput
          aria-describedby="component-helper-text"
          disabled={disabled || isReadOnly}
          id={id || inputProps.name}
          error={isSubmitted && !!errorField}
          multiline={multiline}
          type={type}
          autoComplete="off"
          endAdornment={
            currentEndAdornment && (
              <InputAdornment position="end" onMouseDown={handleMouseDown}>
                {currentEndAdornment}
              </InputAdornment>
            )
          }
          startAdornment={
            startIcon && (
              <InputAdornment position="start" onMouseDown={handleMouseDown}>
                {startIcon}
              </InputAdornment>
            )
          }
          {...inputProps}
          className={cx({ [classes.fieldProps]: isNonEmpty })}
          classes={{
            root: classes.root,
            input: classes.input,
            multiline: classes.multiline,
            inputMultiline: classes.input,
            focused: classes.focused,
            notchedOutline: classes.notchedOutline,
            disabled: classes.disabled,
            adornedStart: classes.adornedStart,
            adornedEnd: classes.adornedEnd,
          }}
        />
        <FormHelperText
          id="component-helper-text"
          error={isSubmitted && !!errorField?.message}
          classes={{ root: classes.errorText }}
        >
          {errorField?.message}
        </FormHelperText>
      </FormControl>
    );
  }
);
