export enum BoxType {
  Radio = 'Radio',
  Checkbox = 'Checkbox',
}

export interface StylesProps {
  sizePx: number;
  type: BoxType;
}

export interface StylesLabelProps {
  checked?: boolean;
  marginLeft?: string;
  isReadOnly?: boolean;
}
