import React, { FunctionComponent, memo } from 'react';

interface Props {
  onClick?: () => void;
  className?: string;
}

export const ArrowLeft: FunctionComponent<Props> = memo((props) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18.5825 11L7.41252 11L11.2925 7.12003C11.6825 6.73003 11.6825 6.09003 11.2925 5.70003C10.9025 5.31003 10.2725 5.31003 9.88252 5.70003L4.29252 11.29C3.90252 11.68 3.90252 12.31 4.29252 12.7L9.87252 18.3C10.2625 18.69 10.8925 18.69 11.2825 18.3C11.6725 17.91 11.6725 17.28 11.2825 16.89L7.41252 13L18.5825 13C19.1325 13 19.5825 12.55 19.5825 12C19.5825 11.45 19.1325 11 18.5825 11Z"
      fill="#B9C1CC"
    />
  </svg>
));
