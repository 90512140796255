import { makeStyles, createStyles } from '@material-ui/core';
import React, { FC } from 'react';
import colors from 'styles/colors';

interface IProps {
  variant: 'small' | 'page';
  count?: number;
  color?: string;
}

const useStyles = makeStyles((theme) =>
  createStyles({
    container: ({ variant }: Partial<IProps>) => {
      if (variant === 'small') {
        return {
          position: 'relative',
          '& svg': {
            width: 20,
            height: 20,
          },
        };
      }

      if (variant === 'page') {
        return {
          position: 'relative',
          '& svg': {
            width: 40,
            height: 40,
          },
        };
      }

      return {};
    },
    counter: ({ variant }: Partial<IProps>) => {
      const commonProps = {
        position: 'absolute',
        backgroundColor: theme.colors.orange,
        borderRadius: '50%',
        fontWeight: 700,
        color: '#fff',
        textAlign: 'center',
      };

      if (variant === 'small') {
        return {
          ...commonProps,
          top: -5,
          right: -5,
          width: 15,
          height: 15,
          fontSize: '10px',
          lineHeight: '16px',
          '& svg': {
            width: 20,
            height: 20,
          },
        };
      }

      if (variant === 'page') {
        return {
          ...commonProps,
          top: 0,
          right: -4,
          width: 20,
          height: 20,
          fontSize: '13px',
          lineHeight: '21px',
          '& svg': {
            width: 40,
            height: 40,
          },
        };
      }

      return {};
    },
  })
);

const CartIcon: FC<IProps> = ({ color = colors.cyan, variant, count }) => {
  const classes = useStyles({ variant });
  return (
    <div className={classes.container}>
      {!!count && count > 0 && <div className={classes.counter}>{count}</div>}
      <svg
        viewBox="0 0 20 20"
        fill="none"
        preserveAspectRatio="xMinYMid meet"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M6.5 17C7.32843 17 8 17.6716 8 18.5C8 19.3284 7.32843 20 6.5 20C5.67157 20 5 19.3284 5 18.5C5 17.6716 5.67157 17 6.5 17ZM15.5 17C16.3284 17 17 17.6716 17 18.5C17 19.3284 16.3284 20 15.5 20C14.6716 20 14 19.3284 14 18.5C14 17.6716 14.6716 17 15.5 17ZM1 0H3C3.4556 0 3.84831 0.30684 3.96506 0.737739L3.98837 0.847943L4.4733 4H19C19.5998 4 20.0549 4.52068 19.9951 5.10035L19.9762 5.21693L18.3242 12.6508C18.0325 13.9637 16.9059 14.9143 15.5779 14.9945L15.3957 15H6.71584C5.29435 15 4.07697 14.0044 3.78265 12.6299L3.75072 12.4562L2.14208 2H1C0.447715 2 0 1.55228 0 1C0 0.487164 0.38604 0.0644928 0.883379 0.00672773L1 0H3H1ZM17.7534 6H4.781L5.72747 12.1521C5.79674 12.6024 6.15973 12.9438 6.60337 12.9937L6.71584 13H15.3957C15.8253 13 16.2021 12.7265 16.3402 12.3285L16.3718 12.2169L17.7534 6Z"
          fill={`${color}`}
        />
      </svg>
    </div>
  );
};

export default CartIcon;
