import queryString from 'query-string';
import { IScheduleItemExist } from 'types';
import { priceOptions } from './constants';

interface IColor {
  disabledColor: string;
  defaultColor: string;
  isEmpty?: boolean;
  isReadOnly?: boolean;
}

interface IProps {
  disabled?: boolean;
  isSubmitted?: boolean;
  error?: boolean;
  isWhite?: boolean;
}

export const makeGenerateColor = ({ disabled, isSubmitted, error, isWhite }: IProps) => ({
  disabledColor,
  defaultColor,
  isEmpty = false,
  isReadOnly,
}: IColor) => {
  if (isReadOnly) return 'grey6';
  if (disabled) return disabledColor;
  if (isSubmitted && !error) return isWhite ? 'white' : 'green';
  if (isSubmitted && error) return 'red';
  if (isEmpty) return 'grey4';
  return defaultColor;
};

export const parseQueryString = (query: string): any => {
  const parsedData = queryString.parse(query, {
    parseNumbers: true,
    arrayFormat: 'comma',
  });
  if (typeof parsedData.ages === 'number') parsedData.ages = [parsedData.ages];
  if (typeof parsedData.cities === 'string') parsedData.cities = [parsedData.cities];
  if (typeof parsedData.categories === 'number') parsedData.categories = [parsedData.categories];
  if (typeof parsedData.hiddenSubCategories === 'number')
    parsedData.hiddenSubCategories = [parsedData.hiddenSubCategories];
  if (typeof parsedData.businessCategories === 'number')
    parsedData.businessCategories = [parsedData.businessCategories];
  if (typeof parsedData.subCategories === 'number')
    parsedData.subCategories = [parsedData.subCategories];
  delete parsedData.key;
  return parsedData;
};

export const sortSchedule = (item?: IScheduleItemExist[]) =>
  item?.sort((a, b) => {
    if (a.id && b.id) return +a.id - +b.id;
    return 0;
  });

export const formatToCurrency = (number: number) => {
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  });
  return formatter.format(number);
};

export const findPeriod = (period: string) =>
  priceOptions.find(({ value }) => value === period)?.label;

export const hideEmailIfGUID = (email?: string) => {
  if (!email) return undefined;
  if (
    /[({]?[a-fA-F0-9]{8}[-]?([a-fA-F0-9]{4}[-]?){3}[a-fA-F0-9]{12}@kivity.com[})]?/g.test(email)
  ) {
    return undefined;
  }
  return email;
};

export const rangeExtraction = (list: string[]) => {
  const newList = [...list];

  for (let i = 0; i < newList.length; i++) {
    let j = i;
    while (+newList[j] - +newList[j + 1] === -1) j += 1;
    if (j !== i && j - i > 1) newList.splice(i, j - i + 1, `${newList[i]}-${newList[j]}`);
  }
  return newList.join(', ');
};

export const formatPrice = (price?: { value: number; period: string }) =>
  price ? (price.value === 0 ? 'Free' : `$${price.value}/${findPeriod(price.period)}`) : '-';
