import { createStyles, makeStyles } from '@material-ui/core/styles';
import { StylesProps } from './types';

export default makeStyles((theme) =>
  createStyles({
    inputRoot: ({ isSmall, isLarge, isOutlined }: StylesProps) => ({
      height: isSmall ? '40px' : isLarge ? '60px' : '50px',
      borderRadius: isOutlined ? '5px' : 'unset',
      backgroundColor: isOutlined ? theme.colors.grey6 : 'unset',
      '& .MuiInputAdornment-positionStart': {
        height: '100%',
        marginRight: 'unset',
        paddingLeft: isOutlined ? '18px' : 'unset',
      },
      '&:hover .MuiOutlinedInput-notchedOutline': {
        border: `1px solid ${theme.colors.cyan}`,
      },
    }),
    inputLabelRoot: ({
      nonEmpty,
      placeholderColor,
      isSmall,
      isLarge,
      isOutlined,
    }: StylesProps) => ({
      '&.MuiInputLabel-outlined, &.MuiInputLabel-shrink': {
        ...theme.fontStyle[isLarge ? 'p18' : 'p16'],
        display: nonEmpty ? 'none' : 'block',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        width: '100%',
        padding: isOutlined ? '0 10px' : '0 12px',
        lineHeight: isSmall ? '20px' : isLarge ? '30px' : '25px',
        color: theme.colors[placeholderColor],
      },
      '&.MuiInputLabel-shrink': {
        width: 'calc(100% - 23px)',
        transform: 'translateX(23px) translateY(50%)',
      },
      '&.MuiInputLabel-outlined': {
        width: 'calc(100% - 42px)',
        transform: 'translateX(42px) translateY(50%)',
      },
    }),
    input: ({ isOutlined, isLarge }: StylesProps) => ({
      ...theme.fontStyle[isLarge ? 'p18' : 'p16'],
      padding: isOutlined ? '0 10px' : '0 12px',
    }),
    formControl: () => ({
      marginTop: 'unset !important',
    }),
    formControlRoot: ({ width = '360px', fullWidth, isSmall, isLarge }: StylesProps) => ({
      width: fullWidth ? '100%' : width,
      height: isSmall ? '40px' : isLarge ? '60px' : '50px',
    }),
    adornedStart: {
      paddingLeft: 'unset',
    },
    adornedEnd: {
      paddingRight: 'unset',
    },
    underline: {
      '&:hover:not(.Mui-disabled):before': {
        borderBottomWidth: '1px',
      },
      '&:after': {
        borderBottom: `1px solid ${theme.colors.cyan}`,
      },
    },
    notchedOutline: {
      borderWidth: '1px',
      borderColor: theme.colors.grey6,
    },
    focused: () => ({
      '& fieldset': {
        border: `1px solid ${theme.colors.cyan} !important`,
      },
    }),
  })
);
