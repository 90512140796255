import { createStyles, makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) =>
  createStyles({
    childContainer: {
      marginBottom: '24px',
      [theme.breakpoints.only('xs')]: {
        flexDirection: 'column',
        alignItems: 'unset',
        justifyContent: 'unset',
      },
    },
    child: {
      marginRight: '24px',
      fontWeight: 600,
      cursor: 'pointer',
      '&:hover': {
        color: theme.colors.cyan,
      },
    },
    childSelected: {
      color: theme.colors.cyan,
    },
    heading: {
      marginBottom: '24px',
    },
    title: {
      marginBottom: '26px',
    },
    buttonsContainer: {
      marginTop: '32px',
      '& button': {
        margin: '0 10px',
      },
      [theme.breakpoints.only('xs')]: {
        flexDirection: 'column-reverse',
        '& button': {
          margin: '8px 0',
          width: '100%',
        },
      },
    },
    addChildButton: {
      [theme.breakpoints.only('xs')]: {
        justifyContent: 'unset',
        flexBasis: 'auto',
        marginTop: '16px',
      },
    },
    childrenContainer: {
      [theme.breakpoints.only('xs')]: {
        flexBasis: 'auto',
      },
    },
  })
);
