import React, {
  ChangeEvent,
  FunctionComponent,
  KeyboardEvent,
  useCallback,
  useMemo,
  useState,
} from 'react';
import { useHistory } from 'react-router-dom';
import queryString from 'query-string';
// view component
import HeaderButtonsView from './view';
// modals
import { SuccessfullyModal } from 'modals';
// icons
import { ReactComponent as Coffee } from 'assets/icons/origin/Coffee.svg';
// helpers
import { parseQueryString } from 'utils/helpers';
// custom hooks
import { useModalSetter } from 'hooks/useModal';
import { useStore } from 'hooks/useStore';
// types
import { StatusProps } from 'types';
import { IHeaderButtons } from './types';

const HeaderButtons: FunctionComponent<IHeaderButtons> = ({
  role,
  avatar,
  isBusinessLandingPage,
  headerIsTransparent,
}) => {
  const [searchValue, setSearchValue] = useState('');
  const { setModal } = useModalSetter();
  const history = useHistory();
  const { user } = useStore();

  const handleSearch = (event: ChangeEvent<HTMLInputElement>) => {
    setSearchValue(event.target.value);
  };

  const handleKeyDown = useCallback(
    (event: KeyboardEvent<HTMLTextAreaElement | HTMLInputElement>) => {
      if (event.key === 'Enter') {
        if (history.location.pathname === '/search') {
          const currentQueryData = parseQueryString(history.location.search);
          const newQueryData = queryString.stringify(
            { ...currentQueryData, search: searchValue, tab: 0 },
            { arrayFormat: 'comma' }
          );
          history.push({ search: newQueryData });
        } else {
          history.push({
            pathname: '/search',
            search: `?${queryString.stringify({ search: searchValue })}`,
          });
        }
      }
    },
    [searchValue, history]
  );

  const businessIsApproved = useMemo(
    () => user?.business_profile?.status.toUpperCase() === StatusProps.active,
    [user]
  );

  const openAdminApproveModal = useCallback(() => {
    setModal(
      <SuccessfullyModal
        title="Sorry!"
        subTitle="You account is still being reviewed by Admin."
        text="We will let You know once it is completed."
        additionalText="Please give us few more minutes"
        icon={<Coffee />}
      />
    );
  }, [setModal]);

  return (
    <HeaderButtonsView
      role={role}
      avatar={avatar}
      searchValue={searchValue}
      handleSearch={handleSearch}
      handleKeyDown={handleKeyDown}
      businessIsApproved={businessIsApproved}
      headerIsTransparent={headerIsTransparent}
      openAdminApproveModal={openAdminApproveModal}
      isBusinessLandingPage={isBusinessLandingPage}
    />
  );
};

export default HeaderButtons;
