import React, { FunctionComponent, memo } from 'react';
import createNumberMask from 'text-mask-addons/dist/createNumberMask';
import MaskedInput from 'react-text-mask';
// types
import { INumberMask } from './types';

export const Number: FunctionComponent<INumberMask> = memo(
  ({ inputRef, integerLimit = null, prefix = '', allowDecimal = false, ...other }) => {
    const maskOptions = {
      prefix,
      integerLimit,
      allowDecimal,
      allowNegative: false,
      allowLeadingZeroes: true,
      includeThousandsSeparator: false,
    };

    const numberMask = createNumberMask(maskOptions);

    return (
      <MaskedInput
        {...other}
        ref={(ref: any) => {
          inputRef(ref ? ref.inputElement : null);
        }}
        mask={numberMask}
      />
    );
  }
);
