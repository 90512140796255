import { createStyles, makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) =>
  createStyles({
    popupEvent: {
      background: theme.colors.grey6,
      borderRadius: '2px',
      borderLeftWidth: '8px',
      borderLeftStyle: 'solid',
      marginTop: '16px',
      padding: '13px 13px 12px 14px',
      width: '320px',
    },
    popupTime: {
      fontWeight: 500,
      display: 'flex',
      alignItems: 'center',
      '& > svg': {
        width: '16px',
        height: '16px',
        minWidth: '16px',
        minHeight: '16px',
        marginRight: '6px',
      },
    },
    title: {
      cursor: 'pointer',
      textDecoration: 'underline',
    },
  })
);
