export const authPages = [
  '/login',
  '/sign-up',
  '/forgot-password',
  '/setup-password',
  '/business-setup-password',
  '/business-registration',
  '/confirm-email',
];

export const businessLandingPages = ['/business', '/business/learn-more', '/business/kivity-ads'];

export const authUpPages = ['/login', '/sign-up'];

export enum Roles {
  unauthorized = 'unauthorized',
  business = 'BUSINESS',
  parent = 'PARENT',
  admin = 'ADMIN',
}

export enum Genders {
  male = 'MALE',
  female = 'FEMALE',
  other = 'OTHER',
}

export enum YesNo {
  yes = 'Yes',
  no = 'No',
}

export const agesOptions = [
  { label: '0-3', value: 'guid0', id: 1 },
  { label: '3-5', value: 'guid1', id: 2 },
  { label: '5-8', value: 'guid2', id: 3 },
  { label: '8-10', value: 'guid3', id: 4 },
  { label: '10-12', value: 'guid4', id: 5 },
  { label: '12-14', value: 'guid5', id: 6 },
  { label: '14-16', value: 'guid6', id: 7 },
  { label: '16-18', value: 'guid7', id: 8 },
];

export const genderOptions = [
  { label: 'Male', value: 'MALE' },
  { label: 'Female', value: 'FEMALE' },
  { label: 'Other', value: 'OTHER' },
];

export const classGenderOptions = [
  { label: 'Male', value: 'MALE' },
  { label: 'Female', value: 'FEMALE' },
  { label: 'Both', value: 'OTHER' },
];

export const billingOptions = [
  { label: 'Monthly', value: 'MONTHLY' },
  { label: 'Weekly', value: 'WEEKLY' },
  { label: 'Daily', value: 'DAILY' },
];

export const levelOptions = [
  { label: 'Recreational', value: 'RECREATIONAL' },
  { label: 'Beginner', value: 'BEGINNER' },
  { label: 'Intermediate', value: 'INTERMEDIATE' },
  { label: 'Competitive', value: 'COMPETITIVE' },
];

export const priceOptions = [
  { label: 'session', value: 'PER_SESSION' },
  { label: 'month', value: 'PER_MONTH' },
  { label: 'class', value: 'PER_CLASS' },
  { label: 'season', value: 'PER_SEASON' },
  { label: 'daily', value: 'PER_DAILY' },
];

export const discountType = [
  { label: '$ (Dollars)', value: 'dollars' },
  { label: '% (Percentage)', value: 'percentage' },
];

export const currencyOptions: { [x: string]: string } = {
  dollars: '$',
  percentage: '%',
};

export const days = ['SUNDAY', 'MONDAY', 'TUESDAY', 'WEDNESDAY', 'THURSDAY', 'FRIDAY', 'SATURDAY'];

export const distanceOptions = [
  { label: '0-3 Miles', value: 'guid0', id: 1 },
  { label: '3-10 Miles', value: 'guid1', id: 2 },
  { label: '10-25 Miles', value: 'guid2', id: 3 },
  { label: '25+ Miles', value: 'guid3', id: 4 },
];

export const gradeLevelOptions = [
  { label: 'Under 5', value: 'Under 5' },
  { label: 'Kindergarten', value: 'Kindergarten' },
  { label: '1st', value: '1-st' },
  { label: '2nd', value: '2-nd' },
  { label: '3rd', value: '3-rd' },
  { label: '4th', value: '4-th' },
  { label: '5th', value: '5-th' },
  { label: '6th', value: '6-th' },
  { label: '7th', value: '7-th' },
  { label: '8th', value: '8-th' },
  { label: '9th', value: '9-th' },
  { label: '10th', value: '10-th' },
  { label: '11th', value: '11-th' },
  { label: '12th', value: '12-fth' },
];

export const yesNoOptions = [
  { label: 'Yes', value: YesNo.yes },
  { label: 'No', value: YesNo.no },
];

export const businessStatusOptions = [
  { label: 'Yes', value: 'active' },
  { label: 'No', value: 'inactive' },
];

export const ADVERTISING_COUNTS = {
  PLATINUM: 6,
  PREMIUM: 4,
  ELITE: 4,
};

export const MONTH_MULTIPLIERS = {
  MONTHLY: 1,
  QUARTERLY: 3,
  ANNUALLY: 12,
};

export const PROMOTE_LIMIT_REACHED_MESSAGE = 'Promotional limit reached';

export const ALL_AGES_ARRAY_LENGTH = 19;

export enum EnrollmentStatus {
  soldOut = 'SOLD OUT',
  waitlistAvailable = 'Waitlist available',
  fewSpotsLeft = 'Few Spots Left',
  available = 'Available',
}
