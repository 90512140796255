export enum StripeProducts {
  CourseAd = 'course_ad',
  ClassEnrollment = 'class_enrollment',
}

export interface ICreateSessionChildrenData {
  id: number;
  extended_hours: string | null;
  preferred_day: string | null;
}

export type TSession = { url: string };
