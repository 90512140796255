import React, { FunctionComponent, MouseEvent, ChangeEvent, useState, useMemo } from 'react';
import moment from 'moment';
// view component
import FilterMenuView from './view';
// types
import { IFilterMenu } from './types';

export const FilterMenu: FunctionComponent<IFilterMenu> = ({ isSearchPage }) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [ages, setAges] = useState<string[]>([]);
  const [startSoonFilter, setStartSoonFilter] = useState(false);

  const handleClick = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    event.persist();
    const splitValue = event.target.value.split(',');
    if (event.target.checked && event.target.value) {
      setAges((prevState) => [...prevState, ...splitValue]);
    } else {
      setAges((prevState) => [...prevState.filter((item) => !splitValue.includes(item))]);
    }
  };

  const handleChangeStartSoon = (event: ChangeEvent<HTMLInputElement>) => {
    setStartSoonFilter(event.target.checked);
  };

  const newSearchValue = useMemo(() => {
    if (startSoonFilter)
      return {
        ages,
        after: moment().toISOString(),
        before: moment().add(2, 'week').toISOString(),
      };
    return { ages };
  }, [ages, startSoonFilter]);

  return (
    <FilterMenuView
      anchorEl={anchorEl}
      handleClick={handleClick}
      handleClose={handleClose}
      handleChange={handleChange}
      isSearchPage={isSearchPage}
      newSearchValue={newSearchValue}
      startSoonFilter={startSoonFilter}
      handleChangeStartSoon={handleChangeStartSoon}
    />
  );
};
