import React, { FunctionComponent, memo } from 'react';

interface Props {
  isActive?: boolean;
  stroke?: string;
}

export const Ads: FunctionComponent<Props> = memo(({ isActive = false, stroke = '#B9C1CC' }) => {
  const color = isActive ? '#268AFF' : stroke;
  return (
    <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M10.8886 5.3792V20.2225C10.8882 20.683 10.7254 21.1287 10.4287 21.4809C10.132 21.8332 9.72057 22.0694 9.26679 22.148C8.81302 22.2267 8.34606 22.1426 7.94818 21.9107C7.5503 21.6788 7.24703 21.3139 7.09179 20.8803L4.70617 14.0465M4.70617 14.0465C3.76305 13.6455 2.98743 12.932 2.51058 12.0248C2.03373 11.1177 1.88492 10.0736 2.08935 9.06935C2.29378 8.06507 2.83888 7.16227 3.63241 6.51373C4.42594 5.86518 5.41917 5.51071 6.44399 5.51032H8.47961C13.0353 5.51032 16.9521 4.13912 18.6666 2.17676V17.7334C16.9521 15.771 13.0364 14.3998 8.47961 14.3998H6.44399C5.8469 14.4007 5.25579 14.2798 4.70617 14.0465ZM18.6666 13.2886C19.5507 13.2886 20.3985 12.9374 21.0237 12.3123C21.6488 11.6871 22 10.8392 22 9.95508C22 9.07096 21.6488 8.22305 21.0237 7.59789C20.3985 6.97273 19.5507 6.62151 18.6666 6.62151V13.2886Z"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
});
