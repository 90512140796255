import React, { FunctionComponent, memo } from 'react';

interface Props {
  isActive?: boolean;
}

export const Notification: FunctionComponent<Props> = memo(({ isActive = false }) => {
  const color = isActive ? '#268AFF' : '#B9C1CC';
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.00007 20C4.29581 20 3.82481 19.2979 4.05985 18.6586L4.10564 18.5528L5.26106 16.242C5.69845 15.3672 5.94714 14.4115 5.9925 13.4369L6.00007 13.1115V10C6.00007 7.02694 8.16245 4.55893 11.0003 4.08293L11.0001 3C11.0001 2.44772 11.4478 2 12.0001 2C12.5524 2 13.0001 2.44772 13.0001 3L13.0009 4.08309C15.7594 4.54627 17.8794 6.89172 17.9951 9.75347L18.0001 10V13.1115C18.0001 14.0895 18.205 15.0555 18.6003 15.9474L18.7391 16.242L19.8945 18.5528C20.2094 19.1827 19.7921 19.918 19.1152 19.9936L19.0001 20H14.0001C14.0001 21.1046 13.1046 22 12.0001 22C10.8955 22 10.0001 21.1046 10.0001 20H5.00007ZM12.0001 6C9.85787 6 8.10899 7.68397 8.00496 9.80036L8.00007 10V13.1115C8.00007 14.3922 7.72672 15.6569 7.19987 16.8213L7.04991 17.1364L6.6181 18H17.382L16.9502 17.1364C16.3774 15.9908 16.0564 14.7374 16.0068 13.4603L16.0001 13.1115V10C16.0001 7.79086 14.2092 6 12.0001 6Z"
        fill={color}
      />
    </svg>
  );
});
