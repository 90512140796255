import { createStyles, makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) =>
  createStyles({
    container: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'relative',
      width: '100vw',
      height: '100vh',
    },
    fullContainer: {
      width: '100%',
      height: '100%',
    },
    backdropContainer: {
      position: 'absolute',
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
      width: '100%',
      height: '100%',
      backgroundColor: 'rgba(255, 255, 255, 0.7)',
      zIndex: 2,
    },
    spinner: {
      display: 'block',
      position: 'absolute',
      left: '50%',
      top: '50%',
      margin: '-75px 0 0 -75px',
      width: '150px',
      height: '150px',
      animation: '$spin_reverse 2.0s infinite linear',
      '&:before, &:after': {
        content: '""',
        width: 0,
        height: 0,
        display: 'block',
        position: 'absolute',
        borderRadius: '100%',
        animation: '$spin 2s infinite ease-in-out',
      },
      '&:before': {
        border: `3em solid ${theme.colors.cyan}`,
        top: 0,
        left: '50%',
      },
      '&:after': {
        border: `3em solid ${theme.colors.orange}`,
        bottom: 0,
        right: '50%',
        animationDelay: '-1s',
      },
    },
    '@keyframes spin_reverse': {
      '100%': {
        transform: 'rotate(360deg)',
      },
    },
    '@keyframes spin': {
      '0%, 100%': {
        transform: 'scale(0)',
      },
      '50%': {
        transform: 'scale(1)',
      },
    },
  })
);
