import React from 'react';
// icons
import { ReactComponent as CalendarSelectDays } from 'assets/icons/origin/Calendar-select-day.svg';
// components
import { OutlinedInput } from 'components/Inputs';
// types
import { InputProps } from './types';

export const InputComponent = React.forwardRef(
  ({ width = '206px', readOnly = true, ...props }: InputProps, ref) => {
    return (
      <OutlinedInput
        {...props}
        inputRef={ref}
        startIcon={<CalendarSelectDays />}
        invariableIcon
        width={width}
        readOnly={readOnly}
      />
    );
  }
);
