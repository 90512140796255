import { createStyles, makeStyles } from '@material-ui/core/styles';
// types
import { StylesProps } from './types';

export default makeStyles((theme) =>
  createStyles({
    badge: ({ isKivity }: StylesProps) => ({
      ...theme.fontStyle.caption,
      display: 'flex',
      justifyContent: 'center',
      padding: '5px 12px 4px',
      margin: '8px 4px 0 0',
      borderRadius: '20px',
      pointerEvents: 'none',
      textTransform: 'capitalize',
      color: isKivity ? theme.colors.orange : theme.colors.cyan,
      backgroundColor: isKivity ? theme.colors.orangePastel : theme.colors.cyanLight,
    }),
  })
);
