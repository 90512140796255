import { createStyles, makeStyles, withStyles } from '@material-ui/core/styles';
import { StepConnector } from '@material-ui/core';
import { StylesProps } from './types';

export const useStyles = makeStyles((theme) =>
  createStyles({
    label: {
      fontWeight: 600,
      [theme.breakpoints.only('xs')]: {
        whiteSpace: 'nowrap',
      },
    },
  })
);

export const useStepStyles = makeStyles((theme) =>
  createStyles({
    horizontal: {
      [theme.breakpoints.only('xs')]: {
        paddingRight: '16px',
        paddingLeft: '16px',
      },
    },
  })
);

export const useStylesStepper = makeStyles((theme) =>
  createStyles({
    root: {
      padding: 0,
      margin: '0 0 62px -70px',
      [theme.breakpoints.down('md')]: {
        margin: '0 0 48px',
      },
      [theme.breakpoints.only('xs')]: {
        overflowX: 'scroll',
      },
    },
  })
);

export const useStylesStepIcon = makeStyles((theme) =>
  createStyles({
    '@global': {
      '@keyframes pulse': {
        '0%': {
          transform: 'scale(1)',
        },
        '70%': {
          transform: 'scale(1.2)',
        },
        '100%': {
          transform: 'scale(1)',
        },
      },
    },
    root: {
      width: '44px',
      height: '44px',
      cursor: 'pointer',
      fill: theme.colors.grey5,
      zIndex: 2,
      backgroundColor: 'white',
      '& svg': {
        zIndex: 2,
      },
      '& path': {
        zIndex: 2,
      },
      [theme.breakpoints.only('xs')]: {
        width: '18px',
        height: '18px',
      },
    },
    active: {
      fill: theme.colors.cyan,
    },
    completed: {
      fill: theme.colors.cyan,
    },
    error: ({ startErrorsPulse }: StylesProps) => ({
      fill: theme.colors.red,
      animation: startErrorsPulse ? 'pulse 2s infinite' : 'none',
      willChange: 'transform',
    }),
    text: {
      ...theme.typography.subtitle2,
      transform: 'translateY(2px)',
      fontWeight: 600,
    },
  })
);

export const Connector = withStyles((theme) =>
  createStyles({
    alternativeLabel: {
      top: '25%',
      left: 0,
      right: 0,
      transform: 'translateX(-50%)',
      zIndex: 0,
      [theme.breakpoints.only('xs')]: {
        top: '8px',
        left: 'calc(-50% + 30px)',
        transform: 'translateX(-40%)',
      },
    },
    active: {
      zIndex: 1,
      '& $line': {
        backgroundColor: theme.colors.cyan,
      },
    },
    completed: {
      '& $line': {
        backgroundColor: theme.colors.cyan,
      },
    },
    line: {
      height: 2,
      border: 0,
      backgroundColor: theme.colors.grey5,
      borderRadius: 1,
    },
  })
)(StepConnector);
