import { makeStyles, createStyles } from '@material-ui/core/styles';
import { StylesProps } from './types';

export default makeStyles((theme) =>
  createStyles({
    container: ({ isLeftSection, isCentered }: StylesProps) => ({
      height: isCentered ? '580px' : '530px',
      maxWidth: isCentered ? '360px' : '310px',
      border: isCentered ? 'unset' : `1px solid ${theme.colors.grey5}`,
      padding: '24px 30px',
      borderRadius: isCentered ? '8px' : isLeftSection ? '8px 0px 0px 8px' : '0px 8px 8px 0px',
      backgroundColor: isCentered ? theme.colors.cyan : theme.colors.white,
    }),
    heading: ({ isCentered }: StylesProps) => ({
      color: isCentered ? theme.colors.white : theme.colors.black,
    }),
    description: ({ isCentered }: StylesProps) => ({
      color: isCentered ? theme.colors.white : theme.colors.grey3,
      margin: isCentered ? '8px 0 48px' : '8px 0 22px',
    }),
    boldText: ({ isCentered }: StylesProps) => ({
      fontSize: '16px',
      lineHeight: '32px',
      fontWeight: 600,
      letterSpacing: '-0.24px',
      color: isCentered ? theme.colors.white : theme.colors.black,
    }),
    date: {
      whiteSpace: 'nowrap',
    },
    greyText: {
      color: theme.colors.grey3,
    },
    buttonContainer: {
      marginTop: '18px',
    },
    textContainer: {
      margin: '14px 0 32px',
    },
    amountContainer: ({ isCentered }: StylesProps) => ({
      padding: '16px 0',
      borderBottom: `1px solid ${isCentered ? theme.colors.white : theme.colors.grey5}`,
    }),
  })
);
