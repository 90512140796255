import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(() => ({
  root: {
    width: '100%',
  },
  iconContainer: {
    display: 'flex',
    paddingRight: '16px',
    '& svg': {
      width: '24px',
      height: '24px',
    },
  },
}));
