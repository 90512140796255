import React, { FunctionComponent, MouseEvent, useState, useMemo } from 'react';
// material-ui components
import { InputAdornment } from '@material-ui/core';
// icons
import { VisibilityOn, VisibilityOff } from 'assets/icons';
// components
import { BaseInput } from '../BaseInput';
import { OutlinedInput } from '../OutlinedInput';
// interfaces
import { IPasswordInput } from './types';

export const PasswordInput: FunctionComponent<IPasswordInput> = ({
  outlined,
  placeholder,
  centeredPlaceholder,
  ...inputProps
}) => {
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);

  const togglePasswordVisibility = () => {
    setIsPasswordVisible(!isPasswordVisible);
  };

  const handleMouseDown = (event: MouseEvent) => {
    event.preventDefault();
  };

  const Input = useMemo(() => (outlined ? OutlinedInput : BaseInput), [outlined]);

  return (
    <Input
      isPassword
      type={isPasswordVisible ? 'text' : 'password'}
      placeholder={placeholder}
      centeredPlaceholder={centeredPlaceholder}
      {...inputProps}
      endAdornment={
        <InputAdornment position="end">
          {isPasswordVisible ? (
            <VisibilityOff onMouseDown={handleMouseDown} onClick={togglePasswordVisibility} />
          ) : (
            <VisibilityOn onMouseDown={handleMouseDown} onClick={togglePasswordVisibility} />
          )}
        </InputAdornment>
      }
    />
  );
};
