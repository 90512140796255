import React, { FunctionComponent, MouseEvent, useMemo } from 'react';
// material-ui components
import { FormControl, InputLabel, Input, OutlinedInput, InputAdornment } from '@material-ui/core';
// icons
import { Search as SearchIcon } from 'assets/icons';
// types
import { ISearch } from './types';
// styles
import useStyles from './styles';

export const Search: FunctionComponent<ISearch> = ({
  isOutlined,
  placeholder,
  width,
  fullWidth,
  isSmall,
  isLarge,
  handleChange,
  handleKeyDown,
  value,
}) => {
  const placeholderColor = isOutlined ? 'grey3' : 'grey4';

  const classes = useStyles({
    placeholderColor,
    nonEmpty: Boolean(value),
    width,
    fullWidth,
    isOutlined,
    isSmall,
    isLarge,
  });

  const CurrentInput = isOutlined ? OutlinedInput : Input;

  const handleMouseDown = (event: MouseEvent) => {
    event.preventDefault();
  };

  const inputClasses = useMemo(() => {
    const {
      inputRoot: root,
      input,
      adornedStart,
      adornedEnd,
      formControl,
      underline,
      notchedOutline,
      focused,
    } = classes;

    if (isOutlined) {
      return {
        root,
        input,
        adornedStart,
        adornedEnd,
        notchedOutline,
        focused,
      };
    }
    return { root, input, formControl, underline };
  }, [isOutlined, classes]);

  return (
    <FormControl classes={{ root: classes.formControlRoot }}>
      <InputLabel
        variant={isOutlined ? 'outlined' : 'standard'}
        disableAnimation
        htmlFor={placeholder}
        classes={{ root: classes.inputLabelRoot }}
      >
        {placeholder}
      </InputLabel>
      <CurrentInput
        id={placeholder}
        onChange={handleChange}
        onKeyDown={handleKeyDown}
        value={value}
        startAdornment={
          <InputAdornment position="start" onMouseDown={handleMouseDown}>
            <SearchIcon />
          </InputAdornment>
        }
        classes={inputClasses}
      />
    </FormControl>
  );
};
