import React, { FunctionComponent, useCallback } from 'react';
// material-ui components
import { Grid, Typography } from '@material-ui/core';
// components
import { Button } from 'components';
// icons
import { ReactComponent as SureDelete } from 'assets/icons/origin/SureDelete.svg';
// custom hooks
import { useModalSetter } from 'hooks/useModal';
// types
import { IDeleteStudent } from './types';
// styles
import useStyles from './styles';

export const DeactivateClass: FunctionComponent<IDeleteStudent> = ({ name, deactivate }) => {
  const { setModal } = useModalSetter();
  const classes = useStyles();

  const handleCloseModal = () => {
    setModal(null);
  };

  const handleDeleteUser = useCallback(async () => {
    await deactivate();
    setModal(null);
  }, [setModal, deactivate]);

  return (
    <Grid container direction="column" alignItems="center" className={classes.wrapper}>
      <Grid item className={classes.iconContainer}>
        <SureDelete />
      </Grid>
      <Grid container justify="center" item>
        <Typography variant="h5" className={classes.heading}>
          {`Are you sure you want to deactivate "${name}" ?`}
        </Typography>
      </Grid>
      <Grid container justify="center">
        <Button
          variant="text"
          label="Cancel"
          width="160px"
          onClick={handleCloseModal}
          className={classes.button}
        />
        <Button
          label="Yes, deactivate"
          width="160px"
          onClick={handleDeleteUser}
          className={classes.button}
        />
      </Grid>
    </Grid>
  );
};
