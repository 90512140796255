import React, { FunctionComponent, memo } from 'react';
// colors
import colors from 'styles/colors';

interface IClose {
  color?: 'red';
}

export const Close: FunctionComponent<IClose> = memo(({ color }) => (
  <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17.3002 4.70997C16.9102 4.31997 16.2802 4.31997 15.8902 4.70997L11.0002 9.58997L6.11021 4.69997C5.72021 4.30997 5.09021 4.30997 4.70021 4.69997C4.31021 5.08997 4.31021 5.71997 4.70021 6.10997L9.59021 11L4.70021 15.89C4.31021 16.28 4.31021 16.91 4.70021 17.3C5.09021 17.69 5.72021 17.69 6.11021 17.3L11.0002 12.41L15.8902 17.3C16.2802 17.69 16.9102 17.69 17.3002 17.3C17.6902 16.91 17.6902 16.28 17.3002 15.89L12.4102 11L17.3002 6.10997C17.6802 5.72997 17.6802 5.08997 17.3002 4.70997Z"
      fill={color ? colors[color] : colors.grey4}
    />
  </svg>
));
