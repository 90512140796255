import React, { FunctionComponent } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers';
// material-ui components
import { Grid, Typography } from '@material-ui/core';
// components
import { Button } from 'components';
// controllers
import { OutlinedInputController } from 'controllers';
// icons
import { ReactComponent as Email } from 'assets/icons/origin/Email.svg';
// custom hooks
import { useModalSetter } from 'hooks/useModal';
// types
import { IFormData, InviteProps } from './types';
// styles
import useStyles from './styles';
// validations
import { Schema } from './validation';
// helpers
import { defaultValues } from './helpers';

export const Invite: FunctionComponent<InviteProps> = ({ name, handleSubmit }) => {
  const classes = useStyles();
  const { setModal } = useModalSetter();

  const form = useForm({
    resolver: yupResolver(Schema),
    defaultValues,
  });

  const handleCloseModal = () => {
    setModal(null);
  };

  const onSubmit = (formData: IFormData) => {
    handleSubmit(formData.email);
    setModal(null);
  };

  return (
    <FormProvider {...form}>
      <Grid container direction="column" className={classes.wrapper}>
        <Grid container>
          <Typography variant="h5" paragraph>
            Invite {name}
          </Typography>
        </Grid>
        <Grid container component="form" onSubmit={form.handleSubmit(onSubmit)}>
          <OutlinedInputController
            name="email"
            type="email"
            placeholder="Type your e-mail"
            startIcon={<Email />}
            errorField={form.errors.email}
            required
          />
          <Grid container justify="center" className={classes.buttonsContainer}>
            <Button
              variant="text"
              label="Cancel"
              width="112px"
              onClick={handleCloseModal}
              className={classes.button}
            />
            <Button type="submit" label="Invite" width="164px" />
          </Grid>
        </Grid>
      </Grid>
    </FormProvider>
  );
};
