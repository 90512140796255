export const skeletons = [
  { width: 80 },
  { width: 30 },
  { width: 60 },
  { width: 150 },
  { width: 90 },
  { width: 170 },
  { width: 120 },
  { width: 170 },
  { width: 130 },
  { width: 60 },
  { width: 50 },
  { width: 50 },
];
