import React, { FunctionComponent, memo } from 'react';
// material-ui
import { FormControlLabel, FormControlLabelProps } from '@material-ui/core';
// styles
import useStyles from './styles';

export const FormLabel: FunctionComponent<FormControlLabelProps> = memo<FormControlLabelProps>(
  (props) => {
    const classes = useStyles();
    return <FormControlLabel {...props} classes={classes} />;
  }
);
