import React, { FunctionComponent, memo } from 'react';
// types
import { IBadge } from './types';
// styles
import useStyles from './styles';

export const Badge: FunctionComponent<IBadge> = memo(({ text, status }) => {
  const classes = useStyles({ status });

  return <span className={classes.badge}>{text?.toLowerCase?.()}</span>;
});
