import React, { FunctionComponent, memo } from 'react';

interface Props {
  isActive?: boolean;
}

export const CalendarSchedule: FunctionComponent<Props> = memo(({ isActive = false }) => {
  const color = isActive ? '#268AFF' : '#B9C1CC';
  return (
    <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17 2.05286C17.5523 2.05286 18 2.50057 18 3.05286V4.05286H19C20.6569 4.05286 22 5.396 22 7.05286V9.05286C22 9.60514 21.5523 10.0529 21 10.0529C20.4477 10.0529 20 9.60514 20 9.05286V7.05286C20 6.50057 19.5523 6.05286 19 6.05286H18V7.05286C18 7.60514 17.5523 8.05286 17 8.05286C16.4477 8.05286 16 7.60514 16 7.05286V6.05286H8V7.05286C8 7.60514 7.55228 8.05286 7 8.05286C6.44772 8.05286 6 7.60514 6 7.05286V6.05286H5C4.44772 6.05286 4 6.50057 4 7.05286V10.0529H9C9.55228 10.0529 10 10.5006 10 11.0529C10 11.6051 9.55228 12.0529 9 12.0529H4V19.0529C4 19.6051 4.44772 20.0529 5 20.0529H9C9.55228 20.0529 10 20.5006 10 21.0529C10 21.6051 9.55228 22.0529 9 22.0529H5C3.34315 22.0529 2 20.7097 2 19.0529V7.05286C2 5.396 3.34315 4.05286 5 4.05286H6V3.05286C6 2.50057 6.44772 2.05286 7 2.05286C7.55228 2.05286 8 2.50057 8 3.05286V4.05286H16V3.05286C16 2.50057 16.4477 2.05286 17 2.05286Z"
        fill={color}
      />
      <path
        d="M17 14.8306V17.0529L18.6667 18.7195M22 17.0529C22 17.7095 21.8707 18.3596 21.6194 18.9663C21.3681 19.5729 20.9998 20.1241 20.5355 20.5884C20.0712 21.0527 19.52 21.421 18.9134 21.6723C18.3068 21.9235 17.6566 22.0529 17 22.0529C16.3434 22.0529 15.6932 21.9235 15.0866 21.6723C14.48 21.421 13.9288 21.0527 13.4645 20.5884C13.0002 20.1241 12.6319 19.5729 12.3806 18.9663C12.1293 18.3596 12 17.7095 12 17.0529C12 15.7268 12.5268 14.455 13.4645 13.5173C14.4021 12.5796 15.6739 12.0529 17 12.0529C18.3261 12.0529 19.5979 12.5796 20.5355 13.5173C21.4732 14.455 22 15.7268 22 17.0529Z"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
});
