import { createStyles, makeStyles } from '@material-ui/core/styles';
import { StyleProps } from '../../types';

export default makeStyles(() =>
  createStyles({
    buttonsContainer: ({ headerIsTransparent }: StyleProps) => ({
      display: headerIsTransparent ? 'none' : 'flex',
    }),
    rightContentContainer: {
      display: 'flex',
      alignItems: 'center',
    },
    searchContainer: {
      margin: '0 4px 0 24px',
    },
    search: {
      marginRight: '24px',
      verticalAlign: 'middle',
      cursor: 'pointer',
    },
  })
);
