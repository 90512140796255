import React, { createContext, useContext, useState, useCallback, FunctionComponent } from 'react';
import { useHistory } from 'react-router-dom';
// helpers
import { clearAuthData } from 'utils/authData';
// types
import { IUser, IContextProps, IAgesOptions, IParentCategory } from './types';
import { Roles } from 'utils/constants';
import { ICategory } from 'types';
// icons
import AdminAvatar from 'assets/images/AdminAvatar.svg';

const StoreContext = createContext({} as IContextProps);

export const useStore = () => useContext(StoreContext);

export const StoreProvider: FunctionComponent = ({ children }) => {
  const history = useHistory();

  const [user, setUser] = useState<IUser>({
    role: Roles.unauthorized,
    isAuthorized: null,
    children: [],
  });

  const [stripeKey, updateStripeKey] = useState('');

  const [categories, updateCategories] = useState<ICategory[]>([]);

  const [cities, updateCities] = useState<string[]>([]);

  const [parentCategories, updateParentCategories] = useState<IParentCategory[]>([]);

  const [agesOptions, updateAgesOptions] = useState<IAgesOptions[]>([]);

  const localStorageCartValue =
    localStorage
      .getItem('cart')
      ?.split(',')
      .map((value) => +value) ?? [];

  const [cart, setCart] = useState<any[]>(localStorageCartValue);

  const addClassToCart = useCallback((classesIds: number | number[] | string | string[]) => {
    setCart((prevState) => {
      const currentValue = Array.isArray(classesIds)
        ? [...prevState, ...classesIds]
        : [...prevState, +classesIds];

      localStorage.setItem('cart', currentValue.join(','));

      return [...Array.from(new Set(currentValue))];
    });
  }, []);

  const removeClassFromCart = useCallback((classesIds: number | number[] | string | string[]) => {
    setCart((prevState) => {
      let currentValue;

      if (Array.isArray(classesIds)) {
        const filteredClasses = prevState.filter(
          // @ts-ignore
          (classId) => !classesIds.includes(classId)
        );

        currentValue = [...Array.from(new Set(filteredClasses))];
      } else {
        currentValue = prevState.filter((cartItem) => cartItem !== +classesIds);
      }

      if (currentValue.length) {
        localStorage.setItem('cart', currentValue.join(','));
      } else {
        localStorage.removeItem('cart');
      }

      return currentValue;
    });
  }, []);

  const removeAllClassesFromCart = useCallback(() => {
    localStorage.removeItem('cart');
    setCart([]);
  }, []);

  const setUserData = useCallback(
    (data) => {
      const userData = { ...user, ...data };
      const helpersData: any = {};
      switch (userData.role) {
        case Roles.admin:
          helpersData.avatar = AdminAvatar;
          helpersData.fullName = 'Kivity Admin';
          break;
        case Roles.business:
          helpersData.avatar = userData.business_profile?.title_photo?.path;
          helpersData.fullName = userData.business_profile?.company_name;
          break;
        case Roles.parent:
          helpersData.avatar = userData.parent_profile?.avatar?.path;
          helpersData.fullName = `${userData.parent_profile?.first_name} ${userData.parent_profile?.last_name}`;
          break;
      }

      setUser({ ...userData, ...helpersData });
    },
    [user]
  );

  const setStripeKey = (key: string) => updateStripeKey(key);

  const setCategories = (data: ICategory[]) => {
    updateCategories(data);
    updateParentCategories(
      data.reduce(
        (acc: IParentCategory[], item) =>
          item.parent_id
            ? acc
            : [...acc, { ...item, slug: item.title.replace(/ /g, '').toLowerCase() }],
        []
      )
    );
  };

  const setCities = (data: string[]) => {
    updateCities(data);
  };

  const setAgesOptions = (data: IAgesOptions[]) => {
    updateAgesOptions(data);
  };

  const logOut = useCallback(() => {
    setUser({ role: Roles.unauthorized, isAuthorized: false, children: [] });
    localStorage.removeItem('registerSteps');
    setCart([]);
    localStorage.removeItem('cart');
    clearAuthData();
    history.push('/');
  }, [history]);

  return (
    <StoreContext.Provider
      value={{
        user,
        setUserData,
        logOut,
        setStripeKey,
        stripeKey,
        categories,
        setCategories,
        parentCategories,
        setCities,
        cities,
        setAgesOptions,
        agesOptions,
        cart,
        addClassToCart,
        removeClassFromCart,
        removeAllClassesFromCart,
      }}
    >
      {children}
    </StoreContext.Provider>
  );
};
