import makeAxiosRequest, { GetAxiosResponse } from './axios.instance';

export interface RequestList {
  auth: {
    signIn: GetAxiosResponse;
    signUpClient: GetAxiosResponse;
    signUpBusiness: GetAxiosResponse;
    resetPassword: GetAxiosResponse;
    setupPassword: GetAxiosResponse;
    getUserData: GetAxiosResponse;
    googleSignUp: GetAxiosResponse;
    facebookSignUp: GetAxiosResponse;
    changePassword: GetAxiosResponse;
    changeEmail: GetAxiosResponse;
    activateParent: GetAxiosResponse;
    getAgesOptions: GetAxiosResponse;
    socialNoEmailSignUp: GetAxiosResponse;
  };
  categories: {
    getCategories: GetAxiosResponse;
  };
  upload: {
    uploadOne: GetAxiosResponse;
    uploadList: GetAxiosResponse;
  };
  classes: {
    putClass: GetAxiosResponse;
    getClasses: GetAxiosResponse;
    getParentCourseClasses: GetAxiosResponse;
    getClass: GetAxiosResponse;
    getBusinessClasses: GetAxiosResponse;
    getBusinessClass: GetAxiosResponse;
    create: GetAxiosResponse;
    updateClass: GetAxiosResponse;
    registerChildren: GetAxiosResponse;
    getParentClass: GetAxiosResponse;
    setFavorite: GetAxiosResponse;
    unsetFavorite: GetAxiosResponse;
    stripeIntents: GetAxiosResponse;
    unPublish: GetAxiosResponse;
    statistics: GetAxiosResponse;
    deleteClass: GetAxiosResponse;
    deleteChildren: GetAxiosResponse;
    getCourseClasses: GetAxiosResponse;
    createCourseClasses: GetAxiosResponse;
    deleteCourseClass: GetAxiosResponse;
    editCourseClasses: GetAxiosResponse;
    getCourseClass: GetAxiosResponse;
    getBusinessCourseClasses: GetAxiosResponse;
    getBusinessCourseClass: GetAxiosResponse;
    getClassWaitlist: GetAxiosResponse;
    createChildren: GetAxiosResponse;
    updateChildren: GetAxiosResponse;
    postBusinessClassCopy: GetAxiosResponse;
  };
  businessProfiles: {
    getProfile: GetAxiosResponse;
    getProfileBySlug: GetAxiosResponse;
    getProfilesList: GetAxiosResponse;
    updateProfile: GetAxiosResponse;
    notificationsSettings: {
      get: GetAxiosResponse;
      update: GetAxiosResponse;
    };
    photosVideos: {
      get: GetAxiosResponse;
      update: GetAxiosResponse;
      delete: GetAxiosResponse;
      updateTitlePhoto: GetAxiosResponse;
    };
    businessCourses: GetAxiosResponse;
    businessCoursesById: GetAxiosResponse;
  };
  businessAddress: {
    deleteAddress: GetAxiosResponse;
    getCities: GetAxiosResponse;
  };
  promotions: {
    getPromotions: GetAxiosResponse;
    classPromotion: GetAxiosResponse;
    getAvailableDate: GetAxiosResponse;
    checkPromotionData: GetAxiosResponse;
  };
  calendar: {
    getGuid: GetAxiosResponse;
    getEvents: GetAxiosResponse;
  };
  parentProfiles: {
    createProfile: GetAxiosResponse;
    updateProfile: GetAxiosResponse;
    getAllChild: GetAxiosResponse;
    getChild: GetAxiosResponse;
    updateChild: GetAxiosResponse;
    deleteChild: GetAxiosResponse;
  };
  stripe: {
    getStripeKey: GetAxiosResponse;
    postStripeCharge: GetAxiosResponse;
    business: {
      createAccount: GetAxiosResponse;
      onboardAccountLink: GetAxiosResponse;
      getAccount: GetAxiosResponse;
    };
    callback: {
      return: GetAxiosResponse;
      refresh: GetAxiosResponse;
    };
    createSession: GetAxiosResponse;
  };
  comments: {
    getComments: GetAxiosResponse;
    postComment: GetAxiosResponse;
  };
  admin: {
    business: {
      getBusinesses: GetAxiosResponse;
      getBusinessBySlug: GetAxiosResponse;
      create: GetAxiosResponse;
      getBusiness: GetAxiosResponse;
      update: GetAxiosResponse;
      delete: GetAxiosResponse;
      changeEmail: GetAxiosResponse;
      getPhotos: GetAxiosResponse;
      updatePhotos: GetAxiosResponse;
      updateTitlePhoto: GetAxiosResponse;
      deleteTitlePhoto: GetAxiosResponse;
      settings: GetAxiosResponse;
      transferBusiness: GetAxiosResponse;
      getBusinessClasses: GetAxiosResponse;
    };
    parent: {
      getParents: GetAxiosResponse;
      getParent: GetAxiosResponse;
      update: GetAxiosResponse;
      delete: GetAxiosResponse;
      getParentCourses: GetAxiosResponse;
    };
    promotions: {
      getCoursesWithoutStatistics: GetAxiosResponse;
      getStatistics: GetAxiosResponse;
      getPlatinumCourses: GetAxiosResponse;
      getPromotionForm: GetAxiosResponse;
      updatePromotionForm: GetAxiosResponse;
      getCategories: GetAxiosResponse;
      promoteClass: GetAxiosResponse;
    };
    classes: {
      createClass: GetAxiosResponse;
      getClass: GetAxiosResponse;
      updateClass: GetAxiosResponse;
      createChildren: GetAxiosResponse;
      deleteChildren: GetAxiosResponse;
      getClasses: GetAxiosResponse;
      getCourseClasses: GetAxiosResponse;
      getCourseClass: GetAxiosResponse;
      postAdminClassCopy: GetAxiosResponse;
    };
    businessPartners: {
      createPartner: GetAxiosResponse;
      getPartners: GetAxiosResponse;
      getPartner: GetAxiosResponse;
      deletePartner: GetAxiosResponse;
      updatePartner: GetAxiosResponse;
    };
    dashboard: {
      getCategories: GetAxiosResponse;
      getBusinesses: GetAxiosResponse;
      getParents: GetAxiosResponse;
    };
  };
  businessReview: {
    post: GetAxiosResponse;
    get: GetAxiosResponse;
    like: GetAxiosResponse;
    dislike: GetAxiosResponse;
    getRating: GetAxiosResponse;
  };
  businessParentProfiles: {
    getProfiles: GetAxiosResponse;
  };
  businessStudents: {
    getStudents: GetAxiosResponse;
    getStudent: GetAxiosResponse;
    inviteStudent: GetAxiosResponse;
  };
  parentChild: {
    createChild: GetAxiosResponse;
  };
  children: {
    getChildren: GetAxiosResponse;
    updateChildren: GetAxiosResponse;
    deleteChildren: GetAxiosResponse;
  };
  settings: {
    getSettings: GetAxiosResponse;
    updateBusinessSettings: GetAxiosResponse;
    updateParentSettings: GetAxiosResponse;
    updateAdminSettings: GetAxiosResponse;
  };
  statistics: {
    addClickCount: GetAxiosResponse;
    addViewCount: GetAxiosResponse;
  };
  common: {
    sendQuestionMessage: GetAxiosResponse;
  };
}

export default {
  auth: {
    signIn: makeAxiosRequest('/auth/signin', 'post'),
    signUpClient: makeAxiosRequest('/auth/signup-client', 'post'),
    signUpBusiness: makeAxiosRequest('/auth/signup-business', 'post'),
    resetPassword: makeAxiosRequest('/auth/password-reset-request', 'post'),
    setupPassword: makeAxiosRequest('/auth/password-reset-confirm', 'post'),
    getUserData: makeAxiosRequest('/auth/user', 'get'),
    googleSignUp: makeAxiosRequest('/auth/google', 'get'),
    facebookSignUp: makeAxiosRequest('/auth/facebook', 'get'),
    changePassword: makeAxiosRequest('/auth/change-password', 'patch'),
    changeEmail: makeAxiosRequest('/auth/change-email', 'patch'),
    activateParent: makeAxiosRequest('/auth/activate', 'post'),
    getAgesOptions: makeAxiosRequest('/course-ages', 'get'),
    socialNoEmailSignUp: makeAxiosRequest('/auth/signup-with-lost-data', 'post'),
  },
  categories: {
    getCategories: makeAxiosRequest('/categories', 'get'),
  },
  upload: {
    uploadOne: makeAxiosRequest('/uploadOne', 'post'),
    uploadList: makeAxiosRequest('/uploadList', 'post'),
  },
  classes: {
    putClass: makeAxiosRequest('/class', 'put'),
    getClasses: makeAxiosRequest('/course', 'get'),
    getClass: makeAxiosRequest((id) => `/course/${id}`, 'get'),
    getBusinessClasses: makeAxiosRequest('/business-course', 'get'),
    getBusinessClass: makeAxiosRequest((id) => `/business-course/${id}`, 'get'),
    create: makeAxiosRequest('/course', 'post'),
    updateClass: makeAxiosRequest((id) => `/course/${id}`, 'patch'),
    registerChildren: makeAxiosRequest((id) => `/parent/class/${id}/children`, 'post'),
    getParentClass: makeAxiosRequest((id) => `/parent/class/${id}`, 'get'),
    setFavorite: makeAxiosRequest((id) => `/course/favorite/${id}`, 'post'),
    unsetFavorite: makeAxiosRequest((id) => `/course/favorite/${id}`, 'delete'),
    stripeIntents: makeAxiosRequest('/course/get-payment-intents', 'post'),
    unPublish: makeAxiosRequest((id) => `/course/${id}/unpublish`, 'patch'),
    statistics: makeAxiosRequest((id) => `/courses-statistics/${id}`, 'get'),
    deleteClass: makeAxiosRequest((id) => `/course/${id}`, 'delete'),
    getCourseClasses: makeAxiosRequest('/class', 'get'),
    getParentCourseClasses: makeAxiosRequest('/parent/class', 'get'),
    getCourseClass: makeAxiosRequest((id) => `/class/${id}`, 'get'),
    createCourseClasses: makeAxiosRequest('/class', 'post'),
    deleteCourseClass: makeAxiosRequest((id) => `/class/${id}`, 'delete'),
    editCourseClasses: makeAxiosRequest((id) => `/class/${id}`, 'put'),
    getClassWaitlist: makeAxiosRequest((id) => `/class/${id}/waitlist`, 'get'),
    createChildren: makeAxiosRequest((id) => `/class/${id}/children`, 'post'),
    updateChildren: makeAxiosRequest((id) => `/class/${id}/children`, 'put'),
    deleteChildren: makeAxiosRequest((id) => `/class/${id}/children`, 'delete'),
    getBusinessCourseClasses: makeAxiosRequest('/business/class', 'get'),
    getBusinessCourseClass: makeAxiosRequest((id) => `/business/class/${id}`, 'get'),
    postBusinessClassCopy: makeAxiosRequest((id) => `/business/class/copy/${id}`, 'post'),
  },
  businessProfiles: {
    getProfile: makeAxiosRequest((id) => `business-profiles/${id}`, 'get'),
    getProfileBySlug: makeAxiosRequest((id) => `business-profiles/by-slug/${id}`, 'get'),
    getProfilesList: makeAxiosRequest('/business-profiles', 'get'),
    updateProfile: makeAxiosRequest('/business-profiles', 'put'),
    photosVideos: {
      get: makeAxiosRequest('/business-profiles/photos-videos', 'get'),
      update: makeAxiosRequest('/business-profiles/photos-videos', 'put'),
      delete: makeAxiosRequest('/business-profiles/photos-videos', 'delete'),
      updateTitlePhoto: makeAxiosRequest('/business-profiles/update-title-photo', 'patch'),
    },
    businessCourses: makeAxiosRequest('/business-profiles/courses', 'get'),
    businessCoursesById: makeAxiosRequest((id) => `/business-profiles/${id}/courses`, 'get'),
  },
  businessAddress: {
    deleteAddress: makeAxiosRequest((id) => `/business-address/${id}`, 'delete'),
    getCities: makeAxiosRequest('/business-address/cities', 'get'),
  },
  promotions: {
    getPromotions: makeAxiosRequest('/promotions', 'get'),
    getAvailableDate: makeAxiosRequest((id) => `/course/${id}/available-promotions-from`, 'get'),
    checkPromotionData: makeAxiosRequest((id) => `course/${id}/check-promotion-data`, 'post'),
  },
  calendar: {
    getGuid: makeAxiosRequest('/calendar/guid', 'get'),
    getEvents: makeAxiosRequest('/calendar/events', 'get'),
  },
  parentProfiles: {
    createProfile: makeAxiosRequest('/parent-profiles', 'post'),
    updateProfile: makeAxiosRequest((id) => `/parent-profiles/${id}`, 'put'),
    getAllChild: makeAxiosRequest('/parent-profile/children', 'get'),
    getChild: makeAxiosRequest((id) => `/parent-profile/children/${id}`, 'get'),
    updateChild: makeAxiosRequest((id) => `/parent-profile/children/${id}`, 'put'),
    deleteChild: makeAxiosRequest((id) => `/parent-profile/children/${id}`, 'delete'),
  },
  stripe: {
    getStripeKey: makeAxiosRequest('/stripe/public-key', 'get'),
    postStripeCharge: makeAxiosRequest('/stripe/charge', 'post'),
    business: {
      createAccount: makeAxiosRequest('/stripe/business/create-account', 'post'),
      onboardAccountLink: makeAxiosRequest('/stripe/business/onboarding-account-link', 'get'),
      getAccount: makeAxiosRequest('/stripe/business/get-account', 'get'),
    },
    callback: {
      return: makeAxiosRequest('/stripe/callback/return_url', 'get'),
      refresh: makeAxiosRequest('/stripe/callback/refresh_url', 'get'),
    },
    createSession: makeAxiosRequest('/stripe/checkout-session', 'post'),
  },
  comments: {
    getComments: makeAxiosRequest((id) => `/comments/${id}`, 'get'),
    postComment: makeAxiosRequest((id) => `/comments/${id}`, 'post'),
  },
  admin: {
    business: {
      getBusinesses: makeAxiosRequest('/admin/business', 'get'),
      create: makeAxiosRequest('/admin/business', 'post'),
      getBusiness: makeAxiosRequest((id) => `/admin/business/${id}`, 'get'),
      getBusinessBySlug: makeAxiosRequest((id) => `admin/business/by-slug/${id}`, 'get'),
      update: makeAxiosRequest((id) => `/admin/business/${id}`, 'put'),
      delete: makeAxiosRequest((id) => `/admin/business/${id}`, 'delete'),
      changeEmail: makeAxiosRequest((id) => `/admin/business/${id}/email`, 'patch'),
      getPhotos: makeAxiosRequest((id) => `/admin/business/${id}/photos`, 'get'),
      updatePhotos: makeAxiosRequest((id) => `/admin/business/${id}/photos`, 'put'),
      updateTitlePhoto: makeAxiosRequest((id) => `/admin/business/${id}/title-photo`, 'patch'),
      deleteTitlePhoto: makeAxiosRequest((id) => `/admin/business/${id}/title-photo`, 'delete'),
      settings: makeAxiosRequest((id) => `/admin/business/${id}/settings`, 'patch'),
      transferBusiness: makeAxiosRequest((id) => `/admin/business/${id}/transfer`, 'patch'),
      getBusinessClasses: makeAxiosRequest((id) => `/admin/business/${id}/courses`, 'get'),
    },
    parent: {
      getParents: makeAxiosRequest('/admin/parents', 'get'),
      getParent: makeAxiosRequest((id) => `/admin/parents/${id}`, 'get'),
      update: makeAxiosRequest((id) => `/admin/parents/${id}`, 'put'),
      delete: makeAxiosRequest((id) => `/admin/parents/${id}`, 'delete'),
      getParentCourses: makeAxiosRequest((id) => `/admin/parents/${id}/courses`, 'get'),
    },
    promotions: {
      getCoursesWithoutStatistics: makeAxiosRequest(
        '/admin/promotions/courses-without-promotions',
        'get'
      ),
      getStatistics: makeAxiosRequest('/admin/promotions/statistics', 'get'),
      getPlatinumCourses: makeAxiosRequest('/admin/promotions/platinum-courses', 'get'),
      getPromotionForm: makeAxiosRequest((id) => `/admin/promotions/${id}`, 'get'),
      updatePromotionForm: makeAxiosRequest((id) => `/admin/promotions/${id}`, 'put'),
      getCategories: makeAxiosRequest((id) => `/admin/promotions/${id}/categories`, 'get'),
      promoteClass: makeAxiosRequest('/admin/promotions/promotion-course', 'post'),
    },
    classes: {
      getClasses: makeAxiosRequest('/admin/course', 'get'),
      getClass: makeAxiosRequest((id) => `/admin/course/${id}`, 'get'),
      createClass: makeAxiosRequest('/admin/course', 'post'),
      updateClass: makeAxiosRequest((id) => `/admin/course/${id}`, 'patch'),
      createChildren: makeAxiosRequest((id) => `/admin/course/children/${id}`, 'post'),
      deleteChildren: makeAxiosRequest((id) => `/admin/course/children/${id}`, 'delete'),
      getCourseClasses: makeAxiosRequest('/admin/class', 'get'),
      getCourseClass: makeAxiosRequest((id) => `/admin/class/${id}`, 'get'),
      postAdminClassCopy: makeAxiosRequest((id) => `/admin/class/copy/${id}`, 'post'),
    },
    businessPartners: {
      createPartner: makeAxiosRequest('/business-partners', 'post'),
      getPartners: makeAxiosRequest('/business-partners', 'get'),
      getPartner: makeAxiosRequest((id) => `/business-partners/${id}`, 'get'),
      deletePartner: makeAxiosRequest((id) => `/business-partners/${id}`, 'delete'),
      updatePartner: makeAxiosRequest('/business-partners/', 'put'),
    },
    dashboard: {
      getCategories: makeAxiosRequest('/admin/dashboard/categories', 'get'),
      getBusinesses: makeAxiosRequest('/admin/dashboard/businesses', 'get'),
      getParents: makeAxiosRequest('/admin/dashboard/parents', 'get'),
    },
  },
  businessReview: {
    post: makeAxiosRequest((id) => `/business-review/${id}`, 'post'),
    get: makeAxiosRequest((id) => `/business-review/${id}`, 'get'),
    like: makeAxiosRequest((id) => `/business-review/like/${id}`, 'post'),
    dislike: makeAxiosRequest((id) => `/business-review/like/${id}`, 'delete'),
    getRating: makeAxiosRequest((id) => `/business-review/${id}/rating`, 'get'),
  },
  businessParentProfiles: {
    getProfiles: makeAxiosRequest('/parent-profiles', 'get'),
  },
  businessStudents: {
    getStudents: makeAxiosRequest('/business-students', 'get'),
    getStudent: makeAxiosRequest((id) => `/business-students/${id}`, 'get'),
    inviteStudent: makeAxiosRequest('/business-students-invite', 'post'),
  },
  parentChild: {
    createChild: makeAxiosRequest((id) => `/parent-profile/${id}/children`, 'post'),
  },
  children: {
    getChildren: makeAxiosRequest((id) => `/children/${id}`, 'get'),
    updateChildren: makeAxiosRequest((id) => `/children/${id}`, 'put'),
    deleteChildren: makeAxiosRequest((id) => `/children/${id}`, 'delete'),
  },
  settings: {
    getSettings: makeAxiosRequest('/settings', 'get'),
    updateBusinessSettings: makeAxiosRequest('/settings/business', 'put'),
    updateParentSettings: makeAxiosRequest('/settings/parent', 'put'),
    updateAdminSettings: makeAxiosRequest('/settings/admin', 'put'),
  },
  statistics: {
    addClickCount: makeAxiosRequest((id) => `/course/${id}/statistics/click`, 'post'),
    addViewCount: makeAxiosRequest((id) => `/course/${id}/statistics/view`, 'post'),
  },
  common: {
    sendQuestionMessage: makeAxiosRequest('/question-messages', 'post'),
  },
} as RequestList;
