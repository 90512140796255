import React, { FunctionComponent, memo } from 'react';

interface Props {
  isActive?: boolean;
}

export const List: FunctionComponent<Props> = memo(({ isActive = false }) => {
  const color = isActive ? '#268AFF' : '#B9C1CC';
  return (
    <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M8.57143 5.09551H6.28571C5.67951 5.09551 5.09812 5.32964 4.66947 5.74639C4.24082 6.16313 4 6.72837 4 7.31774V20.6511C4 21.2404 4.24082 21.8057 4.66947 22.2224C5.09812 22.6392 5.67951 22.8733 6.28571 22.8733H17.7143C18.3205 22.8733 18.9019 22.6392 19.3305 22.2224C19.7592 21.8057 20 21.2404 20 20.6511V7.31774C20 6.72837 19.7592 6.16313 19.3305 5.74639C18.9019 5.32964 18.3205 5.09551 17.7143 5.09551H15.4286M8.57143 5.09551C8.57143 5.68488 8.81224 6.25011 9.2409 6.66686C9.66955 7.08361 10.2509 7.31774 10.8571 7.31774H13.1429C13.7491 7.31774 14.3304 7.08361 14.7591 6.66686C15.1878 6.25011 15.4286 5.68488 15.4286 5.09551M8.57143 5.09551C8.57143 4.50614 8.81224 3.94091 9.2409 3.52416C9.66955 3.10742 10.2509 2.87329 10.8571 2.87329H13.1429C13.7491 2.87329 14.3304 3.10742 14.7591 3.52416C15.1878 3.94091 15.4286 4.50614 15.4286 5.09551M12 12.8733H15.4286M12 17.3177H15.4286M8.57143 12.8733H8.58286M8.57143 17.3177H8.58286"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
});
