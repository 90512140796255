import { createStyles, makeStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles(() =>
  createStyles({
    wrap: {
      paddingTop: '10px',
    },
    month: {
      fontWeight: 600,
      marginRight: '6px',
    },
    menuList: {
      maxHeight: '210px',
      paddingTop: 'unset',
      paddingBottom: 'unset',
    },
  })
);

export const useStylesSelect = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: 0,
      ...theme.typography.body1,
      fontWeight: 600,
    },
    select: {
      height: '26px',
      '&:focus': {
        backgroundColor: 'unset',
      },
    },
  })
);

export const useStylesMenuItem = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      ...theme.typography.body1,
      height: '30px',
      '&:hover': {
        backgroundColor: theme.colors.cyan,
        color: theme.colors.white,
      },
    },
    selected: {
      backgroundColor: `${theme.colors.cyan} !important`,
      color: theme.colors.white,
    },
  })
);
