import React, { memo } from 'react';
import cx from 'classnames';
// material-ui components
import { Grid, Typography } from '@material-ui/core';
// types
import { TProps } from './types';
// styles
import useStyles from './styles';

const HoursOfOperationView: TProps = memo(({ data }) => {
  const classes = useStyles();

  return (
    <Grid container direction="column">
      <span className={classes.detailsHeading}>Hours of Operation</span>
      {data?.map(({ day, start_time, end_time, closed }) => (
        <Grid container key={day} className={classes.hoursContainer}>
          <Grid item xs={5}>
            <Typography
              variant="subtitle2"
              className={cx(classes.day, { [classes.closedDay]: closed })}
            >
              {day.toLowerCase()}
            </Typography>
          </Grid>
          <Grid item xs={7}>
            <Typography
              variant="subtitle2"
              className={cx(classes.time, { [classes.closed]: closed })}
            >
              {closed ? 'Closed' : `${start_time} - ${end_time}`}
            </Typography>
          </Grid>
        </Grid>
      ))}
    </Grid>
  );
});

export default HoursOfOperationView;
