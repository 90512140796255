import React, { FunctionComponent } from 'react';
// material-ui components
import { Grid, Typography } from '@material-ui/core';
// components
import { Button } from 'components';
// icons
import { ReactComponent as CheckBig } from 'assets/icons/origin/Check_big.svg';
// types
import { IGenderConfirm } from './types';
// styles
import useStyles from './styles';

export const GenderConfirm: FunctionComponent<IGenderConfirm> = ({
  title,
  classGender,
  onOk,
  onClose,
}) => {
  const classes = useStyles();

  return (
    <Grid container direction="column" alignItems="center" className={classes.wrapper}>
      <Grid item>
        <CheckBig />
      </Grid>
      <Grid container justify="center" item className={classes.textContainer}>
        <Typography variant="h5" className={classes.text}>
          Oops!
        </Typography>
        <Typography variant="h5" className={classes.text}>
          The class "{title}" is supposed to be for only {classGender}.
        </Typography>
      </Grid>
      <Grid container justify="center" className={classes.buttonsContainer}>
        <Button
          variant="text"
          label="Skip this class"
          width="206px"
          onClick={onClose}
          className={classes.button}
        />
        <Button
          label="Enroll my kid anyway"
          width="206px"
          onClick={onOk}
          className={classes.button}
        />
      </Grid>
    </Grid>
  );
};
