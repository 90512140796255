import { lazy } from 'react';
import { IRoutes } from './types';
import { Roles } from 'utils/constants';

// auth
const SignUp = lazy(() => import('pages/Auth/SignUp'));
const Login = lazy(() => import('pages/Auth/Login'));
const ForgotPassword = lazy(() => import('pages/Auth/ForgotPassword'));
const SetupPassword = lazy(() => import('pages/Auth/SetupPassword'));
const BusinessSetupPassword = lazy(() => import('pages/Auth/BusinessSetupPassword'));
const SignUpBusiness = lazy(() => import('pages/Auth/SignUpBusiness'));
const SocialConfirmEmail = lazy(() => import('pages/Auth/SocialConfirmEmail'));
// business
const BusinessProfile = lazy(() => import('pages/Business/BusinessProfile'));
const BusinessClassAdd = lazy(() => import('pages/Business/Classes/ClassAdd'));
const BusinessClassView = lazy(() => import('pages/Business/Classes/ClassView'));
const BusinessClassesList = lazy(() => import('pages/Business/Classes/ClassesList'));
const BusinessClassEdit = lazy(() => import('pages/Business/Classes/ClassEdit'));
const BusinessClassDuplicate = lazy(() => import('pages/Business/Classes/ClassDuplicate'));
const Students = lazy(() => import('pages/Business/Students/StudentsList'));
const StudentView = lazy(() => import('pages/Business/Students/StudentView'));
const Dashboard = lazy(() => import('pages/Business/Dashboard'));
const BusinessCalendar = lazy(() => import('pages/Business/BusinessCalendar'));
const GhostStudentView = lazy(() => import('pages/Business/Students/GhostStudentView'));
const StudentEdit = lazy(() => import('pages/Business/Students/StudentEdit'));
// common pages
const PageNotFound = lazy(() => import('pages/404'));
const HomePage = lazy(() => import('pages/HomePage'));
const Search = lazy(() => import('pages/Search'));
const Help = lazy(() => import('pages/Help'));
const ClassView = lazy(() => import('pages/ClassView'));
const EnrollmentStudentView = lazy(() => import('pages/EnrollmentStudentView'));
const PrivacyPolicy = lazy(() => import('pages/PrivacyPolicy'));
const About = lazy(() => import('pages/About'));
const TermsAndConditions = lazy(() => import('pages/TermsAndConditions'));
const MarketingPolicy = lazy(() => import('pages/MarketingPolicy'));
const BusinessLandingHome = lazy(() => import('pages/BusinessLanding/Main'));
const BusinessLandingLearnMore = lazy(() => import('pages/BusinessLanding/LearnMore'));
const BusinessLandingKivityAds = lazy(() => import('pages/BusinessLanding/Ads'));
const ClassRoster = lazy(() => import('pages/ClassRoster'));
const Cart = lazy(() => import('pages/Cart'));
const ThankYou = lazy(() => import('pages/ThankYou'));
const Blog = lazy(() => import('pages/Blog/PostsList'));
const BlogPost = lazy(() => import('pages/Blog/Post'));
// parent
const ParentCalendar = lazy(() => import('pages/Parent/ParentCalendar'));
const ParentProfile = lazy(() => import('pages/Parent/ParentProfile'));
const MultipleClassesRegistration = lazy(() => import('pages/Parent/MultipleClassesRegistration'));
const Favorites = lazy(() => import('pages/Parent/Favorites'));
// admin
const AdminClassAdd = lazy(() => import('pages/Admin/Classes/ClassAdd'));
const AdminClassEdit = lazy(() => import('pages/Admin/Classes/ClassEdit'));
const AdminClasses = lazy(() => import('pages/Admin/Classes/ClassesList'));
const AdminClassDuplicate = lazy(() => import('pages/Admin/Classes/ClassDuplicate'));
const AdminBusinessProfileEdit = lazy(() => import('pages/Admin/Business/BusinessProfileEdit'));
const AdminBusinessProfileCreate = lazy(() => import('pages/Admin/Business/BusinessProfileCreate'));
const AdminBusinessList = lazy(() => import('pages/Admin/Business/BusinessList'));
const AdminParents = lazy(() => import('pages/Admin/Parent/ParentsList'));
const ParentProfileView = lazy(() => import('pages/Admin/Parent/ParentProfileView'));
const AdminAd = lazy(() => import('pages/Admin/Advertising/AdAdvertising'));
const AdminsAdEdit = lazy(() => import('pages/Admin/Advertising/EditAdvertising'));
const AdminClassView = lazy(() => import('pages/Admin/Classes/ClassView'));
const PartnersList = lazy(() => import('pages/Admin/Partners/PartnersList'));
const PartnerForm = lazy(() => import('pages/Admin/Partners/PartnerForm'));
const PartnerEdit = lazy(() => import('pages/Admin/Partners/PartnerEdit'));
const AdminDashboard = lazy(() => import('pages/Admin/Dashboard'));

interface IAllRoutes {
  [x: string]: IRoutes[];
}

export default {
  [Roles.unauthorized]: [
    { path: '/', component: HomePage, exact: true },
    { path: '/featured', component: HomePage, exact: true },
    { path: '/help', component: Help, exact: true },
    { path: '/sign-up', component: SignUp },
    { path: '/login', component: Login },
    { path: '/forgot-password', component: ForgotPassword },
    { path: '/setup-password', component: SetupPassword },
    { path: '/search', component: Search },
    { path: '/classes/registration', component: MultipleClassesRegistration },
    { path: '/classes/view/:id', component: ClassView },
    { path: '/privacy-policy', component: PrivacyPolicy },
    { path: '/about', component: About },
    { path: '/terms-and-conditions', component: TermsAndConditions },
    { path: '/marketing-policy', component: MarketingPolicy },
    { path: '/business-setup-password', component: BusinessSetupPassword },
    { path: '/business-registration', component: SignUpBusiness },
    { path: '/confirm-email', component: SocialConfirmEmail },
    { path: '/business', component: BusinessLandingHome, exact: true },
    { path: '/business/learn-more', component: BusinessLandingLearnMore },
    { path: '/business/kivity-ads', component: BusinessLandingKivityAds },
    { path: '/cart', component: Cart },
    { path: '/404', component: PageNotFound, exact: true },
    { path: '/thank-you', component: ThankYou, exact: true },
    { path: '/blog', component: Blog, exact: true },
    { path: '/blog/posts/:id', component: BlogPost, exact: true },
  ],
  [Roles.business]: [
    { path: '/', component: HomePage, exact: true },
    { path: '/featured', component: HomePage, exact: true },
    { path: '/classes', component: BusinessClassesList, exact: true },
    { path: '/classes/create', component: BusinessClassAdd },
    { path: '/classes/view/:id', component: ClassView },
    { path: '/classes/edit/:id', component: BusinessClassEdit },
    { path: '/classes/duplicate/:id', component: BusinessClassDuplicate },
    { path: '/profile', component: BusinessProfile },
    { path: '/search', component: Search },
    { path: '/business-classes/view/:id/roster/:classId', component: ClassRoster },
    { path: '/business-classes/view/:id', component: BusinessClassView },
    { path: '/students', component: Students, exact: true },
    { path: '/students/:id', component: StudentView },
    { path: '/students-without-class/:id', component: GhostStudentView },
    { path: '/students-edit/:id', component: StudentEdit },
    { path: '/course/:courseId/student/:id', component: EnrollmentStudentView },
    { path: '/privacy-policy', component: PrivacyPolicy },
    { path: '/about', component: About },
    { path: '/dashboard', component: Dashboard },
    { path: '/terms-and-conditions', component: TermsAndConditions },
    { path: '/marketing-policy', component: MarketingPolicy },
    { path: '/schedule', component: BusinessCalendar },
    { path: '/help', component: Help, exact: true },
    { path: '/cart', component: Cart },
    { path: '/404', component: PageNotFound },
    { path: '/business', component: BusinessLandingHome, exact: true },
    { path: '/business/learn-more', component: BusinessLandingLearnMore },
    { path: '/business/kivity-ads', component: BusinessLandingKivityAds },
    { path: '/thank-you', component: ThankYou, exact: true },
    { path: '/blog', component: Blog, exact: true },
    { path: '/blog/posts/:id', component: BlogPost, exact: true },
  ],
  [Roles.parent]: [
    { path: '/', component: HomePage, exact: true },
    { path: '/featured', component: HomePage, exact: true },
    { path: '/search', component: Search },
    { path: '/calendar', component: ParentCalendar, exact: true },
    { path: '/profile', component: ParentProfile },
    { path: '/classes/view/:id/roster', component: ClassRoster },
    { path: '/classes/view/:id', component: ClassView },
    { path: '/classes/registration', component: MultipleClassesRegistration },
    { path: '/favorites', component: Favorites },
    { path: '/privacy-policy', component: PrivacyPolicy },
    { path: '/about', component: About },
    { path: '/terms-and-conditions', component: TermsAndConditions },
    { path: '/marketing-policy', component: MarketingPolicy },
    { path: '/help', component: Help, exact: true },
    { path: '/cart', component: Cart },
    { path: '/404', component: PageNotFound },
    { path: '/business', component: BusinessLandingHome, exact: true },
    { path: '/business/learn-more', component: BusinessLandingLearnMore },
    { path: '/business/kivity-ads', component: BusinessLandingKivityAds },
    { path: '/thank-you', component: ThankYou, exact: true },
    { path: '/blog', component: Blog, exact: true },
    { path: '/blog/posts/:id', component: BlogPost, exact: true },
  ],
  [Roles.admin]: [
    { path: '/', component: HomePage, exact: true },
    { path: '/featured', component: HomePage, exact: true },
    { path: '/search', component: Search },
    { path: '/businesses', component: AdminBusinessList, exact: true },
    { path: '/business-profile/create', component: AdminBusinessProfileCreate },
    { path: '/business-profile/edit/:id', component: AdminBusinessProfileEdit },
    { path: '/parents', component: AdminParents, exact: true },
    { path: '/parents/:id', component: ParentProfileView },
    { path: '/advertising', component: AdminAd, exact: true },
    { path: '/advertising/:id', component: AdminsAdEdit, exact: true },
    { path: '/classes', component: AdminClasses, exact: true },
    { path: '/classes/view/:id/roster/:classId', component: ClassRoster },
    { path: '/classes/view/:id', component: AdminClassView },
    { path: '/classes/edit/:id', component: AdminClassEdit },
    { path: '/classes/duplicate/:id', component: AdminClassDuplicate },
    { path: '/classes/:businessId/create', component: AdminClassAdd },
    { path: '/admin-classes/view/:id', component: AdminClassView },
    { path: '/course/:courseId/student/:id', component: EnrollmentStudentView },
    { path: '/students-edit/:id', component: StudentEdit },
    { path: '/privacy-policy', component: PrivacyPolicy },
    { path: '/about', component: About },
    { path: '/terms-and-conditions', component: TermsAndConditions },
    { path: '/marketing-policy', component: MarketingPolicy },
    { path: '/help', component: Help, exact: true },
    { path: '/cart', component: Cart },
    { path: '/partners', component: PartnersList },
    { path: '/partner/create', component: PartnerForm },
    { path: '/partner/edit/:id', component: PartnerEdit },
    { path: '/404', component: PageNotFound },
    { path: '/dashboard', component: AdminDashboard },
    { path: '/business', component: BusinessLandingHome, exact: true },
    { path: '/business/learn-more', component: BusinessLandingLearnMore },
    { path: '/business/kivity-ads', component: BusinessLandingKivityAds },
    { path: '/thank-you', component: ThankYou, exact: true },
    { path: '/blog', component: Blog, exact: true },
    { path: '/blog/posts/:id', component: BlogPost, exact: true },
  ],
} as IAllRoutes;

export const categories = [
  { path: '/academic', component: Search },
  { path: '/art', component: Search },
  { path: '/cultural&language', component: Search },
  { path: '/dance', component: Search },
  { path: '/homeschoolprograms', component: Search },
  { path: '/music', component: Search },
  { path: '/performanceart', component: Search },
  { path: '/pickupafterschool', component: Search },
  { path: '/sports', component: Search },
  { path: '/stem', component: Search },
  { path: '/under5', component: Search },
  { path: '/camps', component: Search },
];
