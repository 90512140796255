import { IFormData } from './types';
import { PROMOTE_LIMIT_REACHED_MESSAGE } from 'utils/constants';

export const createCheckFormData = (formData: IFormData) => ({
  promotion_id: Number(formData.promotion_id),
  rate_id: Number(formData.rate),
  duration_variant: Number(formData.duration),
  total_sum: Number(formData.total),
  start_date: formData.start_date,
});

export const checkPromoteLimitReachedMessage = (message: string) =>
  message === PROMOTE_LIMIT_REACHED_MESSAGE;

export const calculateMonthsAmount = (formData: IFormData, promotions: any) => {
  const rateId = +formData.rate;
  const duration = +formData.duration;
  const promotion =
    formData.title === 'Elite'
      ? promotions[0]
      : formData.title === 'Platinum'
      ? promotions[1]
      : promotions[2];

  if (rateId && duration) {
    return promotion.costs?.[rateId - 1].rate.monthMultiplier * duration;
  }
  if (!rateId && duration) {
    return promotion.costs?.[0].rate.monthMultiplier * duration;
  }
  return promotion.costs?.[0].rate.monthMultiplier;
};
