import { makeStyles, createStyles } from '@material-ui/core/styles';

export default makeStyles((theme) =>
  createStyles({
    container: {
      position: 'relative',
      '& .react-multi-carousel-dot-list': {
        display: 'flex',
        position: 'unset',
        alignItems: 'center',
        height: '28px',
        padding: '0 15px',
        bottom: 'unset',
        right: 'unset',
        left: 'unset',
      },
    },
    carouselContainer: {
      width: '100%',
      marginBottom: '16px',
      borderRadius: '6px',
      '& .react-multi-carousel-track': {
        willChange: 'unset',
      },
    },
    dot: {
      width: '8px',
      height: '8px',
      margin: '0 4px',
      border: 'unset',
      cursor: 'pointer',
      borderRadius: '50%',
      backgroundColor: theme.colors.grey5,
    },
    dotActive: {
      backgroundColor: theme.colors.cyan,
    },
    imagePlaceholder: {
      '& svg': {
        width: '100%',
        height: '100%',
        maxWidth: '730px',
        maxHeight: '410px',
        objectFit: 'cover',
        borderRadius: '6px',
      },
    },
  })
);

export const defaultResponsiveOptions = {
  superLargeDesktop: {
    breakpoint: { max: 4000, min: 3000 },
    items: 1,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 1,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 1,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
};
