import { createStyles, makeStyles } from '@material-ui/core/styles';

export default makeStyles(() =>
  createStyles({
    wrapper: {
      maxWidth: '506px',
      paddingBottom: '38px',
    },
  })
);
