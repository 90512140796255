export default {
  blue: '#0045FF',
  blue2: '#479EE1',
  blue3: '#465DFF',
  blue4: '#17365D',
  blue5: '#4F81BD',
  pastelBlue: '#EFF2FF',
  cyan: '#268AFF',
  black: '#111111',
  blackBasic: '#000000',
  white: '#FFFFFF',
  red: '#FF3A29',
  redPastel: '#FFE5D3',
  green: '#34B53A',
  yellow: '#FFB200',
  grey1: '#1E2126',
  grey2: '#5C6574',
  grey3: '#828B9A',
  grey4: '#B9C1CC',
  grey5: '#E2E2EA',
  grey6: '#F8F8F8',
  grey7: '#F5F6FA',
  grey8: '#9597A1',
  grey9: '#8492BE',
  grey10: '#8B9898',
  background: '#E5E5E5',
  primaryLight: '#469BFF',
  primaryDark: '#0072F8',
  buttonDisabled: '#99C8FF',
  buttonDisabledOutline: '#8DC2FF',
  facebook: '#3B5999',
  facebookLight: '#5B7ABC',
  facebookDark: '#2A4784',
  goggle: '#ED4C42',
  goggleLight: '#F16D64',
  goggleDark: '#E32D22',
  darkBlue: '#343E5C',
  orange: '#FF8A00',
  orangeLight: '#FF9E45',
  orangeDark: '#FA6900',
  orangeDisabled: '#FFD39E',
  orangePastel: '#FFF0DE',
  cyanLight: '#E4F1FF',
  flaming: '#B8019D',
  pastelFlaming: '#FFDEEE',
  velvet: '#783BFF',
  pastelVelvet: '#F0EAFF',
};
