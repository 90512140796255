import { createStyles, makeStyles } from '@material-ui/core/styles';
// types
import { StylesProps } from './types';
// styles
import { statusProps } from './helpers';

export default makeStyles((theme) =>
  createStyles({
    badge: ({ status }: StylesProps) => ({
      display: 'flex',
      justifyContent: 'center',
      padding: '1px 14px',
      borderRadius: '25px',
      pointerEvents: 'none',
      textTransform: 'capitalize',
      ...statusProps(theme, status),
    }),
  })
);
