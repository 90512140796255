import React, { FunctionComponent, memo } from 'react';
// material-ui
import { Checkbox as CheckboxMU, FormControlLabel } from '@material-ui/core';
// styles
import useStyles from './styles';
// types
import { Props } from './types';

export const CheckboxButton: FunctionComponent<Props> = memo<Props>(
  ({ label, height = '40px', colorKey, ...rest }) => {
    const classes = useStyles({ height, checked: rest.checked, color: colorKey });
    return (
      <FormControlLabel
        control={<CheckboxMU {...rest} disableRipple className={classes.checkBox} />}
        classes={{ root: classes.root, label: classes.label }}
        label={label}
      />
    );
  }
);
