import React, { FunctionComponent, memo } from 'react';
// material-ui
import { RadioGroup as RadioGroupMUI } from '@material-ui/core';
import { Radio } from 'components/Radio';
// types
import { Props } from './types';

export const RadioGroup: FunctionComponent<Props> = memo<Props>(({ sizePx, values, ...rest }) => (
  <RadioGroupMUI {...rest}>
    {values?.map((item: { value: string; label: string }) => (
      <Radio
        value={item.value}
        label={item.label}
        key={item.value}
        sizePx={sizePx}
        checked={rest.value === item.value}
      />
    ))}
  </RadioGroupMUI>
));
