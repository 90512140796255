import * as yup from 'yup';
import moment from 'moment';
import validator from 'validator';
// errors text constants
export const INVALID_PHONE_NUMBER = 'Please use valid format of phone number';
export const INVALID_EMAIL = 'Please use valid format of Email address';
export const INVALID_PASSWORD =
  'Please, use min 8 characters including letters in upper and lower case, numbers & symbols';
export const INVALID_CONFIRM_PASSWORD = "Password and confirm password don't match";
export const EMPTY_CATEGORIES = 'Please check at least one category';
export const INVALID_END_TIME = 'End Time should be later than Start Time';
export const INVALID_END_DATE = 'End Date should be later than Start Date';
export const INVALID_TIME = 'Invalid time';
export const EARLY_DATE = 'Must be equal or later than the current date';
export const INVALID_REGISTRATION_DATE =
  'Registration date should be equal or earlier than Start Time';

const currentDate = moment().startOf('day').toDate();

// eslint-disable-next-line
export const isEmail = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
// eslint-disable-next-line
export const isPassword = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!$%^&*()_+|~\-=`{}[\]:";'<>?,./@#])[A-Za-z\d!$%^&*()_+|~\-=`{}[\]:";'<>?,./@#]{8,50}$/;

export const phoneRule = (name: string) => (phone?: string | null) => {
  if (!phone) return true;
  const isMobilePhone = validator.isMobilePhone(phone);
  return (
    isMobilePhone || new yup.ValidationError('Please use valid format of phone number', null, name)
  );
};

export const emailRule = yup.string().required('').matches(isEmail, INVALID_EMAIL);

export const passwordRule = yup.string().required('').matches(isPassword, INVALID_PASSWORD);

export const confirmPasswordRule = (name: string) =>
  passwordRule.oneOf([yup.ref(name)], INVALID_CONFIRM_PASSWORD);

export const scheduleClosedRule = yup.string().when('closed', {
  is: false,
  then: yup
    .string()
    .required('')
    .test(
      'timeTest',
      INVALID_TIME,
      (val?: string | null) => moment(val, 'hh:mm A').isValid() && !val?.includes('_')
    ),
});

export const scheduleTimeRule = (start_time: string, closed: boolean, schema: any) => {
  return schema.test({
    test: (end_time: string) => {
      return closed || !start_time || !end_time
        ? true
        : moment(start_time, 'hh:mm A').isBefore(moment(end_time, 'hh:mm A'));
    },
    message: INVALID_END_TIME,
  });
};

export const scheduleRule = yup.array().of(
  yup.object().shape({
    closed: yup.boolean(),
    start_time: scheduleClosedRule,
    end_time: scheduleClosedRule.when(['start_time', 'closed'], scheduleTimeRule),
  })
);

export const categoriesRule = yup
  .array()
  .test('categoryTest', '', (categories?: Array<boolean | unknown> | null) => {
    if (categories?.find((item) => item)) {
      return true;
    }
    return new yup.ValidationError(EMPTY_CATEGORIES, null, 'categories');
  });

export const agesRule = yup.object().test('agesTest', '', (ages: any) => {
  if (Object.values(ages).find((item) => item)) {
    return true;
  }
  return new yup.ValidationError('Please check at least one ages!', null, 'ages');
});

export const startDateTest = yup.date().required('').nullable();

export const currentDateTest = yup.date().required('').nullable().min(currentDate, EARLY_DATE);

export const registrationDateRule = (start_time: string, schema: any) => {
  return schema.test({
    test: (registration_date: Date) => {
      return moment(registration_date).isSameOrBefore(moment(start_time, 'hh:mm A'), 'days');
    },
    message: INVALID_REGISTRATION_DATE,
  });
};

export const endDateRule = (start_time: string, schema: any) => {
  return schema.test({
    test: (end_date: Date) => {
      return moment(end_date).isSameOrAfter(moment(start_time, 'hh:mm A'), 'days');
    },
    message: INVALID_END_DATE,
  });
};
