import React, { FunctionComponent, ReactNode } from 'react';
// material-ui components
import { Grid, Typography } from '@material-ui/core';
// components
import { ReactComponent as Doves } from './doves.svg';
// styles
import useStyles from './styles';

export const SupportUkraine: FunctionComponent<{ children?: ReactNode }> = ({ children }) => {
  const classes = useStyles();

  return (
    <Grid container direction="column" className={classes.wrapper}>
      {children}
      <Doves className={classes.doves} />
      <Grid container className={classes.textWrapper}>
        <Typography variant="h5" paragraph className={classes.title}>
          The people in Ukraine have long been suffering the effects of armed conflict, and
          political and social unrest. Children and families often bear the brunt of this. More than
          12 million people have fled their homes since Russia’s invasion of Ukraine, with 4.3
          million children being displaced.
        </Typography>
        <Typography variant="h5" paragraph className={classes.text}>
          Like billions of people around the world, we stand up and for Ukraine regarding the
          ongoing war and condemn Russia’s continued and illegal acts of aggression.
        </Typography>
        <Typography variant="h5" paragraph className={classes.text}>
          Kivity alongside our Ukranian Kivity Team is sharing ways to support and help the people
          of Ukraine:
        </Typography>
      </Grid>
      <Grid container justify="flex-start" direction="column" className={classes.linksContainer}>
        <a
          href="https://aid.prytulafoundation.org/en/"
          className={classes.link}
          target="_blank"
          rel="noopener noreferrer"
        >
          Help families rebuild their homes
        </a>
        <a
          href="https://uahelp.monobank.ua/"
          className={classes.link}
          target="_blank"
          rel="noopener noreferrer"
        >
          Support Ukrainian Army
        </a>
      </Grid>
    </Grid>
  );
};
