import { createStyles, makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) =>
  createStyles({
    wrapper: {
      maxWidth: '410px',
      padding: '13px 31px 36px',
    },
    buttonsContainer: {
      marginTop: '30px',
    },
    button: {
      marginRight: '24px',
    },
    description: {
      color: theme.colors.grey3,
      marginBottom: '24px',
    },
  })
);
