import React, { FunctionComponent } from 'react';
// material-ui components
import { Grid, Typography } from '@material-ui/core';
// components
import { Button } from 'components';
// icons
import { ReactComponent as SureDelete } from 'assets/icons/origin/SureDelete.svg';
// custom hooks
import { useModalSetter } from 'hooks/useModal';
// types
import { Props } from './types';
// styles
import useStyles from './styles';

export const ParentUpdateProfile: FunctionComponent<Props> = ({
  title,
  text,
  handleSubmit,
  buttonText,
}) => {
  const { setModal } = useModalSetter();
  const classes = useStyles();

  const handleCloseModal = () => {
    handleSubmit && handleSubmit();
    setModal(null);
  };

  return (
    <Grid container direction="column" alignItems="center" className={classes.wrapper}>
      <Grid item className={classes.iconContainer}>
        <SureDelete />
      </Grid>
      <Grid container justify="center" item>
        <Typography variant="h5" className={classes.heading}>
          {title}
        </Typography>
      </Grid>
      <Grid item>
        <Typography variant="subtitle2" className={classes.text}>
          {text}
        </Typography>
      </Grid>
      <Grid container justify="center">
        <Button
          label={buttonText || 'OK'}
          width={buttonText ? 'auto' : '160px'}
          onClick={handleCloseModal}
          className={classes.button}
        />
      </Grid>
    </Grid>
  );
};
