import React from 'react';
import { Link } from 'react-router-dom';
import { createStyles, makeStyles } from '@material-ui/core/styles';
// hooks
import { useStore } from 'hooks/useStore';
// assets
import CartIcon from 'assets/icons/Cart';

const useStyles = makeStyles((theme) =>
  createStyles({
    container: {
      zIndex: 1000,
      position: 'fixed',
      right: 37,
      bottom: 37,
      width: 88,
      height: 88,
      borderRadius: 24,
      backgroundColor: theme.colors.cyan,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
  })
);

const PageCart = () => {
  const { cart } = useStore();

  const classes = useStyles();

  if (!cart.length) {
    return null;
  }

  return (
    <Link to="/cart" className={classes.container}>
      <CartIcon variant="page" color="#fff" count={cart.length} />
    </Link>
  );
};

export default PageCart;
