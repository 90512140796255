import * as yup from 'yup';
import { startDateTest, currentDateTest } from 'utils/validations';
import moment from 'moment';

const endDateRule = (start_time: string, schema: any) => {
  return schema.test({
    test: (end_date: Date) => {
      return moment(end_date).isSameOrAfter(moment(start_time, 'hh:mm A'), 'days');
    },
    message: '',
  });
};

export const Schema = yup.object().shape({
  start_date: startDateTest,
  end_date: currentDateTest.when('start_date', endDateRule),
});
