import React, { FunctionComponent } from 'react';
// material-ui components
import { Grid, Typography } from '@material-ui/core';
// components
import { Button } from 'components';
// icons
import { ReactComponent as Mail } from 'assets/icons/origin/Mail.svg';
// custom hooks
import { useModalSetter } from 'hooks/useModal';
// styles
import useStyles from './styles';

export const ForgotPasswordModal: FunctionComponent = () => {
  const { setModal } = useModalSetter();
  const classes = useStyles();

  const handleCloseModal = () => {
    setModal(null);
  };

  return (
    <Grid container direction="column" alignItems="center" className={classes.wrapper}>
      <Grid item>
        <Mail />
      </Grid>
      <Grid container justify="center" item>
        <Typography variant="h5" className={classes.heading}>
          A message has been sent <br /> to reset your password.
        </Typography>
      </Grid>
      <Grid item className={classes.buttonContainer}>
        <Button label="Ok" width="220px" onClick={handleCloseModal} />
      </Grid>
    </Grid>
  );
};
