import React, { FunctionComponent, memo, useMemo, useEffect, useCallback } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers';
import cx from 'classnames';
// material-ui components
import { Grid, Typography } from '@material-ui/core';
// components
import { Button } from 'components';
// controllers
import { SelectController, DatePickerController } from 'controllers';
// types
import { OutlinedButtonType } from 'components/Button/types';
import { IPromoteItem } from './types';
// validations
import { Schema } from './validations';
// styles
import useStyles from './styles';

export const PromoteItem: FunctionComponent<IPromoteItem> = memo(
  ({ isCentered = false, isLeftSection, data, onSubmit, registrationDate, availableDay }) => {
    const classes = useStyles({ isCentered, isLeftSection });
    const form = useForm({ resolver: yupResolver(Schema) });

    useEffect(() => {
      if (!form.watch('start_date')) {
        form.setValue('start_date', registrationDate);
      }
      // eslint-disable-next-line
    }, [registrationDate]);

    const rateId = form.watch('rate');
    const duration = form.watch('duration');

    // reset duration value after selecting the rate
    useEffect(() => {
      if (rateId) {
        form.setValue('duration', durationOptions[0].value);
      }
      // eslint-disable-next-line
    }, [rateId]);

    const rateOptions = useMemo(
      () =>
        data.costs
          .sort((a, b) => a.id - b.id)
          .map(({ rate: { id, title } }) => ({ label: title, value: id })),
      [data]
    );

    const durationOptions = useMemo(() => {
      if (rateId) {
        return data.costs[rateId - 1].rate.durationVariants.map((id) => ({ label: id, value: id }));
      }
      return data.costs[0].rate.durationVariants.map((id) => ({ label: id, value: id }));
    }, [data, rateId]);

    const pricePerMonth = useMemo(() => {
      if (rateId) {
        return data.costs[rateId - 1].pricePerMonth;
      }
      return data.costs[0].pricePerMonth;
    }, [data, rateId]);

    const amount = useMemo(() => {
      if (rateId && duration) {
        return data.costs[rateId - 1].rate.monthMultiplier * duration;
      }
      if (!rateId && duration) {
        return data.costs[0].rate.monthMultiplier * duration;
      }
      return data.costs[0].rate.monthMultiplier;
    }, [data, rateId, duration]);

    const total = useMemo(() => (pricePerMonth * amount).toFixed(2), [pricePerMonth, amount]);

    const onSubmitForm = useCallback(
      (formData) => {
        onSubmit({ ...formData, total, title: data.title, promotion_id: data.id });
      },
      [total, data, onSubmit]
    );

    return (
      <FormProvider {...form}>
        <Grid
          container
          item
          className={classes.container}
          component="form"
          onSubmit={form.handleSubmit(onSubmitForm)}
        >
          <Typography variant="h4" className={classes.heading}>
            {data.title}
          </Typography>
          <Typography variant="body1" className={classes.description}>
            {data.description}
          </Typography>
          <Grid
            container
            justify="space-between"
            alignItems="center"
            className={classes.selectContainer}
          >
            <SelectController
              required
              width="164px"
              placeholder="Ad Rate"
              name="rate"
              errorField={form.errors.rate}
              options={rateOptions}
            />
            <span className={classes.boldText}>${pricePerMonth}</span>
          </Grid>
          <Grid container className={classes.selectContainer}>
            <SelectController
              fullWidth
              required
              placeholder="For how long"
              name="duration"
              errorField={form.errors.duration}
              options={durationOptions}
            />
          </Grid>
          <Grid container className={classes.textContainer}>
            <Grid container justify="space-between" alignItems="center">
              <span className={cx(classes.boldText, { [classes.greyText]: !isCentered })}>
                Update cost
              </span>
              <span className={classes.boldText}>${pricePerMonth}/month</span>
            </Grid>
            <Grid container justify="space-between" alignItems="center">
              <span className={cx(classes.boldText, { [classes.greyText]: !isCentered })}>
                Amount
              </span>
              <span className={classes.boldText}>
                {amount} {amount === 1 ? 'month' : 'months'}
              </span>
            </Grid>
            <Grid container justify="space-between" alignItems="center" wrap="nowrap">
              <span
                className={cx(classes.boldText, classes.startDate, {
                  [classes.greyText]: !isCentered,
                })}
              >
                Start date
              </span>
              <DatePickerController
                name="start_date"
                id={`${data.id}_start_date`}
                placeholder="Choose date"
                fullWidth
                errorField={form.errors.start_date}
                disabledPast
                availableDay={availableDay}
                horizontal={isLeftSection || isCentered ? 'left' : 'right'}
              />
            </Grid>
          </Grid>
          <Grid container justify="space-between" className={classes.total}>
            <Typography variant="subtitle2">Total Summary:</Typography>
            <Typography variant="subtitle2">${total}</Typography>
          </Grid>
          <Grid container className={classes.buttonContainer}>
            <Button
              fullWidth
              type="submit"
              label="Start now"
              variant="outlined"
              outlinedButtonType={OutlinedButtonType.orange}
            />
          </Grid>
        </Grid>
      </FormProvider>
    );
  }
);
