import React from 'react';
// material-ui components
import { Grid } from '@material-ui/core';
// components
import HoursOfOperationView from 'layouts/HoursOfOperationView';
// types
import { TProps } from './types';
// styles
import useStyles from './styles';

export const HoursOfOperationViewModal: TProps = (props) => {
  const classes = useStyles();

  return (
    <Grid container direction="column" alignItems="center" className={classes.wrapper}>
      <HoursOfOperationView {...props} />
    </Grid>
  );
};
