import * as yup from 'yup';
import { emailRule, phoneRule } from 'utils/validations';

export const Schema = yup.object().shape({
  parent: yup.object({
    first_name: yup.string().required(''),
    last_name: yup.string().required(''),
    email: emailRule,
    phone: yup.string().required('').test('parent.phone', '', phoneRule('parent.phone')),
  }),
  children: yup.object({
    first_name: yup.string().required(''),
    last_name: yup.string().required(''),
  }),
});

export const OnlyChildrenSchema = yup.object().shape({
  children: yup.object({
    first_name: yup.string().required(''),
    last_name: yup.string().required(''),
  }),
});
