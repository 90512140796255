import { createStyles, makeStyles } from '@material-ui/core/styles';
import { IPropsStyles } from './types';

export default makeStyles((theme) =>
  createStyles({
    avatar: ({ size }: IPropsStyles) => ({
      position: 'relative',
      width: size,
      height: size,
      minWidth: size,
      minHeight: size,
      maxWidth: size,
      maxHeight: size,
      backgroundColor: theme.colors.grey6,
      '& > svg': {
        width: size,
        height: size,
        minWidth: size,
        minHeight: size,
        maxWidth: size,
        maxHeight: size,
      },
    }),
    container: ({ size, isReadOnly }: IPropsStyles) => ({
      position: 'relative',
      width: size,
      cursor: isReadOnly ? 'default' : 'pointer',
    }),
    delete: {
      cursor: 'pointer',
      position: 'absolute',
      top: '-10px',
      right: '-10px',
    },
  })
);
