import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

export default makeStyles((theme: Theme) =>
  createStyles({
    buttonContainer: {
      marginTop: '32px',
    },
    body: {
      padding: '13px 31px 0 31px',
      [theme.breakpoints.only('xs')]: {
        padding: '10px 20px 0',
      },
    },
    payButton: {
      minWidth: '164px',
    },
    mB20: {
      marginBottom: '20px',
    },
    grey3: {
      color: theme.colors.grey3,
    },
    cards: {
      padding: '0 22px',
      margin: '57px 0 48px',
      position: 'relative',
      [theme.breakpoints.only('xs')]: {
        padding: 'unset',
        transform: 'translateX(-24px)',
      },
    },
    card: {
      width: '302px',
      height: '190px',
      padding: '16px 24px 36px',
      background: theme.colors.cyanLight,
      border: `1px solid ${theme.colors.cyan}`,
      boxSizing: 'border-box',
      boxShadow: '0px 8px 16px rgba(67, 86, 100, 0.02), 0px 16px 32px rgba(67, 86, 100, 0.165947)',
      borderRadius: '12px',
      [theme.breakpoints.only('xs')]: {
        padding: '16px 10px',
      },
      '@media (max-width:375px)': {
        width: '264px',
      },
    },
    outerCards: {
      zIndex: 1,
    },
    innerCards: {
      top: '25px',
      right: '22px',
      position: 'absolute',
      paddingTop: '40px',
      [theme.breakpoints.only('xs')]: {
        right: '-48px',
        height: '190px',
      },
    },
    icons: {
      '& > svg': {
        marginRight: '10px',
      },
    },
    mT12: {
      marginTop: '12px',
    },
    cardNumber: {
      marginTop: '12px',
      background: theme.colors.white,
      border: '1px solid rgba(34, 140, 219, 0.1)',
      boxSizing: 'border-box',
      borderRadius: '8px',
      padding: '3px 10px',
    },
  })
);
