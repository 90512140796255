import React, { FunctionComponent } from 'react';
// material-ui components
import { Grid, Typography } from '@material-ui/core';
// types
import { Props } from './types';
// styles
import useStyles from './styles';

export const CovidInfoModal: FunctionComponent<Props> = ({ info }) => {
  const classes = useStyles();

  return (
    <Grid container className={classes.wrapper}>
      <Grid container>
        <Typography variant="h5">COVID-19 Update:</Typography>
      </Grid>
      <Grid container alignItems="center" className={classes.covidInfoText}>
        <Typography variant="subtitle2" className={classes.text}>
          {info}
        </Typography>
      </Grid>
    </Grid>
  );
};
