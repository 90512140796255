import React, { FunctionComponent, useCallback } from 'react';
// material-ui components
import { Grid, Typography } from '@material-ui/core';
// components
import { Button } from 'components';
// icons
import { ReactComponent as CheckBig } from 'assets/icons/origin/Check_big.svg';
// custom hooks
import { useModalSetter } from 'hooks/useModal';
// types
import { Props } from './types';
// styles
import useStyles from './styles';

export const SuccessfullyModal: FunctionComponent<Props> = ({
  icon,
  title,
  text,
  subTitle,
  additionalText,
  onOkAndClose,
}) => {
  const { setModal } = useModalSetter();
  const classes = useStyles();

  const handleCloseModal = useCallback(() => {
    if (onOkAndClose) {
      onOkAndClose();
    } else {
      setModal(null);
    }
  }, [setModal, onOkAndClose]);

  return (
    <Grid container direction="column" alignItems="center" className={classes.wrapper}>
      <Grid item>{icon || <CheckBig />}</Grid>
      <Grid container justify="center" item>
        <Typography variant="h5" className={classes.heading}>
          {title}
        </Typography>
      </Grid>
      <Grid item>
        <Typography variant="body1" className={classes.text}>
          {subTitle}
        </Typography>
      </Grid>
      <Grid container justify="center" item className={classes.textContainer}>
        <Typography variant="body1" className={classes.text}>
          {text}
          <br />
          {additionalText}
        </Typography>
      </Grid>
      <Grid item className={classes.buttonContainer}>
        <Button label="Ok" width="220px" onClick={handleCloseModal} />
      </Grid>
    </Grid>
  );
};
