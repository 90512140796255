import { makeStyles, createStyles } from '@material-ui/core/styles';

export default makeStyles((theme) =>
  createStyles({
    wrapper: {
      maxWidth: '806px',
      padding: '13px 32px 38px 31px',
      [theme.breakpoints.only('xs')]: {
        maxWidth: 'unset',
        padding: '10px 10px',
      },
    },
    calendarLink: {
      color: theme.colors.cyan,
      margin: '24px 0 12px',
    },
    copyLink: {
      width: 'fit-content',
      marginBottom: '12px',
    },
    calendarIcon: {
      padding: '12px 14px',
      cursor: 'pointer',
      border: '1px solid transparent',
      '&:hover, &:focus': {
        background: theme.colors.white,
        border: `1px solid ${theme.colors.grey5}`,
        boxSizing: 'border-box',
        boxShadow:
          '0px 4px 8px rgba(67, 86, 100, 0.102956), 0px 8px 16px rgba(67, 86, 100, 0.0780977)',
        borderRadius: '6px',
      },
    },
    iconsWrap: {
      margin: '28px 0 64px',
    },
    cancelButton: {
      marginRight: '32px',
      [theme.breakpoints.only('xs')]: {
        marginRight: 'unset',
      },
    },
    buttonsContainer: {
      [theme.breakpoints.only('xs')]: {
        flexDirection: 'column-reverse',
        '& > button': {
          width: '100%',
          margin: '6px 0',
        },
      },
    },
  })
);
