import { Theme } from '@material-ui/core';
import { OutlinedButtonType, ObjectProps, SocialButtonTypes, TextButtonTypes } from './types';

export const socialButtonProps = (theme: Theme, type?: SocialButtonTypes): ObjectProps => {
  if (!type) return {};

  const colors = {
    [SocialButtonTypes.facebook]: {
      backgroundColor: theme.colors.facebook,
      hoverColor: theme.colors.facebookLight,
      activeColor: theme.colors.facebookDark,
    },
    [SocialButtonTypes.goggle]: {
      backgroundColor: theme.colors.goggle,
      hoverColor: theme.colors.goggleLight,
      activeColor: theme.colors.goggleDark,
    },
  };

  return {
    borderRadius: '50px',
    fontWeight: '600',
    fontSize: '12px',
    lineHeight: '18px',
    letterSpacing: '2px',
    textTransform: 'uppercase',
    color: theme.colors.white,
    width: '150px',
    height: '40px',
    backgroundColor: colors[type].backgroundColor,
    '&:hover': {
      backgroundColor: colors[type].hoverColor,
    },
    '&:active': {
      backgroundColor: colors[type].activeColor,
    },
  };
};

export const outlinedButtonProps = (
  theme: Theme,
  outlinedButtonType?: OutlinedButtonType,
  path = 'fill'
): ObjectProps => {
  if (!outlinedButtonType) return {};

  if (outlinedButtonType === OutlinedButtonType.transparentBlue) {
    return {
      border: `1px solid ${theme.colors.cyan}`,
    };
  }

  const colors = {
    [OutlinedButtonType.orange]: {
      hoverColor: theme.colors.orangeLight,
      borderColor: theme.colors.orange,
      backgroundColor: theme.colors.orange,
      textColor: theme.colors.white,
      svgColor: theme.colors.white,
      pathColor: 'transparent',
      hoverPathColor: 'transparent',
      activePathColor: 'transparent',
      activeColor: theme.colors.orangeDark,
      disabledColor: theme.colors.orangeDisabled,
      disabledSvgColor: theme.colors.white,
      disabledPathColor: 'transparent',
      disabledTextColor: theme.colors.white,
      disabledBorderColor: theme.colors.orangeDisabled,
    },
    [OutlinedButtonType.transparentRed]: {
      hoverColor: theme.colors.goggle,
      borderColor: theme.colors.grey4,
      backgroundColor: theme.colors.white,
      textColor: theme.colors.goggle,
      svgColor: theme.colors.goggle,
      pathColor: theme.colors.goggle,
      hoverPathColor: theme.colors.white,
      activePathColor: theme.colors.white,
      activeColor: theme.colors.goggleDark,
      disabledColor: theme.colors.white,
      disabledSvgColor: theme.colors.grey4,
      disabledPathColor: theme.colors.grey4,
      disabledTextColor: theme.colors.grey4,
      disabledBorderColor: theme.colors.grey4,
    },
    [OutlinedButtonType.white]: {
      hoverColor: theme.colors.orange,
      borderColor: theme.colors.white,
      backgroundColor: theme.colors.white,
      textColor: theme.colors.cyan,
      svgColor: theme.colors.cyan,
      pathColor: theme.colors.cyan,
      hoverPathColor: theme.colors.white,
      activePathColor: theme.colors.white,
      activeColor: theme.colors.orangeDark,
      disabledColor: theme.colors.orangeDisabled,
      disabledSvgColor: theme.colors.white,
      disabledPathColor: theme.colors.white,
      disabledTextColor: theme.colors.white,
      disabledBorderColor: theme.colors.orangeDisabled,
    },
    [OutlinedButtonType.fullFilledRed]: {
      hoverColor: theme.colors.goggleDark,
      borderColor: theme.colors.grey4,
      backgroundColor: theme.colors.red,
      textColor: theme.colors.white,
      svgColor: theme.colors.white,
      pathColor: theme.colors.goggleDark,
      hoverPathColor: 'transparent',
      activePathColor: 'transparent',
      activeColor: theme.colors.goggle,
      disabledColor: theme.colors.white,
      disabledSvgColor: theme.colors.grey4,
      disabledPathColor: theme.colors.grey4,
      disabledTextColor: theme.colors.grey4,
      disabledBorderColor: theme.colors.grey4,
    },
  };

  return {
    color: colors[outlinedButtonType].textColor,
    border: `1px solid ${colors[outlinedButtonType].borderColor}`,
    backgroundColor: colors[outlinedButtonType].backgroundColor,
    '&:hover path': {
      [path]: colors[outlinedButtonType].hoverPathColor,
    },
    '& svg': {
      [path]: colors[outlinedButtonType].svgColor,
    },
    '& path': {
      [path]: colors[outlinedButtonType].pathColor,
    },
    '&:hover': {
      backgroundColor: colors[outlinedButtonType].hoverColor,
      color: theme.colors.white,
      border: `1px solid ${colors[outlinedButtonType].hoverColor}`,
    },
    '&:active': {
      backgroundColor: colors[outlinedButtonType].activeColor,
      color: theme.colors.white,
      border: `1px solid ${colors[outlinedButtonType].activeColor}`,
      '& path': {
        [path]: colors[outlinedButtonType].activePathColor,
      },
    },
    '&.Mui-disabled': {
      color: colors[outlinedButtonType].disabledTextColor,
      border: `1px solid ${colors[outlinedButtonType].disabledBorderColor}`,
      backgroundColor: colors[outlinedButtonType].disabledColor,
      '& svg': {
        [path]: colors[outlinedButtonType].disabledSvgColor,
      },
      '& path': {
        [path]: colors[outlinedButtonType].disabledPathColor,
      },
    },
  };
};

export const textButtonProps = (theme: Theme, type?: TextButtonTypes): ObjectProps => {
  if (!type) return {};

  const colors = {
    [TextButtonTypes.withIcon]: {
      textColor: theme.colors.grey3,
      hoverTextColor: theme.colors.cyan,
      svgColor: theme.colors.cyan,
      pathColor: 'inherit',
    },
    [TextButtonTypes.withIconMultilayer]: {
      textColor: theme.colors.grey3,
      hoverTextColor: theme.colors.cyan,
      svgColor: theme.colors.cyan,
      pathColor: theme.colors.white,
    },
  };

  return {
    color: colors[type].textColor,
    '& svg': {
      fill: colors[type].svgColor,
      marginRight: '8px',
    },
    '& path': {
      fill: colors[type].pathColor,
    },
    '&:hover, &:active': {
      backgroundColor: 'unset',
      textDecoration: 'underline',
      color: colors[type].hoverTextColor,
      '& svg': {
        fill: colors[type].svgColor,
      },
      '& path': {
        fill: colors[type].pathColor,
      },
    },
    '&.Mui-disabled': {
      color: theme.colors.grey4,
      '& svg, & path': {
        fill: theme.colors.grey4,
      },
    },
  };
};
