import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import colors from 'styles/colors';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    cardNumber: ({ width }: any) => ({
      width,
      height: 'min-content',
      marginTop: '12px',
      background: theme.colors.white,
      border: '1px solid rgba(34, 140, 219, 0.1)',
      boxSizing: 'border-box',
      borderRadius: '8px',
      padding: '5px',
    }),
    cardOwner: {
      width: '100%',
      border: 'none',
      fontSize: '16px',
      letterSpacing: '-0.24px',
      color: colors.black,
      fontFamily: '"Open Sans", sans-serif',
      fontWeight: 400,
      '&::placeholder': {
        color: theme.colors.grey3,
      },
    },
    error: () => ({
      border: `1px solid ${theme.colors.red}`,
    }),
  })
);

export const style = {
  base: {
    fontSize: '16px',
    textAlign: 'center',
    letterSpacing: '-0.24px',
    color: colors.black,
    fontFamily: '"Open Sans", sans-serif',
    fontWeight: 400,
    '::placeholder': {
      color: colors.grey3,
    },
  },
  invalid: {
    color: colors.red,
  },
};
