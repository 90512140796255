import { createStyles, makeStyles } from '@material-ui/core/styles';

export default makeStyles(() =>
  createStyles({
    wrapper: {
      width: '432px',
      paddingBottom: '40px',
    },
    heading: {
      margin: '50px 0 34px',
      textAlign: 'center',
    },
    button: {
      margin: '0 15px',
    },
    iconContainer: {
      marginTop: '25px',
    },
  })
);
