import { makeStyles, createStyles } from '@material-ui/core/styles';

export default makeStyles(() =>
  createStyles({
    buttonsContainer: {
      display: 'flex',
      alignItems: 'center',
    },
    arrow: {
      marginRight: '16px',
      border: 'unset',
      cursor: 'pointer',
      backgroundColor: 'unset',
    },
    reverse: {
      marginRight: '32px',
      '& svg': {
        transform: 'rotate(180deg)',
      },
    },
  })
);
