import React, { FunctionComponent, memo } from 'react';
// material-ui
import { Button as ButtonMU } from '@material-ui/core';
// types
import { Props } from './types';
// styles
import useStyles from './styles';

export const Button: FunctionComponent<Props> = memo<Props>(
  ({
    label,
    fullWidth = false,
    width,
    variant = 'contained',
    color = 'primary',
    socialType,
    // button size on content size
    stringButton = false,
    outlinedButtonType,
    textButtonType,
    refButton,
    withStrokeIcon,
    ...rest
  }) => {
    const classes = useStyles({
      width,
      fullWidth,
      socialType,
      stringButton,
      outlinedButtonType,
      textButtonType,
      withStrokeIcon,
    });
    return (
      <ButtonMU
        {...rest}
        variant={variant}
        color={color}
        fullWidth={fullWidth}
        disableRipple
        classes={classes}
        ref={refButton}
      >
        {label}
      </ButtonMU>
    );
  }
);
