import React, { FunctionComponent } from 'react';
// form
import { Controller, useFormContext } from 'react-hook-form';
// types
import { CheckboxProps } from '@material-ui/core';
// components
import { Checkbox } from 'components/Checkbox';

interface Props extends CheckboxProps {
  sizePx?: number;
  onCheck?: (val?: boolean, event?: MouseEvent) => void;
  marginLeft?: string;
  isReadOnly?: boolean;
  [x: string]: any;
}

export const CheckBoxController: FunctionComponent<Props> = ({
  checked,
  name = '',
  defaultChecked = false,
  label,
  onCheck,
  marginLeft,
  isReadOnly,
  disabled,
}) => {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultChecked}
      render={(props) => (
        <Checkbox
          onChange={(e) => {
            props.onChange(e.target.checked);
            onCheck && onCheck(e.target.checked);
          }}
          onClick={(e) => e.stopPropagation()}
          checked={checked || props.value || false}
          label={label}
          marginLeft={marginLeft}
          isReadOnly={isReadOnly}
          disabled={disabled}
        />
      )}
    />
  );
};
