import { createStyles, withStyles } from '@material-ui/core/styles';
import { TableCell as MUITableCell } from '@material-ui/core';

export const TableCell = withStyles((theme) =>
  createStyles({
    root: {
      ...theme.fontStyle.p16,
      lineHeight: 1,
      padding: '24px 16px 17px',
      borderBottom: 'unset',
      '@media screen and (max-width: 1380px)': {
        padding: '24px 14px 17px',
      },
      '@media screen and (max-width: 600px)': {
        padding: '14px',
      },
      '@media print': {
        padding: '10px',
        fontSize: '12px',
        '& p, & a': {
          fontSize: '12px',
        },
      },
    },
    head: {
      fontSize: '12px',
      lineHeight: 1,
      fontWeight: 'bold',
      letterSpacing: '1.5px',
      whiteSpace: 'nowrap',
      color: theme.colors.black,
      backgroundColor: theme.colors.grey6,
      '@media print': {
        padding: '16px 10px',
        letterSpacing: 1,
      },
    },
    body: {
      lineHeight: 1,
      color: theme.colors.grey3,
      borderBottom: `1px solid ${theme.colors.grey5}`,
      '@media screen and (max-width: 600px)': {
        ...theme.fontStyle.p14,
      },
    },
  })
)(MUITableCell);
