import useSWR, { ConfigInterface, responseInterface, keyInterface } from 'swr';
import { GetAxiosResponse } from 'utils/axios.instance';

type TRequest = <T>(
  key: keyInterface,
  fetcher: GetAxiosResponse,
  options?: ConfigInterface
) => responseInterface<T, Error>;

const useRequest: TRequest = (key, fetcher, options) => {
  const requestOptions: ConfigInterface = {
    revalidateOnMount: true,
    revalidateOnFocus: false,
    ...options,
  };
  return useSWR(key, fetcher, requestOptions);
};

export default useRequest;
