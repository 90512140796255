import { createStyles, makeStyles } from '@material-ui/core/styles';
import ruineBackground from './ruine.jpg';

export default makeStyles((theme) =>
  createStyles({
    wrapper: {
      padding: '146px 62px 64px 22px',
      position: 'relative',
      borderRadius: '12px',
      backgroundImage: `linear-gradient( to right, rgba(52, 62, 92, 0), rgba(52, 62, 92, 1) 50%, rgba(52, 62, 92, 1) 100%),  url(${ruineBackground})`,
      backgroundSize: '100%, cover',
      backgroundPosition: 'left top, left center',
      backgroundRepeat: 'no-repeat',
      backgroundColor: 'rgba(52, 62, 92, 1)',

      '@media (max-width:1024px)': {
        padding: '90px 60px 45px 60px',

        backgroundImage: `linear-gradient(
            to right,
            rgba(52, 62, 92, 0.6),
            rgba(52, 62, 92, 0.6)
          ), url(${ruineBackground})`,
        backgroundSize: 'cover',
        backgroundPosition: 'left top',
        backgroundRepeat: 'no-repeat',
      },

      '@media (max-width:600px)': {
        padding: '30px 20px 15px 20px',
      },
    },
    doves: {
      position: 'absolute',
      left: '50%',
      top: 0,
      transform: 'translate(-50%, -50%)',
      '@media (max-width:600px)': {
        width: '80px',
        top: 0,
      },
    },
    textWrapper: {
      paddingLeft: '40%',
      paddingBottom: '50px',

      '@media (max-width:1024px)': {
        paddingLeft: '20px',
      },
    },
    title: {
      textAlign: 'left',
      color: theme.colors.white,
      '@media (max-width:600px)': {
        marginTop: '10px',
      },
      '@media (max-width:400px)': {
        marginTop: '20px',
      },
    },
    text: {
      color: theme.colors.white,
      fontSize: '22px',
      lineHeight: '28px',
      fontWeight: 400,
      letterSpacing: '-0.2px',
      textAlign: 'left',
      marginBottom: '16px',
      '@media (max-width:600px)': {
        fontSize: '16px',
        lineHeight: '20px',
        letterSpacing: '-0.24px',
      },
    },
    linksContainer: {
      paddingLeft: '40%',
      paddingRight: '14%',

      '@media (max-width:1024px)': {
        paddingLeft: '20px',
        paddingRight: '20px',
      },
    },
    link: {
      color: theme.colors.white,
      backgroundColor: theme.colors.orange,
      display: 'block',
      marginBottom: '24px',
      padding: '10px 15px',
      borderRadius: '4px',
      textAlign: 'center',
      textDecoration: 'none',
      textTransform: 'capitalize',
      fontSize: '18px',
      lineHeight: '22px',
      fontWeight: 700,
      letterSpacing: '0.75px',
    },
  })
);
