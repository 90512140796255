import React, { FunctionComponent, useCallback } from 'react';
// material-ui components
import { Grid, Typography } from '@material-ui/core';
// components
import { Button } from 'components';
// icons
import { ReactComponent as CloudError } from 'assets/icons/origin/CloudError.svg';
// custom hooks
import { useModalSetter } from 'hooks/useModal';
// types
import { IEnrollmentCapacity } from './types';
// styles
import useStyles from './styles';

export const EnrollmentCapacity: FunctionComponent<IEnrollmentCapacity> = ({ subcategory }) => {
  const { setModal } = useModalSetter();
  const classes = useStyles();

  const handleCloseModal = useCallback(() => {
    setModal(null);
  }, [setModal]);

  return (
    <Grid container direction="column" alignItems="center" className={classes.wrapper}>
      <Grid item>
        <CloudError />
      </Grid>
      <Grid container justify="center" item>
        <Typography variant="h5" className={classes.heading}>
          Oh no!
        </Typography>
      </Grid>
      <Grid item>
        <Typography variant="body1" className={classes.text}>
          This class is at capacity now!
        </Typography>
      </Grid>
      <Grid container justify="center" item className={classes.textContainer}>
        <Typography variant="body1" className={classes.text}>
          Check out other classes like this one in our{' '}
          <a
            href={`/search?categories=${subcategory?.categoryId}&subCategories=${subcategory?.id}`}
            className={classes.link}
          >
            {subcategory?.title}
          </a>{' '}
          class search results!
        </Typography>
      </Grid>
      <Grid item className={classes.buttonContainer}>
        <Button label="Ok" width="220px" onClick={handleCloseModal} />
      </Grid>
    </Grid>
  );
};
