import { makeStyles, Theme } from '@material-ui/core/styles';
import { StylesProps } from './types';

export default makeStyles((theme: Theme) => ({
  root: ({ height, checked }: StylesProps) => ({
    width: 'fit-content',
    height,
    paddingLeft: '19px',
    paddingRight: '17px',
    border: `1px solid ${theme.colors.grey4}`,
    borderRadius: '20px',
    margin: '12px 8px 0 0',
    userSelect: 'none',
    background: checked ? theme.colors.grey4 : 'transparent',
    boxSizing: 'border-box',
  }),
  label: ({ checked }: StylesProps) => ({
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '16px',
    lineHeight: '17px',
    color: checked ? theme.colors.white : theme.colors.black,
  }),
  radioBox: {
    display: 'none',
  },
}));
