import React, { FunctionComponent } from 'react';
// material-ui components
import { Typography } from '@material-ui/core';
// types
import { IEmailView } from './types';
// styles
import useStyles from './styles';

export const EmailView: FunctionComponent<IEmailView> = ({ email, variant = 'body1' }) => {
  const classes = useStyles();

  return (
    <Typography variant={variant} className={classes.wrap}>
      {email ? (
        <a target="_blank" rel="noopener noreferrer" href={`mailto: ${email}`}>
          {email}
        </a>
      ) : (
        <span>-</span>
      )}
    </Typography>
  );
};
