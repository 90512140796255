import React, { FunctionComponent, useCallback } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import MailchimpSubscribe, { FormHooks } from 'react-mailchimp-subscribe';
import { yupResolver } from '@hookform/resolvers';
// material-ui components
import { Grid, Typography } from '@material-ui/core';
// components
import { Button } from 'components';
// controllers
import { BaseInputController } from 'controllers';
// icons
import { ReactComponent as Email } from 'assets/icons/origin/Email.svg';
import { Logo } from 'assets/icons';
// custom hooks
import { useModalSetter } from 'hooks/useModal';
// types
import { IFormData } from './types';
// styles
import useStyles from './styles';
// validations
import { Schema } from './validation';
// helpers
import { defaultValues } from './helpers';

const postUrl = `https://kivity.us2.list-manage.com/subscribe/post?u=${process.env.REACT_APP_MAILCHIMP_U}&id=${process.env.REACT_APP_MAILCHIMP_ID}`;

const MailchimpSubscribeForm: FunctionComponent<FormHooks<IFormData>> = ({ subscribe, status }) => {
  const classes = useStyles();
  const { setModal } = useModalSetter();

  const form = useForm({
    resolver: yupResolver(Schema),
    defaultValues,
  });

  const handleCloseModal = () => {
    setModal(null);
  };

  const onSubmit = useCallback(
    async (formData: IFormData) => {
      subscribe(formData);
    },
    [subscribe]
  );

  if (status === 'success') {
    setTimeout(() => {
      setModal(null);
    }, 2000);

    return (
      <Grid container direction="column" justify="center" className={classes.wrapper}>
        <Grid container direction="column" alignItems="center">
          <Logo headerIsTransparent={false} />
          <Typography variant="h4" className={classes.text}>
            Thank you to subscribe to our newsletter!
          </Typography>
        </Grid>
      </Grid>
    );
  }

  if (status === 'error') {
    setTimeout(() => {
      setModal(null);
    }, 2000);

    return (
      <Grid container direction="column" justify="center" className={classes.wrapper}>
        <Grid container direction="column" alignItems="center">
          <Logo headerIsTransparent={false} />
          <Typography variant="h4" className={classes.text}>
            Something went wrong!
          </Typography>
        </Grid>
      </Grid>
    );
  }

  return (
    <FormProvider {...form}>
      <Grid container direction="column" justify="center" className={classes.wrapper}>
        <Grid container direction="column" alignItems="center">
          <Logo headerIsTransparent={false} />
          <Typography color="inherit" variant="subtitle2" paragraph className={classes.text}>
            We invite you to subscribe to our newsletter. Please note this newsletter will only be
            used to share important information regarding offerings, helpful tips, and other such
            insider perks.
          </Typography>
        </Grid>
        <Grid container component="form" onSubmit={form.handleSubmit(onSubmit)}>
          <BaseInputController
            name="EMAIL"
            type="email"
            placeholder="Email"
            startIcon={<Email />}
            errorField={form.errors.EMAIL}
            required
          />
          <BaseInputController
            name="MERGE1"
            placeholder="Name"
            errorField={form.errors.MERGE1}
            required
          />
          <Grid
            container
            alignItems="center"
            justify="center"
            direction="column"
            className={classes.buttonsContainer}
          >
            <Button type="submit" label="Subscribe" width="164px" />
            <Button
              variant="text"
              label="Cancel"
              width="112px"
              onClick={handleCloseModal}
              className={classes.button}
            />
          </Grid>
        </Grid>
      </Grid>
    </FormProvider>
  );
};

export const MailchimpSubscribeModal = () => (
  <MailchimpSubscribe
    url={postUrl}
    render={(renderProps) => {
      return <MailchimpSubscribeForm {...renderProps} />;
    }}
  />
);
