import { makeStyles, Theme } from '@material-ui/core/styles';
// types
import { StyleProps } from './types';

export default makeStyles((theme: Theme) => ({
  formControlRoot: ({
    width = '255px',
    fullWidth,
    disabled,
    borderColor,
    borderHoverColor,
    eventsIsDisabled,
  }: StyleProps) => ({
    height: '50px',
    width: fullWidth ? '100%' : width,
    backgroundColor: theme.colors.grey6,
    borderRadius: '6px',
    boxShadow: 'unset',
    cursor: disabled ? 'none' : 'pointer',
    '& fieldset': {
      borderRadius: '6px',
      borderColor: `${theme.colors[borderColor]} !important`,
    },
    '&:hover fieldset': {
      borderColor: `${theme.colors[borderHoverColor]} !important`,
    },
    '& .Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderWidth: '1px',
    },
    '& input': {
      ...theme.fontStyle.p16,
      fontFamily: 'Gilroy, sans-serif',
    },
    pointerEvents: eventsIsDisabled ? 'none' : 'auto',
  }),
  selectRoot: ({ itemColor, isReadOnly }: StyleProps) => ({
    ...theme.fontStyle.p16,
    color: isReadOnly ? theme.colors.black : theme.colors[itemColor],
    paddingRight: 'unset',
    padding: '12px 16px',
    borderRadius: '6px',
    '&:focus': {
      backgroundColor: theme.colors.grey6,
    },
  }),
  menuList: {
    paddingTop: 'unset',
    paddingBottom: 'unset',
  },
  menuPaper: {
    borderRadius: '6px',
  },
  menuItemRoot: {
    ...theme.fontStyle.p16,
    height: '50px',
    '&:hover': {
      backgroundColor: theme.colors.cyan,
      color: theme.colors.white,
    },
  },
  menuItemSelected: {
    backgroundColor: `${theme.colors.cyan} !important`,
    color: theme.colors.white,
  },
  icon: {
    minWidth: '24px',
    transition: 'transform 0.25s linear',
    '&:focused': {
      transform: 'translateZ(-90)',
    },
  },
  disabled: {
    color: theme.colors.grey4,
    '&:hover': {
      boxShadow: 'unset',
    },
  },
  formControlError: {
    '& fieldset': {
      borderColor: theme.colors.red,
    },
  },
  inputLabelRoot: ({ nonEmpty, placeholderColor }: StyleProps) => ({
    '&.MuiInputLabel-outlined': {
      ...theme.fontStyle.p16,
      display: nonEmpty ? 'none' : 'flex',
      alignItems: 'center',
      width: '100%',
      height: '100%',
      padding: '0 16px',
      lineHeight: '25px',
      transform: 'translateY(0)',
      color: theme.colors[placeholderColor],
    },
  }),
  placeholder: {
    lineHeight: '25px',
    display: 'block',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },
  errorText: () => ({
    ...theme.fontStyle.error,
    textTransform: 'inherit',
  }),
}));
