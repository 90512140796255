import React, { FunctionComponent, ChangeEvent } from 'react';
// material-ui components
import { Grid, CircularProgress, Box } from '@material-ui/core';
import { Autocomplete as MuiAutocomplete, AutocompleteRenderInputParams } from '@material-ui/lab';
// controllers
import { OutlinedInputController } from 'controllers/OutlinedInputController';
// icons
import { ChevronDown } from 'assets/icons';
// types
import { IAutocomplete } from './types';
// styles
import useStyles from './styles';

export const Autocomplete: FunctionComponent<IAutocomplete> = ({
  options,
  placeholder,
  required,
  name,
  handleSelectChange,
  errorField,
  filterOptions,
  inputComponent,
  handleInputChange,
  pending,
  renderInput,
  freeSolo = true,
}) => {
  const classes = useStyles();

  const handleChange = (
    _event: ChangeEvent<{}>,
    value: string | { label: string; value: number } | null
  ) => {
    const currentValue = typeof value === 'string' ? value : value?.value;
    const currentLabel = typeof value === 'string' ? value : value?.label;
    handleSelectChange(currentValue, currentLabel);
  };

  const defaultRenderInput = ({
    InputProps,
    InputLabelProps,
    ...inputProps
  }: AutocompleteRenderInputParams) => (
    <Grid item ref={InputProps.ref}>
      <OutlinedInputController
        {...inputProps}
        inputComponent={inputComponent}
        fullWidth
        name={name}
        required={required}
        endAdornment={
          <Box display="flex">
            {pending ? <CircularProgress color="primary" size={20} /> : null}
            <div className={classes.iconContainer}>
              <ChevronDown />
            </div>
          </Box>
        }
        errorField={errorField}
        placeholder={placeholder}
      />
    </Grid>
  );

  return (
    <MuiAutocomplete
      freeSolo={freeSolo}
      openOnFocus
      id="autocomplete"
      options={options}
      onChange={handleChange}
      onInputChange={(_event, value) => handleInputChange(value)}
      classes={{ root: classes.root }}
      filterOptions={filterOptions}
      getOptionLabel={(option) => option.label}
      getOptionSelected={(option, value) => option.value === value.value}
      renderInput={renderInput || defaultRenderInput}
      autoHighlight
    />
  );
};
