import React, { FunctionComponent, memo } from 'react';
// types
import { IBadge } from './types';
// styles
import useStyles from './styles';
import { Typography } from '@material-ui/core';

export const UserBadge: FunctionComponent<IBadge> = memo(({ text, status }) => {
  const classes = useStyles({ status });

  return (
    <Typography variant="body2" className={classes.badge}>
      {text.toLowerCase()}
    </Typography>
  );
});
