import React, { FunctionComponent, useCallback, useEffect, useMemo, useState } from 'react';
import moment from 'moment';
// react-day-picker
import DayPickerInput from 'react-day-picker/DayPickerInput';
import MomentLocaleUtils from 'react-day-picker/moment';
// components
import { InputComponent, YearMonthForm } from './components';
// styles
import 'react-day-picker/lib/style.css';
import { useStyles, useStylesInput } from './styles';

export const Datepicker: FunctionComponent<any> = ({
  value,
  errorField,
  width,
  onChange,
  name,
  isSubmitted,
  required,
  disabled,
  fullWidth,
  disabledPast,
  horizontal,
  id,
  availableDay,
  isEndDate,
  isStartDate,
  isRegStartDate,
  isRegEndDate,
  readOnly,
  onBlur,
  onDayPickerHide,
  ...props
}) => {
  const [monthState, setMonth] = useState<Date | undefined>();

  useEffect(() => {
    const date = value ? new Date(value) : new Date();
    setMonth(date);
  }, [value]);

  const classes = useStyles();
  const classesInput = useStylesInput({ horizontal });

  const dayPickerProps = useMemo(
    () => ({
      showOutsideDays: true,
      canChangeMonth: true,
      firstDayOfWeek: 1,
      month: monthState,
      weekdaysShort: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
      classNames: classes,
      autocomplete: 'on',
      onCaptionClick: (month: Date) => setMonth(month),
      captionElement: ({ date }: { date: Date }) => (
        <YearMonthForm date={date} onChange={handleYearChange} />
      ),
      disabledDays: (date: Date) => {
        if (availableDay) return moment(date).isBefore(moment(availableDay).startOf('day'));
        if (disabledPast) return moment(date).isBefore(moment().startOf('day'));
      },
    }),
    [monthState, classes, disabledPast, availableDay]
  );

  const handleYearChange = (selectedYear: number) =>
    setMonth((prev) => moment(prev).set('year', selectedYear).toDate());

  const handleDayChange = useCallback(
    (date: Date) => {
      if (!date) {
        return null;
      }

      if (isStartDate) {
        return onChange(new Date(date.setHours(0, 0, 1)));
      }
      if (isRegStartDate) {
        return onChange(new Date(date.setHours(0, 0, 0)));
      }
      if (isEndDate) {
        return onChange(new Date(date.setHours(23, 59, 59)));
      }
      if (isRegEndDate) {
        return onChange(new Date(date.setHours(23, 59, 58)));
      }
      if (moment(date).format('DDD') === moment().format('DDD')) {
        const currentDate = new Date();
        const currentTime = {
          hours: currentDate.getHours(),
          min: currentDate.getMinutes(),
          sec: currentDate.getSeconds(),
        };
        return onChange(
          new Date(date?.setHours(currentTime.hours, currentTime.min, currentTime.sec))
        );
      }
      return onChange(new Date(date.setHours(0, 0, 0, 0)));
    },
    [isStartDate, isRegStartDate, isEndDate, isRegEndDate, onChange]
  );

  return (
    <div>
      <DayPickerInput
        {...props}
        value={value && new Date(value)}
        component={InputComponent}
        format="MM/DD/yyyy"
        formatDate={MomentLocaleUtils.formatDate}
        parseDate={MomentLocaleUtils.parseDate}
        onDayChange={handleDayChange}
        dayPickerProps={dayPickerProps}
        inputProps={{
          errorField,
          width,
          name,
          isSubmitted,
          required,
          eventsIsDisabled: disabled,
          fullWidth,
          id,
          readOnly,
        }}
        classNames={classesInput}
        keepFocus={false}
        onBlur={onBlur}
        onDayPickerHide={onDayPickerHide}
      />
    </div>
  );
};
