import React, { FunctionComponent } from 'react';
// material-ui components
import { Grid, Typography } from '@material-ui/core';
// components
import { Button } from 'components';
// icons
import { ReactComponent as SureDelete } from 'assets/icons/origin/SureDelete.svg';
// custom hooks
import { useModalSetter } from 'hooks/useModal';
// types
import { IDeleteClass } from './types';
// styles
import useStyles from './styles';

export const DeleteClass: FunctionComponent<IDeleteClass> = ({ name, handleDeleteClass }) => {
  const classes = useStyles();
  const { setModal } = useModalSetter();

  const handleCloseModal = () => {
    setModal(null);
  };

  return (
    <Grid container direction="column" alignItems="center" className={classes.wrapper}>
      <Grid item className={classes.iconContainer}>
        <SureDelete />
      </Grid>
      <Grid container justify="center" item>
        <Typography variant="h5" className={classes.heading}>
          {`Are you sure you want to delete "${name}" ?`}
        </Typography>
      </Grid>
      <Grid container justify="center">
        <Button
          variant="text"
          label="Cancel"
          width="160px"
          onClick={handleCloseModal}
          className={classes.button}
        />
        <Button
          label="Yes, delete"
          width="160px"
          onClick={handleDeleteClass}
          className={classes.button}
        />
      </Grid>
    </Grid>
  );
};
