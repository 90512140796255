import React, { FunctionComponent, memo } from 'react';
import { Link, NavLink } from 'react-router-dom';
// icons
import { Logo } from 'assets/icons';
// material-ui components
import { Grid, Hidden, Box, Typography } from '@material-ui/core';
// components
import HeaderButtons from './components/HeaderButtons';
import HeaderButtonsSmall from './components/HeaderButtonsSmall';
// types
import { IHeaderView } from './types';
// styles
import useStyles from './styles';

const HeaderView: FunctionComponent<IHeaderView> = ({
  user,
  withShadow,
  headerLinks,
  isAuthUpPage,
  isBusinessLandingPage,
  headerIsTransparent,
}) => {
  const classes = useStyles({ headerIsTransparent, withShadow });

  return (
    <Grid component="header" container justify="center" className={classes.header}>
      <Grid container justify="space-between" alignItems="center" className={classes.container}>
        <Link to="/" className={classes.logo}>
          <Logo headerIsTransparent={headerIsTransparent} />
          {isBusinessLandingPage && (
            <Typography variant="subtitle1" color="inherit" className={classes.forBusiness}>
              for business
            </Typography>
          )}
        </Link>

        <Hidden lgUp>
          <HeaderButtonsSmall
            role={user.role}
            avatar={user.avatar || undefined}
            headerIsTransparent={headerIsTransparent}
            isBusinessLandingPage={isBusinessLandingPage}
          />
        </Hidden>
        <Hidden mdDown>
          <Box component="nav" display="flex">
            {headerLinks.map(({ path, label, icon: Icon }) => (
              <NavLink
                itemScope
                itemType="http://schema.org/SiteNavigationElement"
                exact
                key={path}
                to={path}
                className={classes.link}
                activeClassName={isAuthUpPage ? undefined : classes.activeLink}
              >
                <Box display="flex" alignItems="center" className={classes.linkContainer}>
                  {Icon && <img src={Icon} alt="header icon" />}
                  {label}
                </Box>
              </NavLink>
            ))}
          </Box>
          <HeaderButtons
            role={user.role}
            avatar={user.avatar || undefined}
            isBusinessLandingPage={isBusinessLandingPage}
            headerIsTransparent={headerIsTransparent}
          />
        </Hidden>
      </Grid>
    </Grid>
  );
};

export default memo(HeaderView);
