import React, { FunctionComponent, useCallback, useState } from 'react';
// material-ui components
import { Grid, Typography } from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';
import Snackbar from '@material-ui/core/Snackbar';
// components
import { Button } from 'components';
// custom hooks
import { useModalSetter } from 'hooks/useModal';
// helpers
import { links } from './helpers';
// styles
import useStyles from './styles';

interface Props {
  link: string;
}

export const AddEventsToCalendar: FunctionComponent<Props> = ({ link }) => {
  const classes = useStyles();
  const { setModal } = useModalSetter();
  const [open, setOpen] = useState(false);

  const handleCloseModal = () => {
    setModal(null);
  };

  const copyLink = useCallback(() => {
    window.navigator.clipboard
      .writeText(link)
      .then(() => setOpen(true))
      .catch((err) => {
        console.error(err);
      });
  }, [link]);

  return (
    <Grid container direction="column" className={classes.wrapper}>
      <Snackbar open={open} autoHideDuration={2000} onClose={() => setOpen(false)}>
        <MuiAlert elevation={6} variant="filled" severity="success">
          Copied!
        </MuiAlert>
      </Snackbar>
      <Typography variant="h5" paragraph>
        Add events to calendar
      </Typography>
      <Typography variant="body1">
        Please copy the link below and use it on your preferred calendar:
      </Typography>
      <Typography variant="body1" className={classes.calendarLink}>
        {link}
      </Typography>
      <Button
        variant="text"
        label="Copy link"
        stringButton={true}
        onClick={copyLink}
        className={classes.copyLink}
      />
      <Typography variant="body1">
        Select the calendar you are using and follow instructions:
      </Typography>
      <Grid container justify="space-between" className={classes.iconsWrap}>
        {links.map(({ href, Icon }) => (
          <a
            key={href}
            target="_blank"
            rel="noopener noreferrer"
            className={classes.calendarIcon}
            href={href}
          >
            <img src={Icon} alt="calendar icon" />
          </a>
        ))}
      </Grid>
      <Grid container justify="center" className={classes.buttonsContainer}>
        <Button
          variant="text"
          label="Cancel"
          width="148px"
          onClick={handleCloseModal}
          className={classes.cancelButton}
        />
        <Button label="OK" width="168px" onClick={handleCloseModal} />
      </Grid>
    </Grid>
  );
};
