import { createStyles, makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) =>
  createStyles({
    wrapper: {
      maxWidth: '480px',
      padding: '13px 30px 30px',
      color: theme.colors.grey3,
    },
    buttonsContainer: {
      marginTop: '30px',
    },
    button: {
      marginTop: '24px',
    },
    text: {
      textAlign: 'center',
      marginTop: '32px',
    },
  })
);
