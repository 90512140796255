// material-ui
import { makeStyles, Theme } from '@material-ui/core/styles';
// types
import { StylesProps } from './types';
// styles
import { socialButtonProps, outlinedButtonProps, textButtonProps } from './helpers';

export default makeStyles((theme: Theme) => ({
  root: ({ width = '142px', fullWidth, stringButton }: StylesProps) => ({
    width: stringButton ? 'auto' : fullWidth ? '100%' : width,
    borderRadius: '6px',
    boxShadow: 'none',
    letterSpacing: '0.75px',
    fontWeight: 'bold',
    fontSize: '16px',
    lineHeight: '19px',
    textTransform: 'capitalize',
    cursor: 'pointer',
    '&:hover, &:active, &.Mui-disabled': {
      boxShadow: 'none',
    },
  }),
  contained: ({ socialType }: StylesProps) => ({
    backgroundColor: theme.colors.cyan,
    height: '50px',
    '& path, &:active path, &:hover path': {
      fill: theme.colors.white,
    },
    '&:hover': {
      backgroundColor: theme.colors.primaryLight,
    },
    '&:active': {
      backgroundColor: theme.colors.primaryDark,
      color: theme.colors.white,
    },
    '&.Mui-disabled': {
      backgroundColor: theme.colors.buttonDisabled,
      color: theme.colors.white,
    },
    ...socialButtonProps(theme, socialType),
  }),
  outlined: ({ outlinedButtonType, withStrokeIcon }: StylesProps) => {
    const path = withStrokeIcon ? 'stroke' : 'fill';
    return {
      color: theme.colors.cyan,
      height: '50px',
      border: `1px solid ${theme.colors.grey4}`,
      boxSizing: 'border-box',
      '&:active path, &:hover path': {
        [path]: theme.colors.white,
      },
      '& svg, & path': {
        [path]: theme.colors.cyan,
      },
      '&:hover': {
        backgroundColor: theme.colors.cyan,
        color: theme.colors.white,
      },
      '&:active': {
        backgroundColor: theme.colors.primaryDark,
        color: theme.colors.white,
      },
      '&.Mui-disabled': {
        color: theme.colors.buttonDisabledOutline,
        border: `1px solid ${theme.colors.grey4}`,
        backgroundColor: 'transparent',
        '& svg, & path': {
          [path]: theme.colors.buttonDisabledOutline,
        },
      },
      ...outlinedButtonProps(theme, outlinedButtonType, path),
    };
  },
  text: ({ textButtonType, stringButton }: StylesProps) => ({
    color: theme.colors.cyan,
    height: stringButton ? 'fit-content' : '50px',
    padding: 'unset',
    '& svg, & path': {
      fill: theme.colors.cyan,
    },
    '&:hover, &:active': {
      backgroundColor: 'unset',
      textDecoration: 'underline',
      '& svg, & path': {
        fill: theme.colors.cyan,
      },
    },
    '&.Mui-disabled': {
      color: theme.colors.grey4,
      '& svg, & path': {
        fill: theme.colors.grey4,
      },
    },
    ...textButtonProps(theme, textButtonType),
  }),
  sizeSmall: ({ width = '118px', fullWidth }: StylesProps) => ({
    height: '40px',
    fontWeight: 600,
    fontSize: '14px',
    lineHeight: '17px',
    width: fullWidth ? '100%' : width,
    textTransform: 'initial',
    '& svg': {
      width: '20px',
      height: '20px',
    },
  }),
  sizeLarge: ({ width = '180px', fullWidth }: StylesProps) => ({
    height: '60px',
    width: fullWidth ? '100%' : width,
    textTransform: 'uppercase',
  }),
  startIcon: {
    marginRight: '4px',
    marginLeft: 'unset',
  },
}));
