import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

export default makeStyles((theme: Theme) =>
  createStyles({
    wrapper: {
      width: '432px',
      paddingBottom: '42px',
      [theme.breakpoints.only('xs')]: {
        width: '100%',
      },
    },
    heading: {
      margin: '30px 0 16px',
    },
    buttonContainer: {
      marginTop: '32px',
    },
  })
);
