import React, { FunctionComponent, useCallback, useMemo } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers';
// material-ui components
import { Grid, Typography } from '@material-ui/core';
// components
import { Button, Autocomplete } from 'components';
// custom hooks
import useRequest from 'hooks/useRequest';
import { useModalSetter } from 'hooks/useModal';
// api
import Api from 'utils/api';
// validations
import { Schema } from './validation';
// helpers
import { defaultValues } from './helpers';
// types
import { IFormData, IBusinessData } from './types';
import { GetAxiosResponse } from 'utils/axios.instance';
// styles
import useStyles from './styles';
import { useHistory } from 'react-router-dom';

export const AddClass: FunctionComponent = () => {
  const classes = useStyles();
  const history = useHistory();
  const { setModal } = useModalSetter();

  const form = useForm({
    resolver: yupResolver(Schema),
    defaultValues,
  });

  const fetcher: GetAxiosResponse = () =>
    Api.admin.business.getBusinesses({ params: { page: 1, limit: 99999 } });

  const { data: businessesList } = useRequest<IBusinessData>('/business-list', fetcher);

  const options = useMemo(
    () => businessesList?.data?.map(({ id, company_name }) => ({ label: company_name, value: id })),
    [businessesList]
  );

  const handleCloseModal = () => {
    setModal(null);
  };

  const onSubmit = ({ businessId }: IFormData) => {
    history.push(`/classes/${businessId}/create`);
    setModal(null);
  };

  const handleChange = useCallback((value: string) => {
    form.clearErrors('businessId');
    form.setValue('businessId', value);
    // eslint-disable-next-line
  }, []);

  const handleSelectChange = useCallback(
    (value) => {
      if (value && Number(value)) {
        handleChange(String(value));
      }
    },
    [handleChange]
  );

  return (
    <FormProvider {...form}>
      <Grid container direction="column" className={classes.wrapper}>
        <Grid container>
          <Typography variant="h5" paragraph>
            Add Class
          </Typography>
        </Grid>
        <Grid container>
          <Typography variant="body1" className={classes.description}>
            Select Business You would like to create class for
          </Typography>
        </Grid>
        <Grid container component="form" onSubmit={form.handleSubmit(onSubmit)}>
          <Autocomplete
            pending={!businessesList}
            required
            name="businessId"
            options={options || []}
            placeholder="Select business"
            errorField={form.errors.businessId}
            handleInputChange={handleChange}
            handleSelectChange={handleSelectChange}
          />
          <Grid container justify="center" className={classes.buttonsContainer}>
            <Button
              variant="text"
              label="Cancel"
              width="112px"
              onClick={handleCloseModal}
              className={classes.button}
            />
            <Button type="submit" label="Add" width="164px" />
          </Grid>
        </Grid>
      </Grid>
    </FormProvider>
  );
};
