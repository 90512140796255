import React, { FunctionComponent, useCallback, useState } from 'react';
// material-ui components
import { Grid, RadioGroup, Typography } from '@material-ui/core';
// components
import { Button, Radio } from 'components';
// custom hooks
import { useModalSetter } from 'hooks/useModal';
// types
import { ISubCategory } from './types';
// styles
import useStyles from './styles';

const options = {
  shouldValidate: true,
  shouldDirty: true,
};

export const SubCategories: FunctionComponent<ISubCategory> = ({
  list,
  title,
  subcategoriesId,
  setValue,
}) => {
  const classes = useStyles();
  const { setModal } = useModalSetter();
  const [subcategory, setSubcategory] = useState(subcategoriesId);

  const resetAll = useCallback(() => {
    setSubcategory('');
    setValue('subcategories', '', options);
  }, [setValue]);

  const handleCloseModal = useCallback(() => {
    setValue('subcategories', subcategory, options);
    setModal(null);
  }, [setValue, setModal, subcategory]);

  return (
    <Grid container direction="column" alignItems="center" className={classes.wrapper}>
      <Grid container direction="column" alignItems="center" justify="center">
        <Grid container direction="column" className={classes.body}>
          <Grid className={classes.mB8}>
            <Typography variant="caption">Categories</Typography>
          </Grid>
          <Typography variant="h5">{title}</Typography>
          <Typography variant="body1">Please check the required subcategories</Typography>
          <Grid container className={classes.categories}>
            <RadioGroup
              value={subcategory}
              onChange={(e) => setSubcategory(+e.target.value)}
              className={classes.subCategories}
            >
              {list?.map((item) => (
                <Grid item xs={12} sm={12} md={6} lg={6} key={item.value}>
                  <Radio
                    value={item.value}
                    label={item.label}
                    checked={subcategory === item.value}
                  />
                </Grid>
              ))}
            </RadioGroup>
          </Grid>
          <Grid>
            <Button variant="text" label="Reset All" stringButton onClick={resetAll} />
          </Grid>
          <Grid item className={classes.buttonContainer}>
            <Button label="Ok" width="220px" onClick={handleCloseModal} />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
