import React, { FunctionComponent, memo, useMemo } from 'react';
import MaskedInput from 'react-text-mask';
// helpers
import { upperCasePipe } from './helpers';
// types
import { ITimeMask } from './types';

export const Time: FunctionComponent<ITimeMask> = memo(({ inputRef, value, ...other }) => {
  const secondMaskNumber = useMemo(() => (value[0] === '0' ? /[0-9]/ : /[0-2]/), [value]);

  return (
    <MaskedInput
      {...other}
      value={value}
      ref={(ref: any) => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={[/[0-1]/, secondMaskNumber, ':', /[0-5]/, /[0-9]/, ' ', /[AaPp]/, 'M']}
      pipe={upperCasePipe}
    />
  );
});
