import { makeStyles, createStyles } from '@material-ui/core/styles';

export default makeStyles(() =>
  createStyles({
    menuList: {
      padding: '8px 0',
      borderRadius: '6px',
    },
    menuPaper: {
      marginTop: '8px',
    },
    menuItemRoot: {
      height: '50px',
      padding: '0px 20px',
    },
    buttonContainer: {
      padding: '20px 20px 18px',
    },
  })
);
