import React, { FunctionComponent } from 'react';
import moment from 'moment';
// material-ui components
import { Grid, Typography } from '@material-ui/core';
// types
import { IEvent } from 'types';
// icons
import { Clock } from 'assets/icons';
// styles
import useStyles from './styles';
import { Link } from 'react-router-dom';

interface Props {
  events?: IEvent[];
}

export const BusinessEventCalendarBigView: FunctionComponent<Props> = ({ events }) => {
  const classes = useStyles();

  return (
    <>
      {events?.map((item, index) => {
        const start = moment(item.start).format('hh:mma');
        const end = moment(item.end).format('hh:mma');
        return (
          <Grid
            key={index.toString()}
            className={classes.popupEvent}
            style={{ borderLeftColor: item.color }}
          >
            <Typography
              variant="h5"
              style={{ color: item.color }}
              component={Link}
              to={`/classes/view/${item.courseId}`}
              className={classes.title}
            >
              {item.title}
            </Typography>
            <Typography variant="subtitle2">{item.location}</Typography>
            <Typography variant="subtitle2" className={classes.popupTime}>
              <Clock /> {start} - {end}
            </Typography>
          </Grid>
        );
      })}
    </>
  );
};
