import { makeStyles, Theme } from '@material-ui/core';
// icons
import ChevronLeft from 'assets/svg/ChevronLeft.svg';
import ChevronRight from 'assets/svg/ChevronRight.svg';
// types
import { StyleProps } from './types';

export const useStylesInput = makeStyles((theme: Theme) => ({
  overlayWrapper: {
    position: 'relative',
  },
  overlay: ({ horizontal = 'left' }: StyleProps) => ({
    position: 'absolute',
    [horizontal]: 0,
    top: 5,
    zIndex: 10,
    backgroundColor: theme.colors.white,
    boxShadow: '0px 24px 64px rgba(67, 86, 100, 0.24)',
    borderRadius: '6px',
  }),
}));

export const useStyles = makeStyles((theme: Theme) => ({
  container: {
    display: 'inline-block',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '14px',
    lineHeight: '16px',
    textAlign: 'center',
    letterSpacing: ' -0.3px',
    color: theme.colors.black,
    width: '300px',
  },
  wrapper: {
    position: 'relative',
    flexDirection: 'row',
    userSelect: 'none',
  },
  interactionDisabled: {},
  navBar: {
    position: 'absolute',
    top: 0,
    left: 0,
  },
  navButtonPrev: {
    backgroundImage: `url(${ChevronLeft})`,
    position: 'absolute',
    top: '1.2rem',
    left: '10px',
    display: 'inline-block',
    width: '24px',
    height: '24px',
    backgroundPosition: 'center',
    backgroundSize: '100%',
    backgroundRepeat: 'no-repeat',
    cursor: 'pointer',
  },
  navButtonNext: {
    backgroundImage: `url(${ChevronRight})`,
    position: 'absolute',
    top: '1.2rem',
    left: '265px',
    display: 'inline-block',
    width: '24px',
    height: '24px',
    backgroundPosition: 'center',
    backgroundSize: '100%',
    backgroundRepeat: 'no-repeat',
    cursor: 'pointer',
  },
  navButtonInteractionDisabled: {
    display: 'none',
  },
  months: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
  },
  month: {
    display: 'table',
    borderSpacing: 0,
    borderCollapse: 'collapse',
    userSelect: 'none',
    margin: '6px 29px 15px',
  },
  caption: {
    position: 'relative',
    display: 'table-caption',
    fontWeight: 600,
    fontSize: '16px',
    lineHeight: '26px',
    textAlign: 'center',
    letterSpacing: '-0.24px',
    height: '48px',
    '& > div': { transform: 'translateY(50%)' },
    '&:after': {
      content: '""',
      position: 'absolute',
      bottom: 0,
      left: '-29px',
      height: '1px',
      width: 'calc(100% + 58px)',
      background: theme.colors.grey5,
    },
  },
  weekdays: {
    display: 'table-header-group',
    padding: '0 29px',
  },
  weekdaysRow: {
    display: 'table-row',
    height: '46px',
  },
  weekday: {
    display: 'table-cell',
    verticalAlign: 'middle',
    textAlign: 'center',
    fontSize: '10px',
    lineHeight: '14px',
    color: theme.colors.grey4,
    '& abbr[title]': {
      borderBottom: 'none',
      textDecoration: 'none',
    },
  },
  body: {
    display: 'table-row-group',
  },
  week: {
    display: 'table-row',
  },
  day: {
    display: 'table-cell',
    margin: '12px',
    borderRadius: '50%',
    verticalAlign: 'middle',
    textAlign: 'center',
    cursor: 'pointer',
    width: '36px',
    minWidth: '36px',
    maxWidth: '36px',
    height: '36px',
  },
  footer: {},
  todayButton: {},
  today: {
    borderRadius: '1000px',
    background: theme.colors.orange,
    color: theme.colors.white,
  },
  selected: {
    borderRadius: '1000px',
    background: theme.colors.blue2,
    color: theme.colors.white,
  },
  disabled: {
    color: theme.colors.grey10,
  },
  outside: {
    color: theme.colors.grey10,
  },
  weekNumber: {},
}));
