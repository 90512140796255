import { ButtonProps } from '@material-ui/core/Button';
import { RefObject } from 'react';

export enum SocialButtonTypes {
  facebook = 'facebook',
  goggle = 'goggle',
}

export enum OutlinedButtonType {
  orange = 'orange',
  transparentRed = 'transparentRed',
  transparentBlue = 'transparentBlue',
  white = 'white',
  fullFilledRed = 'fullFilledRed',
}

export enum TextButtonTypes {
  withIcon = 'withIcon',
  withIconMultilayer = 'withIconMultilayer',
}

export interface Props extends ButtonProps {
  [x: string]: any;
  width?: string;
  socialType?: SocialButtonTypes;
  stringButton?: boolean;
  outlinedButtonType?: OutlinedButtonType;
  textButtonType?: TextButtonTypes;
  refButton?: RefObject<HTMLButtonElement>;
  withStrokeIcon?: boolean;
}

export interface StylesProps {
  width?: string;
  socialType?: SocialButtonTypes;
  fullWidth: boolean;
  stringButton: boolean;
  outlinedButtonType?: OutlinedButtonType;
  textButtonType?: TextButtonTypes;
  withStrokeIcon?: boolean;
  [x: string]: any;
}

export interface ObjectProps {
  [x: string]: any;
  path?: 'stroke' | 'fill';
}
