import React, { FunctionComponent } from 'react';
// form
import { Controller, useFormContext } from 'react-hook-form';
// types
import { SelectProps } from '@material-ui/core';
// components
import { Select } from 'components/Select';

interface Props extends SelectProps {
  options?: { value: number | string; label: number | string }[];
  value?: number | string;
  fullWidth?: boolean;
  placeholder?: string;
  width?: string;
  onChangeHelper?: (val: string | string[]) => void;
  hideIsSubmitted?: boolean;
  errorField?: {
    type: string;
    message?: string;
  };
  [x: string]: any;
}

export const SelectController: FunctionComponent<Props> = ({
  name = '',
  placeholder,
  options,
  defaultValue = '',
  hideIsSubmitted,
  errorField,
  onChangeHelper,
  children,
  ...props
}) => {
  const {
    control,
    formState: { isSubmitted },
  } = useFormContext();

  return (
    <Controller
      defaultValue={defaultValue}
      name={name}
      control={control}
      render={({ value, onChange }) => (
        <Select
          {...props}
          value={value}
          options={options}
          onChange={(e) => {
            onChange(e.target.value);
            onChangeHelper && onChangeHelper(e.target.value as string);
          }}
          placeholder={placeholder}
          errorField={errorField}
          isSubmitted={hideIsSubmitted ? false : isSubmitted}
        >
          {children}
        </Select>
      )}
    />
  );
};
