import { createStyles, makeStyles } from '@material-ui/core/styles';

export default makeStyles(() =>
  createStyles({
    wrapper: {
      width: '350px',
      padding: '0 30px 0 20px',
    },
  })
);
