import React, { FunctionComponent, memo } from 'react';
// icons
import { ReactComponent as GoogleMapMarker } from 'assets/icons/origin/GoogleMapMarker.svg';
// types
import { IMarker } from '../../types';
// styles
import useStyles from './styles';

const Marker: FunctionComponent<IMarker> = () => {
  const classes = useStyles();

  return (
    <div className={classes.marker}>
      <GoogleMapMarker />
    </div>
  );
};

export default memo(Marker);
